import { Button, Icon, MenuItem, Select, Typography } from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { parseISO, formatDistanceToNow } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FuseLoading from '@fuse/core/FuseLoading';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SketchPicker } from 'react-color';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import FormControl from '@mui/material/FormControl';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CircularProgress from '@mui/material/CircularProgress';
import history from '@history';
import './QuickChat.css';
import ChatInbox from './ChatInbox';
import quickChatQuery from '../../query/quickChat';
import {
  avatarDataSendToServer,
  capitalizeFirstLetter,
  handleApiRequest,
} from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';

const QuickChat = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const [pageLoading, setPageLoading] = useState(false);
  const [quickChatAvailable, setQuickChatAvailable] = useState(false);
  const [quickChatCreate, setQuickChatCreate] = useState(false);
  const [quickChatEdit, setQuickChatEdit] = useState(false);
  const [color, setColor] = useState('#0BA861');
  const [selectAvatarUrl, setSelectAvatarUrl] = useState('');
  const [quickChatDetails, setQuickChatDetails] = useState({});
  const [avatarList, setAvatarList] = useState([]);
  const [copy, setCopy] = useState(false);
  const [imageUpload, setImageUpload] = useState();
  const [noImgMsg, setNoImgMsg] = useState('');
  const [domainLoading, setDomainLoading] = useState(false);
  const [domainMessage, setDomainMessage] = useState('');
  const [domainAvailable, setDomainAvailable] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [imageUrl, setImageUrl] = useState();
  const [botData, setBotData] = useState({
    botName: quickChatDetails?.name || 'Quick Chat',
    domainName:
      quickChatDetails?.domain ||
      `${
        userCurrentBusinessDetails?.city?.toLowerCase() ||
        userCurrentBusinessDetails?.countries?.name?.toLowerCase()
      }/${userCurrentBusinessDetails?.name
        ?.replace(/[\s-]+/g, '')
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()}`,
  });
  const [isEmpty, setIsEmpty] = useState({ botName: false, domainName: false });
  const [colorBox, setColorBox] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchDomainNameAvailable = async () => {
      try {
        setDomainLoading(true);
        const payloadData = {
          query: quickChatQuery.checkQuickChatDomain,
          variables: {
            domain: botData?.domainName,
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setDomainLoading(false);

        if (resultData?.checkQuickChatDomain?.status === 200) {
          setDomainAvailable(resultData?.checkQuickChatDomain?.data?.domainAvailable);
          setDomainMessage(resultData?.checkQuickChatDomain?.message);
        } else {
          enqueueSnackbar(resultData?.checkQuickChatDomain?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setDomainLoading(false);
        console.error('Error fetching domain availability:', error);
      }
    };
    fetchDomainNameAvailable();
  }, [botData?.domainName, enqueueSnackbar]);

  useEffect(() => {
    const fetchAgentSupportStatus = async () => {
      if (userCurrentBusinessDetails?.id) {
        const payloadData = {
          query: quickChatQuery.getQuickChat,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        if (
          resultData?.getQuickChat?.status === 200 &&
          resultData?.getQuickChat?.message !== 'Not found.'
        ) {
          setQuickChatAvailable(true);
          setImageUrl(resultData?.getQuickChat?.data?.avatar);
          setQuickChatDetails(resultData?.getQuickChat?.data);
          setSelectAvatarUrl(resultData?.getQuickChat?.data?.avatar);
          setColor(resultData?.getQuickChat?.data?.headerColor);
        }
      }
    };
    fetchAgentSupportStatus();
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (avatarList?.length === 0) {
      const fetchAgentSupportStatus = async () => {
        const payloadData = {
          query: quickChatQuery.getQuickChatDefaultAvatar,
        };
        const resultData = await handleApiRequest(payloadData);
        if (resultData?.getQuickChatDefaultAvatar?.status === 200) {
          setAvatarList(resultData?.getQuickChatDefaultAvatar?.data);
          if (!quickChatEdit) {
            setSelectAvatarUrl(resultData?.getQuickChatDefaultAvatar?.data[0]?.avatar);
          }
        }
      };
      fetchAgentSupportStatus();
    }
  }, [avatarList?.length, quickChatEdit]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.name) {
      const countryName =
        userCurrentBusinessDetails?.countries?.name
          ?.toLowerCase()
          .replace(/[\s-]+/g, '')
          .replace(/[^a-zA-Z]/g, '') || '';

      const businessName =
        userCurrentBusinessDetails?.name
          ?.toLowerCase()
          .replace(/[\s-]+/g, '')
          .replace(/[^a-zA-Z]/g, '') || '';

      const sanitizedDomainName = quickChatDetails?.domain || `${countryName}/${businessName}`;

      setBotData((prevBotData) => ({
        ...prevBotData,
        domainName: sanitizedDomainName,
      }));
    }
  }, [
    userCurrentBusinessDetails?.name,
    userCurrentBusinessDetails?.countries?.name,
    quickChatDetails?.domain,
  ]);
  const handleInputChange = (field) => async (e) => {
    let { value } = e.target;
    if (field === 'domainName') {
      value = value.replace(/[^a-zA-Z./-]/g, '');
    }

    setBotData({ ...botData, [field]: value });
    setIsEmpty({ ...isEmpty, [field]: value.trim() === '' });
  };
  const handleCreateBot = async () => {
    setPageLoading(true);
    const payload = {
      query: quickChatQuery.createQuickChatAndUpdate,
      variables: {
        name: botData?.botName,
        headerColor: color,
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        domain: `${botData?.domainName || ''}`,
        ...(!imageUpload && {
          avatar: selectAvatarUrl,
        }),
        ...(imageUpload && {
          avatarImageFile: imageUpload,
        }),
      },
    };
    const result = await avatarDataSendToServer(payload);
    if (result?.createQuickChatAndUpdate?.status === 200) {
      setPageLoading(false);
      setQuickChatEdit(false);
      setQuickChatCreate(false);
      const payloadData = {
        query: quickChatQuery.getQuickChat,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const resultData = await handleApiRequest(payloadData);
      if (
        resultData?.getQuickChat?.status === 200 &&
        resultData?.getQuickChat?.message !== 'Not found.'
      ) {
        setImageUpload();
        setImageUrl(resultData?.getQuickChat?.data?.avatar);
        setQuickChatAvailable(true);
        setQuickChatDetails(resultData?.getQuickChat?.data);
      }
      enqueueSnackbar(result?.createQuickChatAndUpdate?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setQuickChatEdit(true);
    } else {
      setPageLoading(false);
      enqueueSnackbar(result?.createQuickChatAndUpdate?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleCopyClick = async (id) => {
    const textToCopy = document.getElementById(id).innerText;

    navigator.clipboard.writeText('').then(() => {
      setCopy(true);
      return navigator.clipboard.writeText(textToCopy);
    });
    await setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.onload = async () => {
      setImageUrl(reader.result);
      setImageUpload(file);
    };
    reader.readAsDataURL(file);
    setNoImgMsg('');
  }

  const handleSelectAvtar = (value) => {
    setSelectAvatarUrl(value);
    if (value) {
      setImageUrl();
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={tabValue}
        className="sm:overflow-hidden overflow-scroll p-0 bg-white"
        sx={{
          '& .MuiTabs-scroller': {
            borderBottom: '1px solid #eee',
            borderTop: '1px solid #eee',
            padding: '0px',
          },
        }}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab
          className="md:text-18 text-14 font-medium py-20 px-5 md:px-16 text-black"
          value={0}
          label={t('quickChat.createBot.botInfo')}
        />
        <Tab
          className="md:text-18 text-14 font-medium py-20 px-5 md:px-16 text-black"
          value={1}
          label={t('quickChat.createBot.inbox')}
          disabled={!quickChatDetails?.id}
        />
      </Tabs>
      <div className="p-20 lg:p-32">
        <div className={tabValue !== 0 ? 'hidden' : ''}>
          {!quickChatAvailable && !quickChatCreate && (
            <div className="sm:flex w-full items-center justify-between mb-36">
              <Typography
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
              >
                {t('quickChat.title')}
              </Typography>
              <Button
                type="button"
                onClick={() => {
                  if (!userCurrentBusinessDetails?.isOnlineBusiness) {
                    setQuickChatCreate(true);
                    setQuickChatAvailable(true);
                  } else {
                    enqueueSnackbar(`${t('quickChat.createBot.message')}`, {
                      variant: 'info',
                      autoHideDuration: 3000,
                    });
                  }
                }}
                className="rounded-md"
                variant="contained"
                color="secondary"
              >
                {t('quickChat.createBot.button')}
              </Button>
            </div>
          )}
          {quickChatAvailable ? (
            <>
              {quickChatCreate ? (
                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28">
                  <div className="bg-white rounded-md">
                    <div className="bg-grey-100 py-16 px-24 text-16 font-bold">
                      {quickChatEdit
                        ? `${t('quickChat.chatbotDetail.editBot')}`
                        : `${t('quickChat.createBot.title')}`}
                    </div>
                    <div className="p-24">
                      <div className="block mb-16 max-w-256">
                        <InputLabel
                          htmlFor="botName"
                          className="text-16 font-medium mb-10 text-grey-900 block"
                        >
                          {t('quickChat.createBot.name')}
                          <span className="text-red">*</span>
                        </InputLabel>
                        <TextField
                          onChange={handleInputChange('botName')}
                          value={botData?.botName}
                          size="small"
                          id="botName"
                          variant="outlined"
                          required
                          fullWidth
                        />
                        {isEmpty?.botName && (
                          <Typography className="text-red pt-8 text-14 font-medium">
                            {t('quickChat.createBot.nameError')}
                          </Typography>
                        )}
                      </div>
                      <div className="block mb-16 ">
                        <InputLabel
                          htmlFor="domainName"
                          className="text-16 font-medium mb-10 text-grey-900 block"
                        >
                          {t('quickChat.createBot.domain')}
                          <span className="text-red">*</span>
                        </InputLabel>
                        <div className=" max-w-256 relative">
                          <TextField
                            onChange={handleInputChange('domainName')}
                            className="max-w-256"
                            value={botData?.domainName}
                            size="small"
                            id="domainName"
                            variant="outlined"
                            required
                            fullWidth
                          />
                          {domainLoading && (
                            <CircularProgress
                              size={12}
                              className="text-darkgreen absolute mx-auto right-8 top-14 z-10"
                            />
                          )}
                          {(domainAvailable ||
                            botData?.domainName === quickChatDetails?.domain) && (
                            <span className="w-16 h-16 inline-block rounded-full align-middle text-center pt-2 absolute right-8 top-12 z-0 bg-darkgreen text-white text-12">
                              <Icon className="text-12">check</Icon>
                            </span>
                          )}
                        </div>
                        {isEmpty?.domainName && (
                          <Typography className="text-red pt-8 text-14 font-medium">
                            {capitalizeFirstLetter(domainMessage)}
                          </Typography>
                        )}
                        {!domainAvailable && botData?.domainName !== quickChatDetails?.domain && (
                          <Typography className="text-red">
                            {t('quickChat.domainNameAvailable')}
                          </Typography>
                        )}
                        {/* {isEmpty?.domainName && (
                          <Typography className="text-red pt-8 text-14 font-medium">
                            {t('quickChat.createBot.domainError')}
                          </Typography>
                        )} */}
                        <span className="lowercase block mt-10">
                          {process.env.REACT_APP_PORTAL_URL}/<b>{botData?.domainName}</b>
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <InputLabel
                          htmlFor="botName"
                          className="text-16 font-medium mb-10 text-grey-900 block"
                        >
                          {t('quickChat.createBot.selectAvatar')}
                        </InputLabel>
                        <div className="inline-block text-12 text-darkgreen font-semibold relative">
                          <label htmlFor="button-file">
                            <input
                              accept="image/*"
                              name="button-file"
                              className="absolute right-0 w-full opacity-0 cursor-pointer"
                              id="button-file"
                              type="file"
                              onChange={handleFileChange}
                            />
                            <Button className="hover:bg-white" component="span">
                              {t('quickChat.createBot.upload')}{' '}
                            </Button>
                          </label>
                          <Tooltip
                            placement="bottom-end"
                            className="relative z-9"
                            title={t('quickChat.createBot.info')}
                            arrow
                          >
                            <Icon className="text-16 align-middle">info</Icon>
                          </Tooltip>
                        </div>
                      </div>
                      <div>
                        <FormControl className="block mb-48">
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            className="block"
                            value={selectAvatarUrl}
                            onChange={(event) => handleSelectAvtar(event.target.value)}
                          >
                            <Swiper
                              breakpoints={{
                                319: {
                                  slidesPerView: 3,
                                },
                                590: {
                                  slidesPerView: 5,
                                },
                                768: {
                                  slidesPerView: 6,
                                },
                                992: {
                                  slidesPerView: 6,
                                },
                              }}
                              slidesPerView={4}
                              autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                              }}
                              modules={[Pagination, Autoplay]}
                              className="swiperLogo"
                            >
                              {avatarList &&
                                avatarList?.length > 0 &&
                                avatarList?.map((item, index) => (
                                  <SwiperSlide key={index}>
                                    <FormControlLabel
                                      value={item?.avatar}
                                      control={
                                        <Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />
                                      }
                                      label={
                                        <code className="flex justify-between items-center">
                                          <span className="mx-auto rounded-md">
                                            <img
                                              src={item?.avatar}
                                              alt="..."
                                              className="max-w-96 border-1 border-solid border-grey-400  max-h-96 mx-auto w-full rounded-md p-20"
                                            />
                                          </span>
                                        </code>
                                      }
                                      className={`rounded-md m-0 relative inline-block text-center mx-6 ${
                                        selectAvatarUrl === item?.avatar
                                          ? 'bg-darkgreen-100'
                                          : 'bg-white border-grey '
                                      }`}
                                    />
                                  </SwiperSlide>
                                ))}
                            </Swiper>
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div>
                        <InputLabel
                          htmlFor="colorPicker"
                          className="text-16 font-medium mb-10 text-grey-900 block"
                        >
                          {t('quickChat.createBot.selectColor')}
                        </InputLabel>
                        <span
                          className="w-44 h-44 inline-block rounded-full mr-16 align-middle text-center pt-8"
                          style={{ backgroundColor: color }}
                        >
                          <Icon className="text-white font-bold text-28">check</Icon>
                        </span>
                        <span
                          className="inline-block align-middle"
                          aria-hidden="true"
                          onClick={() => setColorBox(true)}
                        >
                          <img
                            src="/assets/images/business/color.png"
                            className="img-fluid w-44 h-44"
                            alt="..."
                          />
                        </span>
                        {colorBox && (
                          <div className="shadow p-16 inline-block absolute bg-white z-20 ml-16">
                            <Icon
                              className="text-14 absolute top-2 right-2 cursor-pointer"
                              onClick={() => setColorBox(false)}
                            >
                              close
                            </Icon>
                            <SketchPicker
                              color={color}
                              className="!shadow-none border border-1 border-solid border-grey-300"
                              onChangeComplete={(colors) => setColor(colors.hex)}
                            />
                            <div className="border border-1 border-solid border-grey-900 rounded-sm flex items-center p-5 mt-8">
                              <span
                                style={{
                                  backgroundColor: color,
                                  border: '1px solid #eee',
                                  width: 20,
                                  height: 20,
                                  color: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              />
                              <span className="inline-block ml-10 ">{color}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="text-center">
                        {quickChatEdit && (
                          <Button
                            type="button"
                            onClick={() => {
                              setQuickChatEdit(false);
                              setQuickChatCreate(false);
                              setBotData((prevBotData) => ({
                                ...prevBotData,
                                domainName: quickChatDetails?.domain,
                                botName: quickChatDetails?.name,
                              }));
                              setIsEmpty({ botName: false, domainName: false });
                              setImageUpload();
                              setImageUrl(quickChatDetails?.avatar);
                            }}
                            className="rounded-md mt-72 mx-12"
                            variant="outlined"
                            color="secondary"
                          >
                            {t('quickChat.chatbotDetail.cancel')}
                          </Button>
                        )}
                        <Button
                          type="button"
                          disabled={
                            isEmpty?.botName ||
                            isEmpty?.domainName ||
                            (domainAvailable && botData?.domainName === quickChatDetails?.domain)
                          }
                          onClick={() => handleCreateBot()}
                          className="rounded-md mt-72 mx-12 disabled:bg-gray disabled:text-black"
                          variant="contained"
                          color="secondary"
                        >
                          {quickChatEdit
                            ? `${t('quickChat.chatbotDetail.save')}`
                            : `${t('quickChat.createBot.button')}`}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="bg-grey-200 shadow rounded-md p-24 text-center">
                    <span className="block text-20 font-semibold block text-center mb-24">
                      {t('quickChat.createBot.preview')}
                    </span>
                    <div className="relative inline-block mx-auto">
                      <div
                        className={`text-white absolute left-5 right-5 p-5 top-5 rounded-tl-2xl rounded-tr-2xl bg-[${color}]`}
                        style={{ backgroundColor: `${color}` }}
                      >
                        <div className="flex items-center">
                          <span className="inline-block w-32 h-32 bg-white rounded-full text-center pt-5 mr-6">
                            <img
                              src={imageUrl || selectAvatarUrl}
                              className="img-fluid w-20 h-20 mx-auto"
                              alt="..."
                            />
                            <span className="w-10 h-10 bg-green inline-block rounded-full ml-24 z-10 -mb-0 relative bottom-8" />
                          </span>
                          {botData?.botName}
                          <Icon className="text-12 float-right absolute right-8">close</Icon>
                        </div>
                      </div>
                      <div className="absolute top-56 left-8">
                        <span className="inline-block align-middle w-32 h-32 bg-darkgreen-100 rounded-full text-center pt-5 mr-6">
                          <img
                            src={imageUrl || selectAvatarUrl}
                            className="img-fluid w-20 h-20 mx-auto"
                            alt="..."
                          />
                        </span>
                        <div className="inline-block align-middle">
                          <div
                            className="dot w-6 h-6 rounded-full inline-block mx-2"
                            style={{ backgroundColor: `${color}` }}
                          />
                          <div
                            className="dot w-6 h-6 rounded-full inline-block mx-2"
                            style={{ backgroundColor: `${color}`, opacity: 0.5 }}
                          />
                          <div
                            className="dot w-6 h-6 rounded-full inline-block mx-2"
                            style={{ backgroundColor: `${color}`, opacity: 0.5 }}
                          />
                        </div>
                      </div>
                      <img
                        src="/assets/images/business/mobile_frame.png"
                        className="img-fluid max-w-256 w-full mx-auto mb-24"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white max-w-640 shadow">
                  <div className="flex items-center justify-between bg-grey-100 px-20 py-16">
                    <h2 className="text-16 font-semibold">{t('quickChat.chatbotDetail.title')}</h2>
                    <Button
                      type="button"
                      onClick={() => {
                        setQuickChatCreate(true);
                        setQuickChatEdit(true);
                        setBotData((prevBotData) => ({
                          ...prevBotData,
                          domainName: quickChatDetails?.domain,
                          botName: quickChatDetails?.name,
                        }));
                      }}
                      className="rounded-full"
                      size="small"
                      variant="contained"
                      color="secondary"
                    >
                      {t('quickChat.chatbotDetail.edit')}
                    </Button>
                  </div>
                  <div className="px-20 py-32">
                    <div className="flex">
                      <img
                        src={quickChatDetails?.avatar}
                        alt="..."
                        className="max-w-80 max-h-80 w-full mr-28 border-1 border-solid border-grey-300 rounded-md p-10"
                      />
                      <div>
                        <h4 className="text-14 font-medium">{quickChatDetails?.name}</h4>
                        <span className="lowercase block mt-10 relative">
                          <span id="domain" className="inline-block">
                            {process.env.REACT_APP_PORTAL_URL}/{quickChatDetails?.domain}
                          </span>

                          <Button
                            className="min-w-28 min-h-28 hover:bg-darkgreen hover:text-white text-darkgreen bg-darkgreen-100 md:ml-16 ml-5 inline-block text-12"
                            type="button"
                            variant="contained"
                            size="small"
                            onClick={() => handleCopyClick('domain')}
                          >
                            <ContentCopyRoundedIcon className="text-15" />{' '}
                            {t('quickChat.chatbotDetail.copy')}
                          </Button>
                          {copy && (
                            <span className="absolute bg-black text-white text-12 px-8 rounded -top-0 duration-300 animate-bounce">
                              {t('business.selectedBusinessDetail.labels.copied')}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="bg-white rounded-md relative shadow md:p-72 sm:p-28 p-16 chat-bg">
              <div>
                <div className="sm:flex items-center justify-between">
                  <div>
                    <Typography className="text-20 md:text-44 font-bold mb-16 md:mb-0 max-w-400">
                      {t('quickChat.noBot.title')}
                    </Typography>
                    <Typography className="max-w-400 text-16 font-medium mt-32">
                      {t('quickChat.noBot.desc')}
                    </Typography>{' '}
                    <Button
                      type="button"
                      onClick={() => {
                        if (!userCurrentBusinessDetails?.isOnlineBusiness) {
                          setQuickChatCreate(true);
                          setQuickChatAvailable(true);
                        } else {
                          enqueueSnackbar(`${t('quickChat.createBot.message')}`, {
                            variant: 'info',
                            autoHideDuration: 3000,
                          });
                        }
                      }}
                      className="rounded-md mt-24 sm:mb-0 mb-28"
                      variant="contained"
                      color="secondary"
                    >
                      {t('quickChat.createBot.button')}
                    </Button>
                  </div>
                  <div>
                    <img
                      src="/assets/images/business/chat.gif"
                      className="img-fluid relative -mt-44 -mr-40"
                      alt="..."
                    />
                  </div>
                </div>

                <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28 mt-84">
                  <div className="bg-white rounded-md py-20 sm:px-28 px-16">
                    <img
                      src="/assets/images/business/chat-2.png"
                      className="img-fluid max-w-76 w-full mb-24"
                      alt="..."
                    />
                    <h3 className="font-bold sm:text-18 text-16 mb-14">
                      {t('quickChat.noBot.use.title1')}
                    </h3>
                    <Typography className="sm:text-16 font-medium text-grey-800">
                      {t('quickChat.noBot.use.desc1')}
                    </Typography>
                  </div>
                  <div className="bg-white rounded-md py-20 sm:px-28 px-16">
                    <img
                      src="/assets/images/business/chat-1.png"
                      className="img-fluid max-w-76 w-full mb-24"
                      alt="..."
                    />
                    <h3 className="font-bold sm:text-18 text-16 mb-14">
                      {t('quickChat.noBot.use.title2')}
                    </h3>
                    <Typography className="sm:text-16 font-medium text-grey-800">
                      {t('quickChat.noBot.use.desc2')}
                    </Typography>
                  </div>
                  <div className="bg-white rounded-md py-20 sm:px-28 px-16">
                    <img
                      src="/assets/images/business/chat-3.png"
                      className="img-fluid max-w-76 w-full mb-24"
                      alt="..."
                    />
                    <h3 className="font-bold sm:text-18 text-16 mb-14">
                      {t('quickChat.noBot.use.title3')}
                    </h3>
                    <Typography className="sm:text-16 font-medium text-grey-800">
                      {t('quickChat.noBot.use.desc3')}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={tabValue !== 1 ? 'hidden' : 'bg-white'}>
          <ChatInbox quickChatId={quickChatDetails?.id} />
        </div>
      </div>
    </div>
  );
};

export default QuickChat;
