import { Button, CircularProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { yupResolver } from '@hookform/resolvers/yup';
import { FixedSizeList as List } from 'react-window';
import PhoneInput from 'react-phone-input-2';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ReactSelect from 'react-select';
import { handleApiRequest, handlePhoneNumberValidationCheck } from '../../common/common';
import userData from '../../query/user';
import queryData from '../../query/common';
import { getBusinessTypeData, getCountryData } from '../../../store/userSlice';

const BusinessDetail = (props) => {
  const {
    businessDetailBasedOnUserIdData,
    setBusinessDetailBasedOnUserIdData,
    isEditBusiness,
    setIsEditBusiness,
    tabValue,
  } = props;
  const CustomMenuList = (prop) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = prop;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const defaultValues = {};

  const schema = yup.object().shape({
    businesses: yup.array().of(
      yup.object().shape({
        name: yup.string().required(t('users.businessDetails.required.name')),
        email: yup
          .string()
          .email(
            t('appWebsite.newDomain.registerDomain.formField.email.validationMessages.validEmail')
          )
          .trim(),
        city: yup.string().required(t('users.businessDetails.required.city')),
        zipCode: yup.string().test('len', t('users.businessDetails.required.zipcode'), (val) => {
          const length = val ? val.toString().length : 0;
          return length === 5 || length === 6 || length === 9;
        }),
        states: yup.object({
          id: yup.number().positive().required(t('users.businessDetails.required.state')),
        }),
      })
    ),
  });

  const {
    control,
    formState,
    handleSubmit,
    reset,
    setError,
    getValues,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const routeParams = useParams();
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [businessAttributesData, setBusinessAttributesData] = useState([]);
  const [businessId, setBusinessId] = useState();
  const [businessUserData, setBusinessUserData] = useState(businessDetailBasedOnUserIdData);
  const [prevBusinessId, setPrevBusinessId] = useState(null);
  const [prevCountryId, setPrevCountryId] = useState(null);
  const [businessTypeId, setBusinessTypeId] = useState(0);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState('');
  const [phoneValue, setPhoneValue] = useState();
  const [phones, setPhones] = useState();
  const businessTypeList = useSelector(getBusinessTypeData);
  const [countryId, setCountryId] = useState(0);
  const countriesData = useSelector(getCountryData);

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    if (businessDetailBasedOnUserIdData?.length > 0) {
      setBusinessUserData(businessDetailBasedOnUserIdData);
    }
  }, [businessDetailBasedOnUserIdData]);

  useEffect(() => {
    if (businessTypeList?.length > 0) {
      const businessType = businessTypeList?.map((idx) => ({
        value: idx.id,
        label: idx.bType,
      }));
      setBusinessAttributesData(businessType);
    }
  }, [businessTypeList]);

  useEffect(() => {
    async function fetchData() {
      if (
        countryId &&
        businessId &&
        (businessId !== prevBusinessId || countryId !== prevCountryId)
      ) {
        setPrevBusinessId(businessId);
        setPrevCountryId(countryId);
        const payload = {
          query: queryData.getStates,
          variables: { countryId },
        };
        const res = await handleApiRequest(payload);
        if (res?.getStates?.status === 200) {
          const updatedBusinessData = businessUserData?.map((businessItem) => {
            if (businessItem.id === businessId) {
              return {
                ...businessItem,
                countries: {
                  ...businessItem.countries,
                  states: res?.getStates?.data,
                },
              };
            }
            return businessItem;
          });

          setBusinessUserData(updatedBusinessData);
        }
      }
    }
    fetchData();
  }, [countryId, businessUserData, businessId, prevBusinessId, prevCountryId]);

  function handlePhoneChange(event, data, index) {
    const phoneNumber = event.replace(/\D/g, '');
    setValue(`businesses[${index}].phoneNumber.phoneNumber`, phoneNumber);
    setValue(`businesses[${index}].phoneNumber.countryCode`, `+${data?.dialCode}`);
    setValue(`businesses[${index}].phoneNumber.isoCode`, data?.countryCode);
    const updatedBusinessData = [...businessUserData];
    updatedBusinessData[index].phoneNumber = {
      ...updatedBusinessData[index].phoneNumber,
      isoCode: data?.countryCode,
      countryCode: `+${data?.dialCode}`,
      phoneNumber,
    };
    setBusinessUserData(updatedBusinessData);
    if (/\d/.test(phoneNumber)) {
      clearErrors('businesses');
    }
  }
  const onSubmit = async (data) => {
    const businessesWithEmptyPhoneNumber = data?.businesses?.filter((business, index) => {
      const countryCode = business?.phoneNumber?.countryCode || '';
      const phoneNumber = business?.phoneNumber?.phoneNumber?.slice(countryCode.length) || '';
      if (!phoneNumber || phoneNumber.length <= 0) {
        setError(`businesses[${index}].phoneNumber`, {
          type: 'manual',
          message: t('users.businessDetails.required.phoneNumberRequired'),
        });
      }
      return !phoneNumber || phoneNumber.length <= 0;
    });

    if (businessesWithEmptyPhoneNumber.length > 0) {
      return;
    }

    setRefreshIcon(true);
    const updatedBusinessDetails = data.businesses?.map((business) => {
      const countryCode = business?.phoneNumber?.countryCode || '';
      const checkPlusAddOrNot = countryCode?.includes('+') ? countryCode?.slice(1) : countryCode;
      const phoneNumber = business?.phoneNumber?.phoneNumber?.slice(checkPlusAddOrNot.length) || '';
      return {
        businessId: parseInt(business?.id, 10),
        website: business?.website,
        name: business?.name,
        email: business?.email,
        address: business?.address,
        countryId: parseInt(business?.countries?.id, 10),
        stateId: parseInt(business?.states?.id, 10),
        city: business?.city,
        reviewPlatformId: parseInt(business?.review_platforms?.id, 10),
        businessTypeId: parseInt(business?.business_types?.id, 10),
        zipCode: parseInt(business?.zipCode, 10),
        ...(business?.phoneNumber && {
          phoneNumber: {
            isoCode: business?.phoneNumber?.isoCode,
            countryCode,
            phoneNumber: parseInt(phoneNumber, 10),
          },
        }),
      };
    });
    const payload = {
      query: userData.updateMultipleBusinessDetail,
      variables: {
        businessDetail: updatedBusinessDetails,
      },
    };
    const res = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (res?.updateMultipleBusinessDetail?.status === 200) {
      const dataObj = {
        query: userData.getBusinessDetailBasedOnUserId,
        variables: {
          userId: parseInt(routeParams.userId, 10),
          isOnlineBusiness: tabValue !== 1,
        },
      };
      enqueueSnackbar(res?.updateMultipleBusinessDetail?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setIsEditBusiness(false);
      const result = await handleApiRequest(dataObj);
      if (result?.getBusinessDetailBasedOnUserId?.status === 200) {
        if (
          result?.getBusinessDetailBasedOnUserId?.data &&
          result?.getBusinessDetailBasedOnUserId?.data[0]?.user_businesses?.phoneNumber?.phoneNumber
            ?.slice(
              result?.getBusinessDetailBasedOnUserId?.data[0]?.user_businesses?.phoneNumber
                ?.countryCode.length
            )
            .toString() !== null
        ) {
          setBusinessDetailBasedOnUserIdData(
            result?.getBusinessDetailBasedOnUserId?.data[0]?.user_businesses
          );
        }
      }
    } else {
      enqueueSnackbar(res?.updateMultipleBusinessDetail?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  useEffect(() => {
    if (businessDetailBasedOnUserIdData?.length > 0) {
      setValue(
        'businesses',
        businessDetailBasedOnUserIdData?.map((item) => {
          const phoneNumberString = item?.phoneNumber?.phoneNumber?.toString();
          const countryCode = item?.phoneNumber?.countryCode;

          return {
            ...item,
            phoneNumber: {
              ...item.phoneNumber,
              phoneNumber: `${countryCode}${phoneNumberString}`.slice(1),
            },
          };
        })
      );
    }
  }, [setValue, businessDetailBasedOnUserIdData]);
  const handleSelectCountry = (e, id, businessIndex) => {
    const selectedCountryId = e.target.value;
    setCountryId(parseInt(selectedCountryId, 10));
    setBusinessId(id);
    setValue(`businesses[${businessIndex}].countries.id`, selectedCountryId);
    setValue(`businesses[${businessIndex}].states.id`, null);
    const selectedCountry = countryList?.find((country) => country?.id === selectedCountryId);
    if (selectedCountry) {
      const updatedBusinessData = [...businessUserData];
      updatedBusinessData[businessIndex] = {
        ...updatedBusinessData[businessIndex],
        countries: {
          id: selectedCountry?.id,
          name: selectedCountry?.name,
        },
      };
      setBusinessUserData(updatedBusinessData);
    }
  };

  const handleSelectState = (e, businessIndex) => {
    const selectedStateId = e.target.value;
    const selectedState = businessUserData[businessIndex]?.countries?.states?.find(
      (state) => state?.id === selectedStateId
    );
    clearErrors(`businesses[${businessIndex}].states`, { shouldClearErrors: true });
    setValue(`businesses[${businessIndex}].states.id`, parseInt(selectedStateId, 10));
    if (selectedState) {
      const updatedBusinessData = [...businessUserData];
      updatedBusinessData[businessIndex] = {
        ...updatedBusinessData[businessIndex],
        states: {
          id: selectedState?.id,
          name: selectedState?.name,
        },
      };
      setBusinessUserData(updatedBusinessData);
    }
  };

  const handleSelectBusinessType = (e, businessIndex) => {
    const selectedBusinessTypeId = e.value;
    const selectedBusinessType = businessAttributesData?.find(
      (business) => business?.value === selectedBusinessTypeId
    );
    setValue(
      `businesses[${businessIndex}].business_types.id`,
      parseInt(selectedBusinessTypeId, 10)
    );
    if (selectedBusinessType) {
      const updatedBusinessData = [...businessUserData];
      updatedBusinessData[businessIndex] = {
        ...updatedBusinessData[businessIndex],
        business_types: {
          id: selectedBusinessType.value,
          bType: selectedBusinessType.label,
        },
      };
      setBusinessUserData(updatedBusinessData);
    }
  };

  return (
    <div>
      {!isEditBusiness ? (
        <>
          <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32">
            {businessUserData &&
              businessUserData?.length > 0 &&
              businessUserData?.map((business, i) => (
                <div key={i}>
                  <h4 className="font-bold text-20 mb-24 flex items-center">
                    {t('users.userDetails.labels.business')} {i + 1}{' '}
                    <span
                      className={`inline-block px-5 flex items-center text-white text-center rounded-md text-12 font-semibold ml-5  ${
                        business?.isActive === true ? 'bg-green-700' : 'bg-red-700'
                      }`}
                    >
                      <span className="block mx-auto">
                        {business?.isActive === true ? 'Active' : 'Inactive'}
                      </span>
                    </span>
                  </h4>

                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('users.userDetails.labels.businessName')}
                      <span className=" text-2xl text-red-500">*</span>
                    </span>
                    <span className="text-15 break-all font-medium block text-grey-600">
                      {business?.name}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('signUpPage.formField.address.name')}
                    </span>
                    <span className="text-15 break-all font-medium block text-grey-600">
                      {business?.address ? business?.address : '-'}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('signUpPage.formField.country.name')}
                      <span className=" text-2xl text-red-500">*</span>
                    </span>
                    <span className="text-15 font-medium block text-grey-600">
                      {business?.countries?.name}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('signUpPage.formField.state.name')}
                      <span className=" text-2xl text-red-500">*</span>
                    </span>
                    <span className="text-15 font-medium block text-grey-600">
                      {business?.states?.name ? business?.states?.name : '-'}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('signUpPage.formField.city.name')}
                      <span className=" text-2xl text-red-500">*</span>
                    </span>
                    <span className="text-15 font-medium block text-grey-600">
                      {business?.city}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('signUpPage.formField.zipCode.name')}
                      <span className=" text-2xl text-red-500">*</span>
                    </span>
                    <span className="text-15 font-medium block text-grey-600">
                      {business?.zipCode}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('users.userDetails.labels.businessPhone')}
                      <span className=" text-2xl text-red-500">*</span>
                    </span>
                    <span className="text-15 font-medium block text-grey-600">
                      {business?.phoneNumber?.phoneNumber &&
                        business?.phoneNumber?.countryCode?.charAt(0) !== '+' &&
                        '+'}
                      {business?.phoneNumber?.countryCode}{' '}
                      {business?.phoneNumber?.phoneNumber
                        ? business?.phoneNumber?.phoneNumber
                        : '-'}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('users.userDetails.labels.businessEmail')}
                    </span>
                    <span className="text-15 font-medium block text-grey-600">
                      {business?.email ? business?.email : '-'}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('users.userDetails.labels.businessType')}
                    </span>
                    <span className="text-15 font-medium block text-grey-600">
                      {business?.business_types?.bType ? business?.business_types?.bType : '-'}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block break-words">
                      {t('users.userDetails.labels.businessWebsite')}
                    </span>
                    <span className="text-15 break-all font-medium block break-words text-grey-600">
                      {business?.website ? business?.website : '-'}
                    </span>
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('users.userDetails.labels.reviewSites')}
                    </span>
                    <span className="text-15 font-medium block text-black-600">
                      {business?.user_business_review_platforms.length > 0
                        ? business?.user_business_review_platforms?.map((reviewPlatform, index) => (
                            <div
                              className="block border-b-2 border-dashed pb-12 mt-12 last:border-b-0"
                              key={i}
                            >
                              <div className="items-center flex">
                                <img
                                  width="20"
                                  className="mr-5"
                                  src={reviewPlatform?.logo}
                                  alt="--"
                                />{' '}
                                <div>
                                  <Typography className="font-semibold">
                                    {reviewPlatform?.name ? reviewPlatform?.name : '-'}
                                  </Typography>
                                  <Typography>{business?.address}</Typography>
                                </div>
                              </div>
                            </div>
                          ))
                        : '-'}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isEditBusiness && (
              <>
                <Button
                  className="text-18 absolute right-[131px] top-[-88px] px-32 disabled:text-black"
                  variant="contained"
                  color="secondary"
                  disabled={
                    !isValid ||
                    refreshIcon ||
                    errorMessage === false ||
                    phoneValue?.length === 0 ||
                    (phoneValue &&
                      phones?.dialCode &&
                      phoneValue.slice(phones?.dialCode?.length)?.length === 0)
                  }
                  type="submit"
                >
                  {t('users.userDetails.buttons.save')}
                </Button>
                {refreshIcon && (
                  <CircularProgress
                    size={24}
                    className="text-darkgreen absolute right-[168px] top-[-80px] mx-auto"
                  />
                )}
              </>
            )}
            <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32">
              {businessUserData?.map((business, index) => (
                <div key={index}>
                  <h4 className="font-bold text-20 mb-24 flex items-center">
                    {t('users.userDetails.labels.business')} {index + 1}{' '}
                    <span
                      className={`inline-block px-5 flex items-center text-white text-center rounded-md text-12 font-semibold ml-5  ${
                        business?.isActive === true ? 'bg-green-700' : 'bg-red-700'
                      }`}
                    >
                      <span className="block mx-auto">
                        {business?.isActive === true ? 'Active' : 'Inactive'}
                      </span>
                    </span>
                  </h4>
                  <div className="mb-24">
                    <Controller
                      name={`businesses[${index}].name`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('users.businessDetails.label.businessName')}
                          type="text"
                          variant="outlined"
                          fullWidth
                          error={!!errors?.businesses?.[index]?.name}
                          helperText={errors?.businesses?.[index]?.name?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-24">
                    <Controller
                      name={`businesses[${index}].address`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('users.businessDetails.label.address')}
                          type="text"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="mb-0">
                    <Controller
                      name={`businesses[${index}].countries`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="mb-24 max-w-sm w-full"
                          id="country"
                          value={business?.countries?.id}
                          displayEmpty
                          error={!!errors.country}
                          onChange={(e) => handleSelectCountry(e, business.id, index)}
                        >
                          <MenuItem value={0} disabled>
                            {t('signUpPage.formField.country.selectCountry')}
                          </MenuItem>
                          {countryList?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                  <div className="mb-24">
                    <Controller
                      name={`businesses[${index}].states`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="max-w-sm"
                          value={business?.states?.id}
                          displayEmpty
                          id="state"
                          onChange={(e) => {
                            handleSelectState(e, index);
                          }}
                          error={!!errors?.businesses?.[index]?.states}
                          helperText={errors?.businesses?.[index]?.states?.message}
                          fullWidth
                        >
                          <MenuItem value={0} disabled>
                            {t('signUpPage.formField.state.selectState')}
                          </MenuItem>
                          {business?.countries?.states?.map((option) => {
                            return (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                    {errors?.businesses?.[index]?.states && (
                      <Typography variant="caption" color="error">
                        {t('users.businessDetails.required.state')}
                      </Typography>
                    )}
                  </div>
                  <div className="mb-24">
                    <Controller
                      name={`businesses[${index}].city`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('users.businessDetails.label.city')}
                          type="text"
                          variant="outlined"
                          fullWidth
                          error={!!errors?.businesses?.[index]?.city}
                          helperText={errors?.businesses?.[index]?.city?.message}
                        />
                      )}
                    />
                  </div>
                  <div key={index} className="mb-24">
                    <Controller
                      name={`businesses[${index}].zipCode`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('users.businessDetails.label.zipCode')}
                          type="number"
                          variant="outlined"
                          fullWidth
                          error={!!errors?.businesses?.[index]?.zipCode}
                          helperText={errors?.businesses?.[index]?.zipCode?.message}
                          inputProps={{
                            maxLength: 9,
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          onInput={(e) => {
                            if (e.target.value.length > 9) {
                              e.target.value = e.target.value.slice(0, 9);
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-24">
                    <Controller
                      name={`businesses[${index}].phoneNumber`}
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                          inputStyle={{
                            width: '100%',
                            height: '52px',
                          }}
                          className="w-full max-w-sm"
                          name="phone_number"
                          required
                          value={field.value?.phoneNumber?.toString()}
                          country={field.value?.isoCode || ''}
                          enableSearch
                          onChange={(value, data) => {
                            setPhoneValue(value);
                            setPhones(data);
                            handlePhoneChange(value, data, index);
                            const validPhoneNumber = handlePhoneNumberValidationCheck(value, data);
                            setErrorMessage(validPhoneNumber);
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="mb-24">
                    <Controller
                      name={`businesses[${index}].email`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('users.businessDetails.label.businessEmail')}
                          type="text"
                          variant="outlined"
                          fullWidth
                          error={!!errors?.businesses?.[index]?.email}
                          helperText={errors?.businesses?.[index]?.email?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-24 relative">
                    <Controller
                      name={`businesses[${index}].business_types.bType`}
                      control={control}
                      render={({ field }) => (
                        <>
                          {business?.business_types?.id && (
                            <label className="label-businessType" htmlFor="businessType">
                              {t('business.selectBusinessType')}
                            </label>
                          )}
                          <ReactSelect
                            labelId="businessType"
                            className="select"
                            placeholder={t('business.selectBusinessType')}
                            id="businessType"
                            name="businessType"
                            value={{
                              label: business.business_types?.bType,
                              value: business.business_types?.id,
                            }}
                            onChange={(e) => {
                              handleSelectBusinessType(e, index);
                            }}
                            options={businessAttributesData}
                            components={{
                              MenuList: CustomMenuList,
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div className="mb-24 z-0">
                    <Controller
                      name={`businesses[${index}].website`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="z-0"
                          label={t('users.businessDetails.label.businessWebsite')}
                          type="text"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="mb-24">
                    <span className="text-15 font-semibold block">
                      {t('users.userDetails.labels.reviewSites')}
                    </span>
                    <span className="text-15 font-medium block text-black-600">
                      {business?.user_business_review_platforms.length > 0
                        ? business?.user_business_review_platforms?.map((reviewPlatform, i) => (
                            <div
                              className="block border-b-2 border-dashed pb-12 mt-12 last:border-b-0"
                              key={i}
                            >
                              <div className="items-center flex">
                                <img
                                  width="20"
                                  className="mr-5"
                                  src={reviewPlatform?.logo}
                                  alt="--"
                                />{' '}
                                <div>
                                  <Typography className="font-semibold">
                                    {reviewPlatform?.name ? reviewPlatform?.name : '-'}
                                  </Typography>
                                  <Typography>{business?.address}</Typography>
                                </div>
                              </div>
                            </div>
                          ))
                        : '-'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default BusinessDetail;
