import { Button, Icon, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import Loading from '../manage-business/Loading';
import quickPostQuery from '../../query/quickPost';
import { handleApiRequest } from '../../common/common';
import SharePost from '../quick-post/SharePost';
import UserProfile from '../../common/userProfile';

const QuickSocialFeatures = ({
  setActiveStep,
  surpriseMeCardData,
  allFeaturesTrialDataId,
  socialTrialDone,
}) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [shareCompOpen, setShareCompOpen] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [allReviewData, setAllReviewData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createPostDetail, setCreatePostDetail] = useState('');
  const [allDataForImage, setAllDataForImage] = useState({});
  const [newSuggestedText, setNewSuggestedText] = useState('');
  const [selectedReviewDetails, setSelectedReviewDetails] = useState({});
  const [avialabaleTempImage, setAvialabaleTempImage] = useState([]);

  const allReviewsData = useCallback(async () => {
    if (!userCurrentBusinessDetails?.id) return;
    const payloadData = {
      query: quickPostQuery.getAllReviewForFeatureExplore,
      variables: {
        businessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };

    try {
      const resultData = await handleApiRequest(payloadData);
      if (resultData?.getAllReviewForFeatureExplore?.status === 200) {
        setAllReviewData(resultData?.getAllReviewForFeatureExplore?.data);
        setSelectedReviewDetails(resultData?.getAllReviewForFeatureExplore?.data[0]);
      }
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    allReviewsData();
  }, [allReviewsData]);

  const handleShare = async () => {
    setShareCompOpen(true);
    const payloadData = {
      query: quickPostQuery.saveViewedPosts,
      variables: {
        userBusinessesId: parseInt(userCurrentBusinessDetails?.id, 10),
        sharePostTemplateId: allDataForImage?.id,
        reviewText: {
          review: newSuggestedText,
          reviewPlatformTypeId: userCurrentBusinessDetails?.reviewPlatformId,
          reviewRating: selectedReviewDetails?.reviewRating,
          reviewerName: selectedReviewDetails?.reviewerName,
        },
      },
    };
    await handleApiRequest(payloadData);

    const payload = {
      query: quickPostQuery.updateSurpriseMeCardData,
      variables: {
        quickHubSurpriseCardId: allFeaturesTrialDataId,
        planType: 'social',
      },
    };
    await handleApiRequest(payload);
    surpriseMeCardData();
  };

  const handaleClose = () => {
    setShareCompOpen(false);
  };
  useEffect(() => {
    try {
      const fetchData = async () => {
        const payloadData = {
          query: quickPostQuery.sharePostGenerateSummarizeReviews,
          variables: {
            review: selectedReviewDetails?.review,
            tokens: 'large',
          },
        };

        const resultData = await handleApiRequest(payloadData);
        if (resultData?.sharePostGenerateSummarizeReviews?.status === 200) {
          setNewSuggestedText(
            resultData?.sharePostGenerateSummarizeReviews?.data?.summarizeReviews
          );
        }
      };
      fetchData();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [selectedReviewDetails?.review]);

  const similarPost = useCallback(async () => {
    setLoadingPage(true);
    try {
      const payloadData = {
        query: quickPostQuery.getSharePostTemplate,
      };
      const resultData = await handleApiRequest(payloadData);
      setLoadingPage(false);
      if (resultData?.getSharePostTemplate?.status === 200) {
        setAllDataForImage(resultData?.getSharePostTemplate?.data[0]);
        setAvialabaleTempImage(resultData?.getSharePostTemplate?.data);
      }
    } catch (error) {
      setLoadingPage(false);
      console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      similarPost();
      surpriseMeCardData();
    }
  }, [userCurrentBusinessDetails?.id, similarPost, surpriseMeCardData]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        setLoading(true);
        const payloadData = {
          query: quickPostQuery.getConvertedSharePostImage,
          variables: {
            businessName: userCurrentBusinessDetails?.name,
            htmlTemplate: encodeURIComponent(allDataForImage?.template),
            reviewPlatformTypeId: userCurrentBusinessDetails?.reviewPlatformId,
            reviewRating: selectedReviewDetails?.reviewRating,
            reviewerName: selectedReviewDetails?.reviewerName,
            review: newSuggestedText,
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setLoading(false);
        if (resultData?.getConvertedSharePostImage?.status === 200) {
          setCreatePostDetail(resultData?.getConvertedSharePostImage?.data?.socialPostUrl);
        }
      };
      fetchData();
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  }, [
    userCurrentBusinessDetails?.name,
    userCurrentBusinessDetails?.reviewPlatformId,
    selectedReviewDetails,
    newSuggestedText,
    allDataForImage?.template,
  ]);

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col-reverse md:flex-row items-center md:h-auto gap-28 md:gap-0">
      {allReviewData?.length > 0 && (
        <div className="m-full md:w-3/12 bg-white p-20 rounded-6">
          <h1 className="font-semibold text-20 mb-20">{t('createPost.review')}</h1>
          <div className="h-480 md:h-2xl overflow-y-auto flex flex-col gap-5 ">
            {allReviewData?.map((review, index) => (
              <div
                key={index}
                className={`w-full p-14 rounded-4 h-full cursor-pointer ${
                  review?.id === selectedReviewDetails?.id
                    ? 'bg-green-500 text-white'
                    : 'bg-indigo-50 bg-opacity-60'
                }`}
                onClick={() => {
                  setSelectedReviewDetails(review);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setSelectedReviewDetails(review);
                  }
                }}
                tabIndex={0}
                role="button"
                aria-label={`Preview ${index}`}
              >
                <div className="flex w-full gap-10 flex-col">
                  <div className="flex items-center">
                    <div className="mr-14 gap-12">
                      {review?.reviewerProfileUrl ? (
                        <img
                          className="w-48 h-48 rounded-full"
                          src={review?.reviewerProfileUrl || 'assets/images/icon/Profile.png'}
                          alt=""
                        />
                      ) : (
                        <UserProfile name={review?.reviewerName} width="45px" height="45px" />
                      )}
                    </div>
                    <div className="flex flex-col">
                      <Typography>{review?.reviewerName}</Typography>
                      <div>
                        {[...Array(5)].map((_, i) => (
                          <Icon
                            className={`text-16 align-middle mr-2 -mt-2 ${
                              i <
                              (parseInt(userCurrentBusinessDetails?.reviewPlatformId, 10) !== 7
                                ? review?.reviewRating
                                : review?.rating)
                                ? 'text-yellow-800'
                                : 'text-gray-400'
                            }`}
                            key={i}
                          >
                            star
                          </Icon>
                        ))}{' '}
                        {review?.reviewRating || review?.rating}.0
                      </div>
                    </div>
                  </div>
                  <p>{review?.review}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex px-0 sm:px-20 flex-col md:flex-row w-full md:w-9/12">
        <div className="w-full md:w-1/2 flex flex-col gap-20 bg-white p-20 rounded-l-6">
          <div className="flex items-center justify-center">
            {loading && (
              <div className="absolute loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
            )}
            {createPostDetail && (
              <img
                src={createPostDetail}
                className="rounded-md w-288 sm:w-360 h-288 sm:h-360 shadow-md object-cover"
                alt="Selected Template"
              />
            )}
          </div>
          <div className="max-h-112 bg-grey-100 rounded-4 px-10 mx-0 lg:mx-32">
            <Typography className="p-10 overflow-y-scroll text-16 font-medium line-clamp-3">
              {newSuggestedText}
            </Typography>
          </div>
          <div className="flex flex-row gap-10 items-center justify-center">
            <Button
              className="md:text-18 px-20 sm:px-60 font-semibold disabled:text-black rounded-md"
              variant="outlined"
              color="secondary"
              onClick={() => setActiveStep(2)}
            >
              {t('manageBusiness.button.next')}
            </Button>
            <Button
              className="bg-darkgreen rounded-md disabled:text-black disabled:bg-gray-300 text-white px-20 sm:px-40 hover:bg-darkgreen"
              onClick={handleShare}
              disabled={socialTrialDone}
            >
              {t('createPost.shareBtn')}
            </Button>
          </div>
        </div>
        <div className="hidden md:block w-2 bg-gray-500" />
        <div className="w-full md:w-1/2 flex-col bg-white p-20 rounded-r-6">
          <div className="max-w-auto md:max-w-512">
            <h1 className="font-semibold text-20 mb-20">{t('createPost.selectTemplates')}</h1>
            <div className="grid justify-between lg:grid-cols-2 sm:grid-cols-2 md:grid-cols-1 gap-6 max-h-288 md:max-h-2xl overflow-y-auto">
              {avialabaleTempImage?.map((img, index) => (
                <div key={index} className="relative group cursor-pointer p-4">
                  <div
                    className="w-full h-full flex p-0 cursor-pointer"
                    onClick={() => setAllDataForImage(img)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setAllDataForImage(img);
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    aria-label={`Preview ${index}`}
                  >
                    <img
                      src={img?.templateImage}
                      alt={`Preview ${index}`}
                      className={`w-full h-200 object-cover rounded border-2 border-solid ${
                        img?.id === allDataForImage?.id ? 'border-darkgreen' : 'border-white'
                      }`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {shareCompOpen && (
        <SharePost
          url={createPostDetail}
          title={t('createPost.checkThisPost')}
          image={createPostDetail}
          open={shareCompOpen}
          onClose={handaleClose}
        />
      )}
    </div>
  );
};

export default QuickSocialFeatures;
