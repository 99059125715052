import { Box, Typography, Button } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { t } from 'i18next';
import { selectDashboardData } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import QuickSocialFeatures from './QuickSocialFeatures';
import QuickChatFeatures from './QuickChatFeatures';
import QuickReviewsFeatures from './QuickReviewsFeatures';
import QuickCampaignsFeatures from './QuickCampaignsFeatures';
import quickPostQuery from '../../query/quickPost';
import { handleApiRequest } from '../../common/common';

const steps = ['Review', 'Social', 'Chat', 'Campaign'];

const ExploreFeatures = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [activeStep, setActiveStep] = useState(0);
  const [allFeaturesTrialDataId, setAllFeaturesTrialDataId] = useState();
  const [trialStatus, setTrialStatus] = useState({
    social: false,
    chat: false,
    campaign: false,
  });

  const surpriseMeCardData = useCallback(async () => {
    const payload = {
      query: quickPostQuery.getSurpriseMeCard,
      variables: {
        isHomeScreen: false,
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };
    const results = await handleApiRequest(payload);

    if (results?.getSurpriseMeCard?.status === 200) {
      setAllFeaturesTrialDataId(results?.getSurpriseMeCard?.data?.id);
      setTrialStatus({
        social: results?.getSurpriseMeCard?.data?.social?.isTrialDone,
        chat: results?.getSurpriseMeCard?.data?.chat?.isTrialDone,
        campaign: results?.getSurpriseMeCard?.data?.campaign?.isTrialDone,
      });
    }
  }, [userCurrentBusinessDetails]);

  return (
    <div className="p-20 lg:p-32 font-quicksand flex flex-col gap-20">
      <Typography className="text-20 sm:text-28 font-semibold">
        {t('dashboard.exploreFeatures.exploreFeatures')}
      </Typography>

      <div className="p-40 bg-white flex justify-center">
        <div className="flex">
          {steps.map((label, index) => (
            <React.Fragment key={label}>
              <div className="flex flex-row max-h-72">
                <div className="flex flex-col gap-20">
                  <Button className="flex flex-col justify-center hover:bg-transparent items-center">
                    <img
                      className={`rounded-full border-2 ${
                        activeStep === index
                          ? 'w-48 sm:w-68 border-darkgreen'
                          : 'w-44 sm:w-60 border-gray-300'
                      }`}
                      src={`/assets/images/quick-hub/quick-${label.toLowerCase()}.jpg`}
                      alt={label}
                    />
                  </Button>
                  <p className="text-center text-14 sm:text-16 font-semibold text-black">{label}</p>
                </div>
                {index < steps.length - 1 && (
                  <div
                    className={`w-36 sm:w-112 h-2 mt-20 -mx-12 sm:-mx-10 my-2 ${
                      activeStep >= index + 1 ? 'bg-darkgreen' : 'bg-gray-400'
                    }`}
                  />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div>
        {activeStep === 0 && (
          <Box>
            <QuickReviewsFeatures setActiveStep={setActiveStep} />
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <QuickSocialFeatures
              setActiveStep={setActiveStep}
              surpriseMeCardData={surpriseMeCardData}
              allFeaturesTrialDataId={allFeaturesTrialDataId}
              socialTrialDone={trialStatus.social}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box>
            <QuickChatFeatures
              setActiveStep={setActiveStep}
              allFeaturesTrialDataId={allFeaturesTrialDataId}
              chatTrialDone={trialStatus.chat}
              surpriseMeCardData={surpriseMeCardData}
            />
          </Box>
        )}
        {activeStep === 3 && (
          <Box>
            <QuickCampaignsFeatures
              allFeaturesTrialDataId={allFeaturesTrialDataId}
              campaignTrialDone={trialStatus.campaign}
              surpriseMeCardData={surpriseMeCardData}
            />
          </Box>
        )}
      </div>
    </div>
  );
};

export default ExploreFeatures;
