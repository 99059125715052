import { Button, CircularProgress, Dialog, DialogActions, Icon, Typography } from '@mui/material';
import { t } from 'i18next';

const DeletePopUp = (props) => {
  const {
    openValue,
    eventCall,
    cancelCall,
    refreshIcon,
    popUpMessage,
    popUpDescription,
    popUpButtonName,
  } = props;

  return (
    <Dialog
      classes={{
        paper:
          'm-24 sm:p-28 p-10 border-t-8 border-solid border-t-red-A300 border-red-A300 rounded-md',
      }}
      open={openValue}
      fullWidth
      maxWidth="xs"
    >
      <Icon
        onClick={() => cancelCall()}
        className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
      >
        cancel
      </Icon>
      <div className="w-full m-auto mt-24">
        <img className="w-144 text-center m-auto" src="/assets/images/icon/delete.png" alt="" />
      </div>
      <Typography className="text-20 font-semibold text-black text-center w-full my-28">
        {popUpMessage}
      </Typography>
      <Typography className="text-14 max-w-288 m-auto font-semibold text-black text-center w-full pb-10">
        {popUpDescription}
      </Typography>
      <DialogActions className="p-0 mt-20 justify-center">
        <Button
          className="md:text-16 font-medium hover:bg-white bg-white border border-solid !border-darkgreen bg-white  text-darkgreen rounded-lg sm:px-48"
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => cancelCall()}
        >
          {t('manageBusiness.addBusinessSteps.staffMember.dialog.cancel')}
        </Button>
        <Button
          className="md:text-16 font-medium disabled:bg-gray disabled:text-black hover:bg-red-A300 bg-red-A300 sm:px-24 rounded-lg"
          variant="contained"
          color="secondary"
          size="small"
          type="button"
          disabled={refreshIcon}
          onClick={() => eventCall()}
        >
          {popUpButtonName}
          {refreshIcon && (
            <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopUp;
