import {
  Button,
  ButtonGroup,
  CircularProgress,
  Icon,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import history from '@history';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation, useParams } from 'react-router-dom';
import userService from '../../../services';
import { handleApiRequest, handlePhoneNumberValidationCheck } from '../../common/common';
import businessData from '../../query/business';
import SuccessMessagePopup from '../manage-business/SuccessMessagePopup';
import CustomCssForInviteReview from './CustomCssForInviteReview.css';

const InviteReviewProductPage = () => {
  const params = useParams();
  const [tabValue, setTabValue] = useState(2);
  const [loading, setLoading] = useState(false);
  const [phoneData, setPhoneData] = useState(null);
  const [emailData, setEmailData] = useState(null);
  const [emailInput, setEmailInput] = useState('');
  const [phoneInput, setPhoneInput] = useState({ phoneNumber: '', countryCode: '' });
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [errorMessage, setErrorMessage] = useState(true);
  const [type, setType] = useState('messages');
  const location = useLocation();
  const { plateFormID, productDetailsData } = location.state || {};
  const productId = parseInt(params?.productId, 10);
  const loginUserData = userService.getUserData();

  const fetchTemplateData = useCallback(async () => {
    try {
      const payload = {
        query: businessData.getOnlineProductDefaultTemplate,
        variables: {
          genericName: productDetailsData[0]?.genericName,
          productUrl: productDetailsData[0]?.businessQRRedirectUrl,
          profileUrl: productDetailsData[0]?.profileUrl,
          type,
          reviewPlatformsId: productDetailsData[0]?.reviewPlatformId,
        },
      };
      const result = await handleApiRequest(payload);

      if (result?.getOnlineProductDefaultTemplate?.status === 200) {
        setEmailInput('');
        setPhoneInput({ phoneNumber: '', countryCode: '' });
        if (type === 'messages') {
          setPhoneData(result?.getOnlineProductDefaultTemplate?.data[0]?.htmlTemplate);
        } else if (type === 'email') {
          setEmailData(result?.getOnlineProductDefaultTemplate?.data[0]?.htmlTemplate);
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [productDetailsData, type]);

  useEffect(() => {
    fetchTemplateData();
  }, [fetchTemplateData]);

  const sendSmsOrEmail = async () => {
    setRefreshIcon(true);
    try {
      if (type === 'messages') {
        if (phoneInput?.phoneNumber.length === 0) {
          enqueueSnackbar(t('productSettings.phoneNumber.required'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
          setRefreshIcon(false);
          return;
        }

        if (!/^\d+$/.test(phoneInput?.phoneNumber)) {
          enqueueSnackbar(t('productSettings.phoneNumber.length'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
          setRefreshIcon(false);
          return;
        }
        const phoneLength = phoneInput?.phoneNumber
          .toString()
          .slice(phoneInput.countryCode.length).length;
        const maxLength = 15;
        if (phoneLength > maxLength) {
          enqueueSnackbar(t('productSettings.phoneNumber.numeric'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
          setRefreshIcon(false);
          return;
        }
      } else if (type === 'email') {
        if (emailInput.length === 0) {
          enqueueSnackbar(t('productSettings.emailId.required'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
          setRefreshIcon(false);
          return;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(emailInput)) {
          enqueueSnackbar(t('productSettings.emailId.invalid'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
          setRefreshIcon(false);
          return;
        }
      }
      const payload = {
        query: businessData.sendOnlineMessageAndEmail,
        variables: {
          campaignPlatformId: parseInt(tabValue, 10),
          userBusinessId: productDetailsData[0]?.userBusinessId,
        },
      };
      if (type === 'messages') {
        payload.variables.countryCode = `+${phoneInput.countryCode}`;
        payload.variables.phoneNumber = parseInt(
          phoneInput.phoneNumber.toString().slice(phoneInput.countryCode.length),
          10
        );
        payload.variables.smsTemplate = phoneData;
      } else if (type === 'email') {
        payload.variables.email = emailInput;
        payload.variables.emailTemplate = emailData;
      }
      if (plateFormID === 5) {
        payload.variables.amazonProductId = parseInt(productId, 10);
      } else if (plateFormID === 6) {
        payload.variables.flipkartProductId = parseInt(productId, 10);
      }

      const result = await handleApiRequest(payload);
      if (result?.sendOnlineMessageAndEmail?.status === 200) {
        setOpenDeleteDialogue(true);
      } else {
        enqueueSnackbar(result?.sendOnlineMessageAndEmail?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        enqueueSnackbar(error.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      } else {
        console.error('Error sending SMS/Email:', error);
      }
    } finally {
      setLoading(false);
      setRefreshIcon(false);
    }
  };

  const closeBox = () => {
    setOpenDeleteDialogue(false);
    fetchTemplateData();
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div>
      <div className="p-20 lg:p-32 h-full flex justify-center sm-justify-normal flex-col">
        <Toolbar className="font-semibold pl-20 min-h-fit">
          <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
            west
          </Icon>
          <Typography
            variant="subtitle1"
            color="inherit"
            className="flex-1 pl-12 font-bold text-18"
          >
            {t('products.productsettings')}
          </Typography>
        </Toolbar>
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-20 md:text-28 font-bold pl-20 pt-40 mb-16 md:mb-0"
        >
          {t('inviteReviews.title')}
        </Typography>
        <div className="w-full flex flex-col justify-center items-center bg-white p-10 sm:p-36 max-w-3xl mx-auto sm:mx-0 mt-0 sm:mt-28">
          <ButtonGroup
            value={tabValue}
            onChange={(e, newValue) => {
              setTabValue(newValue);
              setType(newValue === 2 ? 'messages' : 'email');
            }}
            variant="outlined"
            aria-label="Basic button group"
            className="rounded-none flex flex-row"
          >
            <Button
              className={`sm:text-18 font-semibold px-40 py-10 sm:px-92 rounded-12 w-full sm:max-w-320 mb-2 sm:mb-0 ${
                tabValue === 2 ? 'bg-darkgreen-100 text-darkgreen' : 'bg-white text-black'
              }`}
              type="button"
              onClick={() => {
                setTabValue(2);
                setType('messages');
              }}
            >
              {t('productSettings.sms')}
            </Button>
            <Button
              className={`sm:text-18 font-semibold px-40 py-10 sm:px-92 rounded-12 w-full sm:max-w-320 ${
                tabValue === 1 ? 'bg-darkgreen-100 text-darkgreen' : 'bg-white text-black'
              }`}
              type="button"
              onClick={() => {
                setTabValue(1);
                setType('email');
              }}
            >
              {t('productSettings.email')}
            </Button>
          </ButtonGroup>

          {tabValue === 2 && (
            <div className="flex flex-col justify-between items-center w-full">
              <div className="mt-20 flex flex-col justify-between items-center">
                <Typography className="text-16 md:text-28 font-bold pl-20 mt-20 mb-16 md:mb-0">
                  {t('productSettings.phoneNumber.title')}
                </Typography>
                <Typography className="text-16 font-500 pl-20 mt-8 mb-16 md:mb-0">
                  {t('productSettings.phoneNumber.note')}
                </Typography>
              </div>
              <div className="mt-32">
                <PhoneInput
                  placeholder={t('signUpPage.formField.phoneNumber.name')}
                  inputStyle={{
                    height: '42px',
                  }}
                  name="phone_number"
                  required
                  country={phoneInput.countryCode || loginUserData?.isoCode.toLowerCase()}
                  enableSearch="true"
                  value={phoneInput.phoneNumber}
                  onChange={(value, data) => {
                    const phoneNumber = value.replace(/[^0-9]/g, '');
                    const validPhoneNumber = handlePhoneNumberValidationCheck(phoneNumber, data);
                    setErrorMessage(validPhoneNumber);
                    setPhoneInput((prev) => ({
                      countryCode: data.countryCode || prev.countryCode,
                      phoneNumber,
                    }));
                  }}
                  className="rounded-lg border border-gray-300 focus:border-darkgreen focus:ring focus:ring-darkgreen focus:ring-opacity-50 sm:max-w-md"
                />

                {errorMessage === false &&
                  phoneInput?.phoneNumber?.slice(phoneInput?.countryCode.length)?.length > 0 && (
                    <p className="text-red mt-5 text-16">
                      {t('productSettings.InvalidPhoneNumber')}
                    </p>
                  )}
              </div>

              <Button
                className="bg-darkgreen hover:bg-darkgreen disabled:text-black disabled:bg-gray px-48 mt-48 text-white rounded-4"
                onClick={sendSmsOrEmail}
                disabled={
                  refreshIcon ||
                  errorMessage === false ||
                  !phoneInput?.phoneNumber?.slice(phoneInput?.countryCode.length)
                }
              >
                {t('sendMessage.sendSms')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
              <div className="w-full mt-40">
                <Typography className="text-20 mb-10 font-600 flex justify-start">
                  {t('productSettings.phoneNumber.message')}
                </Typography>
                <div className="CustomCss" dangerouslySetInnerHTML={{ __html: phoneData }} />
              </div>
            </div>
          )}

          {tabValue === 1 && (
            <div className="flex flex-col justify-between items-center">
              <div className="mt-20 flex flex-col justify-between items-center">
                <Typography className="text-16 md:text-28 font-bold pl-20 mt-20 mb-16 md:mb-0">
                  {t('productSettings.emailId.title')}
                </Typography>
                <Typography className="text-16 font-500 pl-20 mt-8 mb-16 md:mb-0">
                  {t('productSettings.emailId.note')}
                </Typography>
              </div>
              <div className="mt-32 w-full flex justify-center">
                <TextField
                  className="w-288 p-0 bg-grey-100"
                  value={emailInput}
                  placeholder={t('productSettings.emailId.placeHolder')}
                  onChange={(e) => {
                    const emailValue = e.target.value;
                    setEmailInput(emailValue);
                  }}
                />
              </div>
              <Button
                className="bg-darkgreen hover:bg-darkgreen disabled:text-black disabled:bg-gray px-48 mt-48 text-white rounded-4"
                onClick={sendSmsOrEmail}
                disabled={refreshIcon || !emailInput || !/\S+@\S+\.\S+/.test(emailInput)}
              >
                {t('sendMessage.sendEmail')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
              <div className="w-full mt-40 ">
                <Typography className="text-20 mb-10 font-600 flex justify-start">
                  {t('productSettings.phoneNumber.message')}
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: emailData }} />
              </div>
            </div>
          )}
        </div>
      </div>
      <SuccessMessagePopup
        openValue={openDeleteDialogue}
        cancelCall={closeBox}
        popUpMessage="Your Message Has Been Successfully Sent"
        image="/assets/images/icon/done.png"
      />
    </div>
  );
};

export default InviteReviewProductPage;
