import PrimaryDetails from './PrimaryDetails';
import Reviews from './Reviews';
import PendingTask from './PendingTask';
import Dashboards from './Dashboards';

const ReviewsConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/dashboard/:id?',
      element: <Dashboards />,
    },
    {
      path: 'reviews/:id?',
      element: <Reviews />,
    },
    {
      path: 'primary-details/:id?',
      element: <PrimaryDetails />,
    },
    {
      path: 'pending-task/:id?',
      element: <PendingTask />,
    },
  ],
};

export default ReviewsConfigs;
