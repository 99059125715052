import { Button } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import quickPostQuery from '../../query/quickPost';
import { handleApiRequest } from '../../common/common';

const QuickChatFeatures = ({
  setActiveStep,
  surpriseMeCardData,
  allFeaturesTrialDataId,
  chatTrialDone,
}) => {
  useEffect(() => {
    surpriseMeCardData();
  }, [surpriseMeCardData]);
  const openChatBot = async () => {
    const payload = {
      query: quickPostQuery.updateSurpriseMeCardData,
      variables: {
        quickHubSurpriseCardId: parseInt(allFeaturesTrialDataId, 10),
        planType: 'chat',
      },
    };
    await handleApiRequest(payload);
  };

  return (
    <div className="flex flex-col items-center bg-white p-20">
      <img
        className="w-400 h-586 m-auto border-1 border-grey-300 rounded-8"
        src="/assets/video/surpriseMeChat.gif"
        alt=""
      />
      <div className="flex flex-col sm:flex-row justify-center w-full gap-20 mt-10">
        <Button
          className="md:text-18  mt-10 px-36 sm:px-60 font-semibold disabled:text-black rounded-md"
          variant="outlined"
          color="secondary"
          type="button"
          onClick={() => setActiveStep(3)}
        >
          {t('manageBusiness.button.next')}
        </Button>
        <Button
          className="text-14 mt-10 px-14 font-medium disabled:text-black disabled:bg-gray-300 rounded-md"
          variant="contained"
          color="secondary"
          type="button"
          onClick={openChatBot}
          disabled={chatTrialDone}
        >
          {t('createPost.testYourChatbot')}
        </Button>
      </div>
    </div>
  );
};

export default QuickChatFeatures;
