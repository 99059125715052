import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import history from '@history';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useParams } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { logoutUser, selectUser } from 'app/store/userSlice';
import { t } from 'i18next';
import userService from '../../services';
import { getParamsId } from '../../main/common/common';
import LogOut from './LogOut';

function UserMenu(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const bc = new BroadcastChannel('logout_channel');
  const [userMenu, setUserMenu] = useState(null);
  const [loginUserData, setLoginUserData] = useState();
  const [openDialogueForLogout, setOpenDialogueForLogout] = useState(false);
  const link = history?.location?.pathname;

  const params = useParams();
  const redirect = (type) => {
    try {
      if (type === 'offerAndPricing') {
        if (params?.id) {
          history.push(`/offer-pricing/${params?.id}`);
        } else {
          history.push(`/offer-pricing`);
        }
      }
      if (type === 'yourPlan') {
        if (params?.id) {
          history.push(`/your-plan/${params?.id}`);
        } else {
          history.push(`/your-plan`);
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  bc.onmessage = (event) => {
    if (event.data === 'logout') {
      sessionStorage.clear();
    }
  };

  useEffect(() => {
    const id = getParamsId();
    if (id) {
      const userData = sessionStorage.getItem(`jwt_access_token_${id}`);
      if (userData) {
        setLoginUserData(jwtDecode(userData));
      }
    } else {
      const loginUser = userService.getUserData();
      setLoginUserData(loginUser);
    }
  }, []);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  function handleLogout() {
    dispatch(logoutUser());
    bc.postMessage('logout');
    sessionStorage.clear();
  }
  const handleOpenLogoutDialog = () => {
    setOpenDialogueForLogout(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenDialogueForLogout(false);
  };
  return (
    <>
      <style>
        {`
    .icon-hover-effect {
      filter: brightness(0) invert(0); /* Default: Black */
      transition: filter ease;
    }

    .icon-hover-effect:hover {
      filter: invert(50%) sepia(43%) saturate(6806%) hue-rotate(125deg) brightness(92%) contrast(91%);
    }
  `}
      </style>

      <a href="https://quickreviews.freshdesk.com/support/home" color="inherit">
        <img
          src="assets/images/icon/headphone.png"
          alt="icon"
          className="max-w-32 icon-hover-effect"
        />
      </a>

      <Button
        className="border-1 px-0 md:px-16 py-0 md:py-6 hover:bg-transparent"
        onClick={userMenuClick}
        color="inherit"
      >
        <img src="assets/images/icon/profileIcon.svg" alt="icon" className="icon-hover-effect" />
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
        sx={{
          mt: 1,
          px: 2,
          ml: -1,
        }}
      >
        <MenuItem
          className="menu-item  hover:bg-transparent text-black mx-10 border-b d-flex flex-col items-start"
          onClick={() => history.push('/your-plan')}
        >
          <ListItemText
            primary={`${user?.firstName} ${user?.lastName}`}
            primaryTypographyProps={{ className: 'text-lg font-semibold' }}
          />
          <ListItemText
            primary={
              user?.roles?.id === 1
                ? 'Admin'
                : user?.roles?.id === 3
                ? 'Sales Manager'
                : user?.roles?.id === 2
                ? 'Business User'
                : ''
            }
          />
        </MenuItem>
        <hr className="my-8 mb-10" />
        {loginUserData?.roles?.type !== 'sales_user' && (
          <MenuItem
            className="menu-item group rounded-8 hover:bg-darkgreen-100 mx-10 mb-10"
            component={Link}
            to={
              getParamsId()
                ? window.location.pathname.includes('agency')
                  ? `/agency-profile`
                  : `/user-profile/${getParamsId()}`
                : loginUserData?.roles?.type === 'agent'
                ? '/agency-profile'
                : '/user-profile'
            }
            onClick={userMenuClose}
            role="button"
          >
            <ListItemIcon className="group-hover:text-darkgreen">
              <img
                src="assets/images/icon/profileIcon.svg"
                className="w-20 icon-hover-effect group-hover:filter-none"
                alt="icon"
              />
            </ListItemIcon>
            <ListItemText
              primary="My Profile"
              primaryTypographyProps={{ style: { fontSize: '16px', fontWeight: '500' } }}
              className="text-black group-hover:text-darkgreen"
            />
          </MenuItem>
        )}
        {loginUserData?.roles?.type === 'business_user' && (
          <>
            <MenuItem
              className="menu-item rounded-8 hover:bg-darkgreen-100 hover:text-darkgreen mx-10 mb-10 group"
              onClick={() => {
                userMenuClose();
                history.push('mySubscription');
              }}
            >
              <div className="flex items-center group-hover:text-darkgreen">
                <ListItemIcon className="min-w-40">
                  <img
                    src="assets/images/icon/dollar.svg"
                    className="w-20 icon-hover-effect group-hover:filter-none"
                    alt="icon"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Subscription"
                  className="group-hover:text-darkgreen"
                  primaryTypographyProps={{ style: { fontSize: '16px', fontWeight: '500' } }}
                />
              </div>
            </MenuItem>

            <MenuItem
              className="menu-item rounded-8 hover:bg-darkgreen-100 hover:text-darkgreen mx-10 mb-10 group"
              onClick={() => {
                userMenuClose();
                history.push(redirect('offerAndPricing'));
              }}
            >
              <ListItemIcon className="min-w-40 group-hover:text-darkgreen">
                <img
                  src="assets/images/icon/offers&pricing.svg"
                  alt="icon"
                  className="w-20 icon-hover-effect group-hover:filter-none"
                />
              </ListItemIcon>
              <ListItemText
                primary="Offer & Pricing"
                primaryTypographyProps={{
                  style: { fontSize: '16px', fontWeight: '500' },
                }}
                className="group-hover:text-darkgreen"
              />
            </MenuItem>

            <MenuItem
              className="menu-item rounded-8 hover:bg-darkgreen-100 hover:text-darkgreen mx-10 mb-10 group"
              onClick={() =>
                history.push(getParamsId() ? 'manageBusiness/' : `/manageBusiness/${getParamsId()}`)
              }
            >
              <ListItemIcon className="min-w-40 group-hover:text-darkgreen">
                <img
                  src="assets/images/icon/managebusiness.svg"
                  alt="icon"
                  className="w-20 icon-hover-effect group-hover:filter-none"
                />
              </ListItemIcon>
              <ListItemText
                primary="Manage Business"
                primaryTypographyProps={{
                  style: { fontSize: '16px', fontWeight: '500' },
                }}
                className="group-hover:text-darkgreen"
              />
            </MenuItem>
          </>
        )}

        <MenuItem
          className="menu-item group rounded-8 hover:bg-darkgreen-100 mx-10 mb-10"
          onClick={handleOpenLogoutDialog}
        >
          <ListItemIcon className="min-w-40 group-hover:text-darkgreen">
            <img
              src="assets/images/icon/logout.svg"
              alt="icon"
              className="w-20 icon-hover-effect group-hover:filter-none"
            />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{ style: { fontSize: '16px', fontWeight: '500' } }}
            className="text-black group-hover:text-darkgreen"
          />
        </MenuItem>
      </Popover>
      <LogOut
        open={openDialogueForLogout}
        onClose={handleCloseLogoutDialog}
        onLogout={() => handleLogout()}
        confirmationText="Hope To See You Back Soon"
        additionalInfo={t('agencyBusiness.ConformationForLogout.text')}
        logoutImage="/assets/images/icon/logout.svg"
      />
    </>
  );
}

export default UserMenu;
