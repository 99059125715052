import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'src/app/store/userSlice';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import { t } from 'i18next';
import { Button } from '@mui/material';
import FuseLoading from '@fuse/core/FuseLoading';
import { handleApiRequest } from '../../common/common';

const PendingTask = () => {
  const [card, setCard] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [isLoading, setIsLoading] = useState(true);
  const totalTasks = card.length;

  const completedTasks = card && card.filter((task) => task.isAdded).length;
  const navigate = useNavigate();
  useEffect(() => {
    const getAppSetupCard = async () => {
      try {
        setIsLoading(true);
        const invoicePayload = {
          query: manageBusinessData.getAppSetupCard,
          variables: {
            type: 'quick_reviews',
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };

        const invoiceResult = await handleApiRequest(invoicePayload);
        if (invoiceResult?.getAppSetupCard?.status === 200) {
          setCard(invoiceResult?.getAppSetupCard?.data);
        } else {
          console.error('Error: Unexpected status code', invoiceResult?.getAppSetupCard?.status);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    getAppSetupCard();
  }, [userCurrentBusinessDetails?.id]);

  const handleClick = (index) => {
    if (card?.[index]?.title === 'Add Custom Attributes') {
      navigate('/customAttributes');
    }
    if (card?.[index]?.title === 'Scan The Qr Code & Give Reviews') {
      navigate('/qrCode');
    }
    if (card?.[index]?.title === 'Invite Reviews') {
      navigate('/invite-reviews');
    }
    if (card?.[index]?.title === 'Add Staff Member') {
      navigate('/staff');
    }
    if (card?.[index]?.title === 'Add Product & Services') {
      navigate('/businessProductAndService');
    }
    if (card?.[index]?.title === 'Check Metrics') {
      navigate('/matrices');
    }
    if (card?.[index]?.title === 'Tweak Autogenerated Complexity') {
      navigate('/autoGenerateText');
    }
    if (card?.[index]?.title === 'Add More Platforms') {
      navigate('/manageReviewSite');
    }
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="md:p-20 lg:p-32 p-16">
      <h1 className="text-24 md:text-28 font-bold mb-32">{t('pendingTask.pendingTask')}</h1>

      <div className="bg-white lg:p-32 md:p-32 sm:p-24 p-16 rounded-8 ">
        <h3 className="font-semibold text-18 text-left mb-24">
          {t('pendingTask.letSetUpYourBusiness')}
        </h3>
        <p className="font-medium text-16 mb-14">
          {completedTasks} / {card.length}
          {t('pendingTask.completed')}
        </p>
        <div className="flex space-x-2  mb-24 md:gap-20 sm:gap-10 gap-5">
          {Array.from({ length: totalTasks }, (_, index) => (
            <div
              key={index}
              className={`h-16 w-full rounded-4 text-white  ${
                index < completedTasks ? 'bg-darkgreen' : 'bg-darkgreen-100 '
              } border-1 ${index >= completedTasks ? 'border-darkgreen ' : 'border-transparent '}`}
            >
              .
            </div>
          ))}
        </div>
        <div>
          {card &&
            card?.map((task, index) => (
              <ul
                key={index}
                className=" list-disc list-inside  flex flex-col sm:flex-row justify-between w-full bg-grey-100 p-24 px-14 mb-14 rounded-8"
              >
                <li
                  className={` list-disc list-inside flex items-center  ${
                    task.isAdded ? 'line-through text-black' : ''
                  }`}
                >
                  <span>
                    {task?.isAdded ? (
                      <img
                        src="/assets/images/icon/check2.png"
                        alt="tick"
                        className="mr-14 ml-10 "
                      />
                    ) : (
                      <span className="list-disc list-inside  ">
                        <img
                          src="/assets/images/icon/dot.webp"
                          alt="dot"
                          className="h-36 w-40 justify-center items-center mr-8 "
                        />
                      </span>
                    )}
                  </span>
                  <p className="font-medium text-16 mb-10 "> {task.title}</p>
                </li>
                {!task.isAdded ? (
                  <Button
                    key={index}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className="text-16 text-darkgreen bg-darkgreen-100 font-medium rounded-md px-36 py-1 hover:text-white"
                    aria-label="skip"
                    type="button"
                    onClick={() => handleClick(index)}
                  >
                    {task.buttonAction}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    className="text-16 text-white hover:bg-darkgreen bg-darkgreen font-medium disabled:text-black rounded-md px-16 border-0"
                    aria-label="skip"
                    type="button"
                  >
                    {t('pendingTask.completed')}
                  </Button>
                )}
              </ul>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PendingTask;
