import Faq from './faq/Faq';
import GeoGrid from './geoGrid/GeoGrid';
import ScanHistory from './scanHIstory.js/ScanHistory';
import ScanHistoryDetails from './scanHIstory.js/ScanHistoryDetails';

const GeoGridConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/geoGrid/:id?',
      element: <GeoGrid />,
    },
    {
      path: '/scanHistory/:id?',
      element: <ScanHistory />,
    },
    {
      path: '/scanHistoryDetails/:id?',
      element: <ScanHistoryDetails />,
    },
    {
      path: '/faq/:id?',
      element: <Faq />,
    },
  ],
};

export default GeoGridConfigs;
