import { Button } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { capitalizeFirstLetter } from '../../../common/common';
import AddBusiness from './AddBusiness';
import AmazonProduct from './AmazonProduct';
import FlipkartProduct from './FlipkartProduct';

const AddOnlineBusiness = (props) => {
  const {
    onlinePlatFrom,
    setBusinessAddInProgress,
    businessAddInProgress,
    setIsBusinessSuccessFullyAdded,
  } = props;

  const [businessAdded, setBusinessAdded] = useState(false);
  const [businessId, setBusinessId] = useState('');
  const [typeOfProduct, setTypeOfProduct] = useState({
    isAmazon: false,
    isFlipkart: false,
    isWebsite: false,
  });

  const addBusinessLocation = (data) => {
    setBusinessAddInProgress({
      ...businessAddInProgress,
      isOnline: true,
    });
    setTypeOfProduct({
      isAmazon: data?.name === 'amazon',
      isFlipkart: data?.name === 'flipkart',
      isWebsite: data?.name === 'quickreviews',
    });
  };

  return (
    <div className="w-full">
      {!businessAddInProgress.isOnline &&
        onlinePlatFrom?.length > 0 &&
        onlinePlatFrom?.map((item, index) => {
          return (
            <div
              className="border-1 flex justify-between mb-28 items-center border-solid !border-darkgreen rounded-md mx-auto py-6 px-16"
              key={index}
            >
              <div className="flex items-center">
                <img
                  src={item?.logo}
                  alt={item?.name}
                  className="md:max-w-24 max-w-24 md:mr-20 mr-10 rounded-full"
                />
                <span className="block md:text-16 text-14 font-bold">
                  {capitalizeFirstLetter(item?.name)}
                </span>
              </div>
              <div className="flex items-center">
                <Button
                  className="inline-block align-middle rounded-3xl md:text-14 py-1 px-12 font-medium disabled:text-black min-h-24"
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => addBusinessLocation(item)}
                >
                  <span>{t('manageBusiness.button.addLocation')}</span>
                </Button>
              </div>
            </div>
          );
        })}
      {(typeOfProduct.isAmazon || typeOfProduct.isFlipkart || typeOfProduct.isWebsite) &&
        !businessAdded && (
          <AddBusiness
            setBusinessAdded={setBusinessAdded}
            setBusinessId={setBusinessId}
            typeOfProduct={typeOfProduct}
            setIsBusinessSuccessFullyAdded={setIsBusinessSuccessFullyAdded}
          />
        )}
      {businessAdded && typeOfProduct.isAmazon && (
        <AmazonProduct
          setIsBusinessSuccessFullyAdded={setIsBusinessSuccessFullyAdded}
          businessId={businessId}
        />
      )}
      {businessAdded && typeOfProduct.isFlipkart && (
        <FlipkartProduct
          setIsBusinessSuccessFullyAdded={setIsBusinessSuccessFullyAdded}
          businessId={businessId}
        />
      )}
    </div>
  );
};

export default AddOnlineBusiness;
