import { Button, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import userService from 'src/app/services';
import { t } from 'i18next';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import history from '@history';
import reviewPlatformData from '../../query/reviewPlatform';
import { handleApiRequest } from '../../common/common';
import description from './description.css';

const Description = () => {
  const options = [
    {
      id: 7,
      title: 'WELCOME TO QUICK REVIEWS!',
      heading: `<h1>Your One-Stop Solution For <br />
              <span style="color:#0BA861">Customer Reviews.</span></h1>`,
      description:
        'Collect valuable feedback, gain insights, and enhance your business with quick reviews, your customer feedback solution.',
      image: '/assets/images/business/description.png',
      videoTitle: '<h1><span style="color:#0BA861">Build</span> A Community</h1>',
      videoDescription:
        'Quick Reviews offers multiple tools to help you stay connected with your customers. Gain insights into what they value most about your business and identify opportunities for further improvement to enhance their experience and drive your growth.',
    },
    {
      id: 8,
      title: 'WELCOME TO QUICK SOCIAL!',
      heading: `<h1><span style="color:#0BA861">Create And Share </span>
              Your<br /> Reviews Effortlessly</h1>`,
      description:
        'Easily create and share customer reviews. Engage with your audience and enhance your business reputation.',
      image: '/assets/images/business/quicksocial.png',
      videoTitle:
        '<h1>Power Up Your <br /><span style="color:#0BA861">Social Presence!</span></h1>',
      videoDescription:
        'Watch how quick social makes it effortless to create and share customer reviews. Engage with your audience and elevate your brand’s reputation through real-time feedback.',
    },
    {
      id: 9,
      title: 'WELCOME TO QUICK CHAT!',
      heading: `<h1>Experience Seamless <br />
              <span style="color:#0BA861">Instant Bot Conversations.</span></h1>`,
      description:
        'Welcome to Quick Chat, where instant bot conversations streamline your experience. Get swift, automated assistance anytime, anywhere!',
      image: '/assets/images/business/quickchat.png',
      videoTitle:
        '<h1>Experience Faster, <br /><span style="color:#0BA861">Smarter Communication</span> <br /> Today!</h1>',
      videoDescription:
        'Streamline your customer interactions through automated, real-time conversations. Watch the video to discover how our intelligent bots provide swift support, available 24/7. Simplify your customer service and boost efficiency with quick chat!',
    },
    {
      id: 10,
      title: 'WELCOME TO QUICK CAMPAIGNS',
      heading: `<h1><span style="color:#0BA861">Connect</span>
               With Your<br /> Customers Seamlessly</h1>`,
      description:
        'Welcome to Quick Campaigns! effortlessly send promotions, festival wishes, offers, and discounts with just one click. enhance customer engagement and boost your business.',
      image: '/assets/images/business/QUICKCAMPAIGNS.png',
      videoTitle:
        '<h1>Effortless Marketing <br /><span style="color:#0BA861">with One Click</span></h1>',
      videoDescription:
        'Quick Reviews offers multiple tools to help you stay connected with your customers. Gain insights into what they value most about your business and identify opportunities for further improvement to enhance their experience and drive your growth.',
    },
  ];
  const params = useParams();
  const [packageData, setPackageData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [packageInterval, setPackageInterval] = useState('monthActive');
  const loginUserData = userService.getUserData();
  const findStatus = options.find((item) => item.id === parseInt(params?.packageTypeId, 10));
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = React.createRef();

  useEffect(() => {
    const fetchFilterData = async () => {
      const payload = {
        query: reviewPlatformData.getPackages,
        variables: {
          packageTypeId: parseInt(params?.packageTypeId, 10),
          packageInterval: `${packageInterval === 'monthActive' ? 'month' : 'year'}`,
          countryId: loginUserData?.countryId,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getPackages?.status === 200) {
        setAllData(result?.getPackages?.data[0]);
        setPackageData(JSON.parse(result?.getPackages?.data[0]?.featureInsideSection));
      }
    };
    fetchFilterData();
  }, [loginUserData?.countryId, packageInterval, params?.packageTypeId]);

  const myFunction = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVideoPlay = () => {
    setIsPlaying(true);
  };

  const handleVideoPause = () => {
    setIsPlaying(false);
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    videoRef.current.load();
  };

  return (
    <div className="p-20 lg:p-32 px-10 lg:px-112 font-quicksand">
      <div
        className={`bg-white text-center mt-20 w-full rounded-20 flex flex-col items-center shadow-3 bg-gradient-to-b from-white to-[#DEECEB] ${
          findStatus?.id === 9 || findStatus?.id === 10 ? 'p-0' : 'p-16'
        }`}
      >
        <div
          className={`flex items-center flex-col ${
            findStatus?.id === 9 || findStatus?.id === 10 ? 'w-full' : 'max-w-5xl'
          }`}
        >
          <div className="max-w-5xl p-20 md:p-64">
            <h1 className="text-20 sm:text-28 font-500">{findStatus?.title}</h1>
            <p
              className="mt-10 break-all mainHeading font-700 leading-tight"
              dangerouslySetInnerHTML={{ __html: findStatus?.heading }}
            />
            <p className="mt-20 text-20 sm:text-28 font-500">{findStatus?.description}</p>

            <div className="mt-20 flex flex-col sm:flex-row gap-20 justify-center">
              <Button className="bg-transparent rounded px-10 md:px-28 text-darkgreen disabled:bg-gray disabled:border-gray disabled:text-black border border-solid border-darkgreen hover:bg-transparent mx-10 md:mx-0 text-20">
                {t('selectProduct.startFreeTrial')}
              </Button>
              <Button
                className="bg-darkgreen rounded px-10 md:px-28 text-white disabled:bg-gray disabled:border-gray border border-solid border-darkgreen hover:bg-darkgreen mx-10 md:mx-0 text-20"
                type="button"
              >
                {t('userProfile.subscribeNow')}
              </Button>
            </div>
          </div>
          {(findStatus?.id === 7 || findStatus?.id === 8) && (
            <div className="flex justify-center">
              <img className="md:max-w-512 max-w-200" src={findStatus?.image} alt="" />
            </div>
          )}
        </div>
        <div>
          {(findStatus?.id === 9 || findStatus?.id === 10) && (
            <div className="flex">
              <img className="w-full" src={findStatus?.image} alt="" />
            </div>
          )}
        </div>
      </div>
      {packageData?.title && packageData?.heading && packageData?.description && (
        <div className="bg-white mt-40 px-16 md:px-64 w-full rounded-20 p-20 md:p-64 flex flex-col shadow-3">
          <div className="flex flex-col gap-10">
            <h3 className="font-500">{packageData?.title}</h3>
            <div
              className="font-600 cssInTitle"
              dangerouslySetInnerHTML={{ __html: packageData?.heading }}
            />
            <p className="text-20 max-w-4xl">{packageData?.description}</p>
          </div>
          <div className="grid sm:grid-cols-1 lg:grid-cols-2 mt-16 gap-24">
            {packageData?.featureDetails?.length &&
              packageData?.featureDetails.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col max-w-full md:max-w-2xl bg-grey-100 p-16 md:p-24 rounded-6"
                >
                  <div className="flex flex-row justify-between">
                    <div>
                      <img src={item?.icon} alt="" className="mr-14 max-w-40" />
                    </div>
                    <div className="flex flex-col justify-center">
                      <h2 className="font-600">{item?.title}</h2>
                      <p className="text-20 leading-8">{item?.details}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      <div className="bg-white mt-40 text-center w-full rounded-20 p-20 md:p-64 flex flex-col items-center shadow-3">
        <div className="w-full flex flex-col lg:flex-row ">
          {packageData?.videoLink && (
            <>
              <div className="relative w-full lg:w-1/2 flex justify-center items-center">
                <video
                  ref={videoRef}
                  className="block w-full rounded-12 max-h-360"
                  poster="/assets/images/business/cover-image.png"
                  onPlay={handleVideoPlay}
                  onPause={handleVideoPause}
                  onEnded={handleVideoEnded}
                >
                  <source src={packageData?.videoLink} type="video/mp4" />
                  <track kind="captions" label="English" default />
                </video>
                <Button
                  className={`absolute transition-opacity duration-300  hover:opacity-100 ${
                    isPlaying ? 'opacity-0 group-hover:opacity-100' : 'opacity-100'
                  }`}
                  onClick={myFunction}
                >
                  {isPlaying ? (
                    <PauseIcon className="bg-[#0BA861] bg-opacity-70 p-20 text-white rounded-full h-80 w-80" />
                  ) : (
                    <PlayArrowIcon className="bg-[#0BA861] bg-opacity-70 p-20 text-white rounded-full h-80 w-80" />
                  )}
                </Button>
              </div>
            </>
          )}
          <div className="w-full lg:w-1/2 flex flex-col justify-center gap-10 pt-10 lg:pt-0 pl-0 lg:pl-40 text-start">
            <h1
              className="font-600 cssInTitle"
              dangerouslySetInnerHTML={{ __html: findStatus?.videoTitle }}
            />
            <p className="text-16 md:text-24">{findStatus?.videoDescription}</p>
          </div>
        </div>
      </div>
      <div className="bg-white mt-40 text-center w-full rounded-20 p-20 md:p-64 flex flex-col items-center shadow-3">
        <p className="text-20 font-500">{packageData?.pricingDetails?.title}</p>
        <div>
          <h1
            className="font-600 mt-10 cssInTitle"
            dangerouslySetInnerHTML={{ __html: packageData?.pricingDetails?.heading }}
          />
        </div>
        <p className="text-20 max-w-480 font-500 mt-10">
          {packageData?.pricingDetails?.description}
        </p>
        <div className="text-center mt-36 mb-28">
          <span className="rounded-12 border border-darkgreen inline-block">
            <span
              className={`${
                packageInterval === 'monthActive'
                  ? 'bg-darkgreen text-white'
                  : 'bg-white text-darkgreen'
              } cursor-pointer text-16 font-medium py-8 inline-block rounded-12 min-w-136 text-center`}
              aria-hidden="true"
              onClick={() => {
                setPackageInterval('monthActive');
              }}
            >
              {t('pricingCatalogue.addNew.monthly')}
            </span>
            <span
              className={`${
                packageInterval === 'yearActive'
                  ? 'bg-darkgreen text-white'
                  : 'bg-white text-darkgreen'
              } cursor-pointer text-16 font-medium py-8 inline-block rounded-12 min-w-136 text-center`}
              aria-hidden="true"
              onClick={() => {
                setPackageInterval('yearActive');
              }}
            >
              {t('pricingCatalogue.addNew.yearly')}
            </span>
          </span>
        </div>

        <div className="w-full flex md:gap-0 gap-20 md:flex-row flex-col justify-evenly">
          <div className="flex flex-row justify-center">
            <div className="md:max-w-4xl max-w-full border rounded-8 flex items-center justify-center p-10 border-darkgreen">
              <img className="shadow-2" src="/assets/images/business/pricing.png" alt="" />
            </div>
          </div>
          <div className="">
            <div className="p-20 border rounded-8 border-darkgreen">
              <img className="max-w-64" src={allData?.image} alt="" />
              <p className="mt-14 text-20 text-start font-600">{allData?.name}</p>
              <p className="mt-14 text-start text-14 text-grey-700 font-500 max-w-256">
                {packageData?.pricingDetails?.description}
              </p>
              <p className="mt-14 text-start text-28 font-600">
                {loginUserData?.countryId === 2 ? '₹' : '$'} {allData?.offerAmount}/
                {allData?.packageInterval}
              </p>
              <Button
                className="bg-darkgreen mt-14 flex justify-center rounded px-10 md:px-28 leading-5 w-full text-white disabled:bg-gray disabled:border-gray border border-solid border-darkgreen hover:bg-darkgreen mx-0 text-16"
                type="button"
                onClick={() =>
                  history.push('selectProduct/', {
                    packageBundle: [allData?.packageTypeId],
                    intervalType: allData?.packageInterval,
                  })
                }
              >
                {t('userProfile.subscribeNow')}
              </Button>
              <h3 className="my-14 font-600 text-16 text-start">{packageData?.title}</h3>
              {packageData?.pricingDetails?.included?.length &&
                packageData?.pricingDetails?.included.map((item, index) => (
                  <div className="flex items-center pb-10">
                    {' '}
                    <img
                      src="/assets/images/business/check_circle.svg"
                      className="img-fluid max-w-20 mr-20"
                      alt="..."
                    />{' '}
                    <p className="text-14 font-500 text-start">{item}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
