import { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Typography, Icon, Toolbar } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation, useParams } from 'react-router-dom';
import history from '@history';
import ReviewMode from '../../manage-business/ReviewMode';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

const ReviewScreenMode = () => {
  const params = useParams();
  const [modeId, setModeId] = useState(0);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const location = useLocation();
  const { plateFormID } = location.state || {};
  const productId = parseInt(params?.productId, 10);
  const agentId = parseInt(params?.id, 10);
  const fetchBusinessData = useCallback(async () => {
    try {
      if (
        userCurrentBusinessDetails?.id &&
        userCurrentBusinessDetails?.isOnlineBusiness &&
        productId
      ) {
        setLoading(true);

        const payload = {
          query:
            parseInt(plateFormID, 10) === 5
              ? manageBusinessData.getAmazonProduct
              : parseInt(plateFormID, 10) === 6 && manageBusinessData.getFlipkartProduct,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        if (plateFormID === 5) {
          payload.variables.amazonProductId = productId;
        } else if (plateFormID === 6) {
          payload.variables.flipkartProductId = productId;
        }
        const result = await handleApiRequest(payload);
        const res =
          parseInt(plateFormID, 10) === 5
            ? result?.getAmazonProduct
            : parseInt(plateFormID, 10) === 6 && result?.getFlipkartProduct;
        setLoading(false);
        if (res?.status === 200) {
          setModeId(parseInt(res?.data[0]?.reviewModeTypeId, 10));
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else if (userCurrentBusinessDetails?.id) {
        const payload = {
          query: manageBusinessData.getUserBusinessDetail,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getUserBusinessDetail?.status === 200) {
          setModeId(parseInt(result?.getUserBusinessDetail?.data?.[0]?.reviewModeTypeId, 10));
        } else {
          enqueueSnackbar(result?.getUserBusinessDetail?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.isOnlineBusiness,
    productId,
    plateFormID,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  const saveAndNext = async () => {
    setRefreshIcon(true);
    try {
      if (productId) {
        const queryKey =
          parseInt(plateFormID, 10) === 5
            ? 'updateAmazonProductScreenMode'
            : 'updateFlipkartProductScreenMode';

        const payload = {
          query:
            parseInt(plateFormID, 10) === 5
              ? manageBusinessData.updateAmazonProductScreenMode
              : manageBusinessData.updateFlipkartProductScreenMode,
          variables: {
            [`${parseInt(plateFormID, 10) === 5 ? 'amazonProductId' : 'flipkartProductId'}`]:
              productId,
            reviewModeTypeId: parseInt(modeId, 10),
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };

        const result = await handleApiRequest(payload);
        const res = result?.[queryKey];
        setRefreshIcon(false);
        setLoading(false);

        enqueueSnackbar(res?.message, {
          variant: res?.status === 200 ? 'success' : 'error',
          autoHideDuration: 3000,
        });
      } else {
        const payload = {
          query: manageBusinessData.updateUserBusinessesPlatformModeTypeV2,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            reviewModeTypeId: parseInt(modeId, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setRefreshIcon(false);

        enqueueSnackbar(result?.updateUserBusinessesPlatfromModeType?.message, {
          variant:
            result?.updateUserBusinessesPlatfromModeType?.status === 200 ? 'success' : 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div>
      {params?.productId && (
        <Toolbar className="pl-36 pt-32 font-semibold min-h-fit">
          <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
            west
          </Icon>
          <Typography
            variant="subtitle1"
            color="inherit"
            className="flex-1 px-12 font-bold text-18"
          >
            {t('products.productsettings')}
          </Typography>
        </Toolbar>
      )}
      <div className={`${!params?.productId ? 'p-20 h-max lg:p-40' : 'h-max p-40'}`}>
        <>
          <div className="flex flex-1 w-full items-center justify-between mb-36">
            <div className="flex items-center">
              <Typography
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
              >
                {t('reviewScreenMode.title')}
              </Typography>
            </div>
            <div className="md:flex gap-24 text-center">
              <Button
                className="bg-darkgreen rounded md:px-60 disabled:bg-gray disabled:text-black text-white hover:bg-darkgreen mx-10 md:mx-0"
                disabled={refreshIcon || !modeId || !userCurrentBusinessDetails?.id}
                onClick={() => saveAndNext()}
              >
                {t('reviewScreenMode.button.save')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            </div>
          </div>
          <div className="bg-white p-36">
            <ReviewMode
              setModeId={setModeId}
              modeId={modeId}
              productId={productId}
              agentId={agentId}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default ReviewScreenMode;
