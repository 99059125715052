import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import ReactSelect from 'react-select';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Select from '@mui/material/Select';
import { FixedSizeList as List } from 'react-window';
import MenuItem from '@mui/material/MenuItem';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import * as React from 'react';
import Switch from '@mui/material/Switch';
import history from '@history';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import businessData from '../../query/business';
import { getBusinessList, resetBusiness, deleteBusiness } from './store/businessSlice';
import reducer from './store';
import queryData from '../../query/common';
import FilterDialog from './filter';
import { getBusinessTypeData, getCountryData } from '../../../store/userSlice';
import { sortOptions } from '../../common/common';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function BusinessList() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();

  const businessListData = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getBusinessListData?.data?.getBusinessList?.data || ''
  );

  const totalBusinessType = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getBusinessListData?.data?.getBusinessList?.totalCount || ''
  );

  const deleteBusinessStatus = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.deleteBusinessData?.data?.deleteBusiness || ''
  );
  const { control } = useForm({});

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight } = props;
    if (options.length > 0) {
      return (
        <div>
          {children?.length > 0 ? (
            <List height={maxHeight} itemCount={children.length} itemSize={itemHeight}>
              {({ index, style }) => (
                <div style={style} className="bg-white relative">
                  {children[index]}
                </div>
              )}
            </List>
          ) : (
            <div className="text-center py-10 my-1">
              {t('manageBusiness.addBusinessSteps.addBusiness.form.businessType.businessNotFound')}
            </div>
          )}
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueByBusinessType, setSearchValueByBusinessType] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowAction, setSelectedRowAction] = useState('action');
  const [selectedBusinessId, setSelectedBusinessId] = useState();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('cts');
  const [orderNow, setOrderNow] = useState('desc');
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState(businessListData);
  const [country, setCountry] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('Active');
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [sortByValue, setSortByValue] = useState('Newest');
  const [filterLength, setFilterLength] = useState([]);
  const countriesData = useSelector(getCountryData);
  const [inputValue, setInputValue] = useState('');
  const [businessType, setBusinessType] = useState([]);
  const businessTypeList = useSelector(getBusinessTypeData);
  const [businessTypeId, setBusinessTypeId] = useState();

  useEffect(() => {
    setCountry(countriesData);
  }, [countriesData]);

  useEffect(() => {
    if (businessTypeList?.length > 0) {
      const businessTypeData = businessTypeList?.map((idx) => ({
        value: idx.id,
        label: idx.bType,
      }));
      setBusinessType(businessTypeData);
    }
  }, [businessTypeList]);

  const filteredOptions = useMemo(() => {
    return sortOptions(inputValue, businessType);
  }, [inputValue, businessType]);

  const getStatusClick = (value) => {
    const filterLen = selectedCountries.length + selectedCategory.length;
    setFilterLength(filterLen);

    const countryData = {
      countryId: selectedCountries?.includes('India') ? 2 : 1,
    };
    const categoryValue = {
      category: selectedCategory.includes('Online') ? 'online' : 'physical',
    };

    const sortValue = {
      sortOrder: sortByValue === 'Newest' ? 'desc' : 'asc',
    };

    // setLoadingPage(true);
    setLoadingPage(true);
    const findBusinessType = businessType?.find(
      (item) => parseInt(item?.value, 10) === parseInt(businessTypeId, 10)
    );
    const payload = {
      query: businessData.getBusinessList,
      variables: {
        pageSize: 10,
        pageNumber: value + 1,
        searchText: '',
        sortBy: 'cts',
        ...sortValue,
        status: selectedStatus === 'Active',
        ...(selectedCountries.length < 2 && countryData),
        ...(selectedCategory.length < 2 && categoryValue),
        businessType: findBusinessType?.label,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessList(payload));
      setLoadingPage(false);
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = businessListData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
      setSelectedRow(row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    } else {
      setSelectedRow(null);
    }

    setSelected(newSelected);
  };

  const handleInputChange = (value) => {
    if (value) {
      setBusinessTypeId(value);
    }
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectBusinessType = (selectedOption) => {
    if (selectedOption) {
      setBusinessTypeId(selectedOption?.value);
    }
  };

  const getBusinessListData = useCallback(() => {
    let unmounted = false;
    setLoadingPage(true);
    const findBusinessType = businessType?.find(
      (item) => parseInt(item?.value, 10) === parseInt(businessTypeId, 10)
    );
    const payload = {
      query: businessData.getBusinessList,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
        sortBy: 'cts',
        sortOrder: 'desc',
        status: true,
        businessType: findBusinessType?.label,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessList(payload));
      setLoadingPage(false);
    });
    return () => {
      unmounted = true;
      dispatch(resetBusiness());
    };
  }, [dispatch, businessTypeId, businessType]);

  useEffect(() => {
    getBusinessListData();
  }, [getBusinessListData]);

  const handleFilterClose = () => {
    setFilterOpen(false);
    setOpen(false);
    setFilterLength([]);
    setSelectedCountries([]);
    setSelectedCategory([]);
    getBusinessListData();
  };

  useEffect(() => {
    if (businessListData || businessListData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [businessListData]);

  useEffect(() => {
    if (businessListData && businessListData?.length) {
      setData(businessListData);
    } else {
      setData();
    }
  }, [businessListData]);

  useEffect(() => {
    if (deleteBusinessStatus && deleteBusinessStatus.status === 400) {
      enqueueSnackbar(deleteBusinessStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (deleteBusinessStatus && deleteBusinessStatus.status === 200) {
      enqueueSnackbar(deleteBusinessStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, deleteBusinessStatus]);

  function commonGetData(searchValues = '') {
    const findBusinessType = businessType?.find(
      (item) => parseInt(item?.value, 10) === parseInt(businessTypeId, 10)
    );
    const payload = {
      query: businessData.getBusinessList,
      variables: {
        //  searchText: searchValues,
        businessName: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
        status: true,
        businessType: findBusinessType?.label,
      },
    };
    dispatch(getBusinessList(payload));
  }
  function commonGetDataByBusinessType(searchValues = '') {
    const findBusinessType = businessType?.find(
      (item) => parseInt(item?.value, 10) === parseInt(businessTypeId, 10)
    );
    const payload = {
      query: businessData.getBusinessList,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
        status: true,
        businessType: findBusinessType?.label,
      },
    };
    dispatch(getBusinessList(payload));
  }

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    setSearchValue(searchText);
    commonGetData(searchText);
  }

  function handleSearchByBusinessType(selectedOption) {
    if (selectedOption) {
      const searchBusinessByName = selectedOption.value;
      setSearchValueByBusinessType(searchBusinessByName);
      commonGetDataByBusinessType(searchBusinessByName);
    }
  }

  const clearSearchForBusinessTye = async () => {
    await setPage(1);
    await commonGetData(searchValue);
    if (!searchValue) {
      await commonGetDataByBusinessType();
    }
    await setSearchValueByBusinessType('');
  };

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetDataByBusinessType(searchValueByBusinessType);
    if (!searchValueByBusinessType) {
      await commonGetData();
    }
  };

  function handleActionDropdownChange(event) {
    setSelectedRowAction(event.target.value);
  }

  function redirectUserRoutes(id) {
    return history.push({
      pathname: `/user-details/${id}`,
    });
  }

  const handleDelete = async (businessId) => {
    try {
      setRefreshIcon(true);
      const findBusinessType = businessType?.find(
        (item) => parseInt(item?.value, 10) === parseInt(businessTypeId, 10)
      );
      const payload = {
        query: businessData.deleteBusiness,
        variables: {
          businessId,
        },
      };
      await dispatch(deleteBusiness(payload));
      const payloadData = {
        query: businessData.getBusinessList,
        variables: {
          searchText: searchValue,
          pageNumber: page,
          pageSize: rowsPerPage,
          sortBy: orderBy,
          sortOrder: orderNow,
          status: true,
          businessType: findBusinessType?.label,
        },
      };
      await dispatch(getBusinessList(payloadData));
      setRefreshIcon(false);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const findBusinessType = businessType?.find(
      (item) => parseInt(item?.value, 10) === parseInt(businessTypeId, 10)
    );
    if (filterLength?.length === 0) {
      const payload = {
        query: businessData.getBusinessList,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: value + 1,
          searchText: searchValue,
          sortBy: orderBy,
          sortOrder: orderNow,
          status: true,
          businessType: findBusinessType?.label,
        },
      };
      dispatch(async (dispatchValue) => {
        await dispatchValue(getBusinessList(payload));
        setLoadingPage(false);
      });
    } else {
      getStatusClick(value);
    }
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const findBusinessType = businessType?.find(
      (item) => parseInt(item?.value, 10) === parseInt(businessTypeId, 10)
    );
    const payload = {
      query: businessData.getBusinessList,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
        status: true,
        businessType: findBusinessType?.label,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessList(payload));
      setLoadingPage(false);
    });
  }
  const filterListClick = () => {
    setOpen(!open);
    setFilterOpen(!filterOpen);
  };

  const handleSorting = (event) => {
    const sortColumnName = event;
    setOrderBy(sortColumnName);
    if (order === 'asc') {
      const sorted = [...data].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col > sortTypeB.col ? 1 : 1
      );
      setData(sorted);
      setOrderNow('asc');
      setOrder('desc');
    }
    if (order === 'desc') {
      const sorted = [...data].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col < sortTypeB.col ? 1 : 1
      );
      setData(sorted);
      setOrderNow('desc');
      setOrder('asc');
    }
    const findBusinessType = businessType?.find(
      (item) => parseInt(item?.value, 10) === parseInt(businessTypeId, 10)
    );
    const payload = {
      query: businessData.getBusinessList,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: sortColumnName,
        sortOrder: order,
        status: true,
        businessType: findBusinessType?.label,
      },
    };
    dispatch(getBusinessList(payload));
  };

  const handleCountryFilter = (event) => {
    setLoadingPage(true);
    setRowsPerPage(10);
    setPage(1);
    const findBusinessType = businessType?.find(
      (item) => parseInt(item?.value, 10) === parseInt(businessTypeId, 10)
    );
    const countryData = {
      countryId: event.target.value,
    };
    const payload = {
      query: businessData.getBusinessList,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
        ...(event?.target?.value !== 'all' && countryData),
        status: true,
        businessType: findBusinessType?.label,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessList(payload));
      setLoadingPage(false);
    });
  };

  const filterListCloseClick = () => {
    setFilterOpen(!filterOpen);
    setOpen(!open);
  };

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('business.businessList.column.srNo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('name');
              }}
            >
              {t('business.businessList.column.businessName')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('email');
              }}
            >
              {t('business.businessList.column.businessEmailID')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('cts');
              }}
            >
              {t('business.businessList.column.dateOfRegistration')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.businessList.column.businessType')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.businessList.column.country')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('business.businessList.column.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center w-1.5/12">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('business.businessList.title')}
          </Typography>
        </div>
        <div className="flex items-center justify-between w-8/12">
          <div className="flex w-full items-center justify-center w-full px-12">
            <Paper
              component={motion.div}
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
              className="flex items-center w-full px-8 rounded-8 shadow-none"
            >
              <Input
                placeholder={t('business.businessList.placeHolder.search')}
                className="flex flex-1 mx-8 text-black text-16"
                disableUnderline
                name="searchText"
                fullWidth
                inputProps={{
                  'aria-label': 'Search',
                }}
                onChange={handleSearchEvent}
                value={searchValue}
              />
              {searchValue ? (
                <Icon
                  color="action"
                  className="cursor-pointer"
                  onClick={() => {
                    clearSearch();
                  }}
                >
                  close
                </Icon>
              ) : (
                <Icon color="action">search</Icon>
              )}
            </Paper>
          </div>
          <div className=" w-full relative">
            <ReactSelect
              labelId="businessType"
              placeholder={t('business.selectBusinessType')}
              id="businessType"
              name="businessType"
              inputValue={inputValue}
              onChange={(e) => {
                handleSelectBusinessType(e);
              }}
              options={businessType}
              onInputChange={handleInputChange}
              value={businessType.find((op) => {
                return op.value === businessTypeId;
              })}
              components={{
                MenuList: CustomMenuList,
              }}
            />
          </div>
        </div>

        <div>
          {filterLength >= 1 ? (
            <Button
              endIcon={<FilterListIcon onClick={() => filterListCloseClick()} />}
              variant="contained"
              color="secondary"
              className="text-16 w-full min-w-192 hover:bg-darkgreen justify-between px-20"
            >
              {t('business.businessList.filter.mainBtn')}
              <Button className="bg-white text-darkgreen hover:bg-white justify-between px-10 min-h-auto h-auto t-0 py-3.5">
                {filterLength}
                <CloseIcon onClick={handleFilterClose} />
              </Button>
            </Button>
          ) : (
            <Button
              className="text-16 w-full min-w-192 justify-between px-20"
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => filterListClick()}
            >
              {t('business.businessList.filter.mainBtn')}{' '}
              <Icon onClick={() => filterListClick()} className="text-20 text-black ">
                filter_list
              </Icon>
            </Button>
          )}
          {open && (
            <FilterDialog
              setSelectedStatus={setSelectedStatus}
              selectedStatus={selectedStatus}
              setFilterOpen={setFilterOpen}
              filterOpen={filterOpen}
              setOpen={setOpen}
              open={open}
              getStatusClick={getStatusClick}
              setSelectedCountries={setSelectedCountries}
              selectedCountries={selectedCountries}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              sortByValue={sortByValue}
              setSortByValue={setSortByValue}
              filterLength={filterLength}
              setFilterLength={setFilterLength}
              getBusinessListData={getBusinessListData}
            />
          )}
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {businessListData?.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={businessListData?.length}
                />
                <TableBody>
                  {data &&
                    data?.length > 0 &&
                    data?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row?.id}
                          selected={isItemSelected}
                        >
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black font-semibold max-w-188 break-all"
                            align="left"
                          >
                            {row?.name}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.email}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.business_types?.bType}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.countries?.name}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Select
                              className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                              sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                              variant="filled"
                              value={selectedRow === row ? selectedRowAction : 'action'}
                              fullWidth
                              onChange={handleActionDropdownChange}
                            >
                              <MenuItem
                                value="action"
                                className="capitalize mx-5 rounded-md"
                                disabled
                              >
                                {t('business.businessList.column.action')}{' '}
                                {refreshIcon && row.id === selectedBusinessId && (
                                  <CircularProgress
                                    size={20}
                                    className="text-darkgreen absolute mx-auto right-5"
                                  />
                                )}
                              </MenuItem>
                              <MenuItem
                                value="view"
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                onClick={() => redirectUserRoutes(row.userId)}
                              >
                                {t('business.businessList.column.viewDetails')}
                              </MenuItem>
                              <MenuItem
                                value="delete"
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                onClick={() => {
                                  handleDelete(row.id);
                                  setSelectedBusinessId(row.id);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('business.businessList.noBusinessFound')}
            </Typography>
          </motion.div>
        )}
      </div>
      {businessListData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalBusinessType}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

export default withReducer('businessReducer', reducer)(BusinessList);
