import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControl,
  Icon,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useLocation, useParams } from 'react-router-dom';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import { capitalizeFirstLetter, handleApiRequest } from '../../../common/common';
import manageBusinessData from '../../../query/manageBusiness';
import { selectDashboardData } from '../../../../store/userSlice';
import UserProfile from '../../../common/userProfile';

const ServiceRecovery = () => {
  const params = useParams();
  const options = [
    { id: 1, label: 'request_for_Call', value: 'Request for call', buttonName: 'Request' },
    { id: 2, label: 'called', value: 'Called', buttonName: 'Called' },
    { id: 3, label: 'solved', value: 'Solved', buttonName: 'Solved' },
  ];
  const [tabValue, setTabValue] = useState(0);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [negativeReviewData, setNegativeReviewData] = useState([]);
  const [negativeReview, setNegativeReview] = useState([]);
  const [preventionData, setPreventionData] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [customerReviewData, setCustomerReviewData] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [negativeReviewId, setNegativeReviewId] = useState();
  const [star, setStar] = useState();
  const [face, setFace] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const [changeStatus, setChangeStatus] = useState('all');
  const location = useLocation();
  const { plateFormID, paramId } = location.state || {};
  const productId = parseInt(params?.productId, 10);
  const [enableDisable, setEnableDisable] = useState({
    recoveryStatus: false,
    preventionStatus: true,
  });
  const { enqueueSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const fetchBusinessData = useCallback(async () => {
    let payload;
    let apiResult;
    try {
      if (plateFormID) {
        const variables = { userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10) };

        if (productId) {
          if (plateFormID === 5) {
            variables.amazonProductId = productId;
          } else if (plateFormID === 6) {
            variables.flipkartProductId = productId;
          }
        }
        payload = {
          query:
            parseInt(plateFormID, 10) === 5
              ? manageBusinessData.getAmazonProduct
              : parseInt(plateFormID, 10) === 6 && manageBusinessData.getFlipkartProduct,
          variables,
        };

        apiResult = await handleApiRequest(payload);
        const res =
          parseInt(plateFormID, 10) === 5
            ? apiResult?.getAmazonProduct
            : apiResult?.getFlipkartProduct;

        setPageLoading(false);

        if (res?.status === 200) {
          const filteredProducts = res?.data?.filter((item) => item?.id);
          if (filteredProducts.length > 0) {
            setEnableDisable({
              recoveryStatus: filteredProducts[0]?.isNegativeReviewRedirect,
              preventionStatus: true,
            });
          }
        }
      } else {
        payload = {
          query: manageBusinessData.getUserBusinessDetail,
        };
        const result = await handleApiRequest(payload);
        if (result?.getUserBusinessDetail?.status === 200) {
          const data = result.getUserBusinessDetail?.data;
          const findData = data?.find(
            (item) => parseInt(item?.id, 10) === parseInt(userCurrentBusinessDetails?.id, 10)
          );
          setEnableDisable({
            recoveryStatus: findData?.isNegativeReviewRedirect,
            preventionStatus: findData?.isNegativeReviewsPrevention,
          });
        } else {
          enqueueSnackbar(result?.getUserBusinessDetail?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [plateFormID, userCurrentBusinessDetails?.id, productId, enqueueSnackbar]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getNegativeReviewsBaseOnPrevention,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getNegativeReviewsBaseOnPrevention?.status === 200) {
        setPreventionData(result?.getNegativeReviewsBaseOnPrevention?.data);
      } else {
        enqueueSnackbar(result?.getNegativeReviewsBaseOnPrevention?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchData();
    }
  }, [
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.isOnlineBusiness,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    const fetchPreventData = async () => {
      try {
        setPageLoading(true);
        const variables = {};

        if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
          if (plateFormID === 5) {
            variables.amazonProductId = productId;
          } else if (plateFormID === 6) {
            variables.flipkartProductId = productId;
          }
        } else {
          variables.userBusinessId = parseInt(userCurrentBusinessDetails?.id, 10);
        }
        const payload = {
          query: manageBusinessData.getNegativeReviewRedirectStatus,
          variables,
        };

        const result = await handleApiRequest(payload);
        setPageLoading(false);

        if (result?.getNegativeReviewRedirectStatus?.status === 200) {
          const data = result?.getNegativeReviewRedirectStatus?.data;
          setNegativeReviewData(data);
          setNegativeReview(data);
        } else {
          enqueueSnackbar(result?.getNegativeReviewRedirectStatus?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setPageLoading(false);
        console.error('An error occurred while fetching preventive data', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchPreventData();
    }
  }, [
    userCurrentBusinessDetails?.id,
    enqueueSnackbar,
    productId,
    plateFormID,
    userCurrentBusinessDetails?.isFlipkartProducts,
    userCurrentBusinessDetails?.isOnlineBusiness,
    userCurrentBusinessDetails?.isAmazonProducts,
  ]);

  const viewCustomerData = async (id) => {
    setNegativeReviewId(id);
    setOpenDialogue(true);

    const variables = {
      negativeReviewId: parseInt(id, 10),
    };
    if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
      switch (plateFormID) {
        case 5:
          variables.amazonProductId = productId;
          break;
        case 6:
          variables.flipkartProductId = productId;
          break;
        default:
          break;
      }
    } else {
      variables.userBusinessId = parseInt(userCurrentBusinessDetails?.id, 10);
    }
    const payload = {
      query: manageBusinessData.getNegativeReview,
      variables,
    };
    const result = await handleApiRequest(payload);
    if (result?.getNegativeReview?.status === 200) {
      const data = result?.getNegativeReview?.data[0];
      setCustomerReviewData(data);
      const findStatus = options.find((item) => item.label === data.status);
      setSelectedStatus(findStatus?.id);
    } else {
      enqueueSnackbar(result?.getNegativeReview?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const updateNegativeReview = async () => {
    const findStatus = options.find(
      (item) => parseInt(item.id, 10) === parseInt(selectedStatus, 10)
    );
    setRefreshIcon(true);
    const payload = {
      query: manageBusinessData.negativeReviewUpdate,
      variables: {
        negativeReviewId: parseInt(negativeReviewId, 10),
        status: findStatus?.label,
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.negativeReviewUpdate?.status === 200) {
      handleCancel();
      const variables = {};

      if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
        if (plateFormID === 5) {
          variables.amazonProductId = productId;
        } else if (plateFormID === 6) {
          variables.flipkartProductId = productId;
        }
      } else {
        variables.userBusinessId = parseInt(userCurrentBusinessDetails?.id, 10);
      }
      const obj = {
        query: manageBusinessData.getNegativeReviewRedirectStatus,
        variables,
      };
      const res = await handleApiRequest(obj);
      if (res?.getNegativeReviewRedirectStatus?.status === 200) {
        const resData = res?.getNegativeReviewRedirectStatus?.data;
        setNegativeReviewData(resData);
        setNegativeReview(resData);
        if (changeStatus === 'all') {
          setNegativeReviewData(resData);
        } else {
          const findStatusLabel = options.find(
            (item) => parseInt(item.id, 10) === parseInt(changeStatus, 10)
          );
          const filterData = resData?.filter((item) => item.status === findStatusLabel?.label);
          setNegativeReviewData(filterData);
        }
      } else {
        enqueueSnackbar(res?.getNegativeReviewRedirectStatus?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      enqueueSnackbar(result?.negativeReviewUpdate?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleCancel = () => {
    setSelectedStatus();
    setNegativeReviewId();
    setOpenDialogue(false);
    setCustomerReviewData();
  };

  const handleChangeStatus = async (e) => {
    const statusValue = e.target.value;
    setChangeStatus(statusValue);
    if (statusValue === 'all') {
      setNegativeReviewData(negativeReview);
    } else {
      const findStatus = options.find(
        (item) => parseInt(item.id, 10) === parseInt(statusValue, 10)
      );
      const filterData = negativeReview?.filter((item) => item.status === findStatus?.label);
      setNegativeReviewData(filterData);
    }
  };

  const enableDisableService = async () => {
    setEnableDisable({ ...enableDisable, recoveryStatus: !enableDisable.recoveryStatus });
    try {
      if (productId) {
        const payload = {
          query: manageBusinessData.setAmazonAndFlipkartNegativeReviewRedirect,
          variables: {
            isActive: !enableDisable.recoveryStatus,
            ...(enableDisable.recoveryStatus === true && {
              negativeReviewsPrevention: false,
            }),
          },
        };
        if (plateFormID === 5) {
          payload.variables.amazonProductId = productId;
        } else if (plateFormID === 6) {
          payload.variables.flipkartProductId = productId;
        }
        const result = await handleApiRequest(payload);
        if (result?.setAmazonAndFlipkartNegativeReviewRedirect?.status === 200) {
          await fetchBusinessData();
        } else {
          enqueueSnackbar(result?.setAmazonAndFlipkartNegativeReviewRedirect?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else {
        const payload = {
          query: manageBusinessData.setNegativeReviewRedirect,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            isActive: !enableDisable.recoveryStatus,
            ...(enableDisable.recoveryStatus === true && {
              negativeReviewsPrevention: false,
            }),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.setNegativeReviewRedirect?.status === 200) {
          await fetchBusinessData();
        } else {
          enqueueSnackbar(result?.setNegativeReviewRedirect?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const enableDisablePrevention = async () => {
    if (enableDisable.recoveryStatus) {
      setEnableDisable({
        ...enableDisable,
        preventionStatus: !enableDisable.preventionStatus,
      });
      try {
        if (productId) {
          const payload = {
            query: manageBusinessData.setAmazonAndFlipkartNegativeReviewRedirect,
            variables: {
              isActive: enableDisable.recoveryStatus,
              negativeReviewsPrevention: !enableDisable.preventionStatus,
            },
          };
          if (plateFormID === 5) {
            payload.variables.amazonProductId = productId;
          } else if (plateFormID === 6) {
            payload.variables.flipkartProductId = productId;
          }
          const result = await handleApiRequest(payload);
          if (result?.setAmazonAndFlipkartNegativeReviewRedirect?.status === 200) {
            fetchBusinessData();
          } else {
            enqueueSnackbar(result?.setAmazonAndFlipkartNegativeReviewRedirect?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } else {
          const payload = {
            query: manageBusinessData.setNegativeReviewRedirect,
            variables: {
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
              isActive: enableDisable.recoveryStatus,
              negativeReviewsPrevention: !enableDisable.preventionStatus,
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.setNegativeReviewRedirect?.status === 200) {
            fetchBusinessData();
          } else {
            enqueueSnackbar(result?.setNegativeReviewRedirect?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        }
      } catch (error) {
        console.error('error', error);
      }
    }
  };

  const fetchAttributeLevelThreshold = useCallback(async () => {
    try {
      setPageLoading(true);
      const variables = {};
      if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
        if (plateFormID === 5) {
          variables.amazonProductId = parseInt(productId, 10);
        } else if (plateFormID === 6) {
          variables.flipkartProductId = parseInt(productId, 10);
        }
      } else {
        variables.userBusinessId = parseInt(userCurrentBusinessDetails?.id, 10);
      }

      const payload = {
        query: manageBusinessData.getAttributeLevelThreshold,
        variables,
      };

      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getAttributeLevelThreshold?.status === 200) {
        const { data } = result.getAttributeLevelThreshold;
        setStar(data?.star);
        setFace(data?.face);
      } else {
        enqueueSnackbar(result?.getAttributeLevelThreshold?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setPageLoading(false);
      console.error('An error occurred while fetching attribute level threshold', error);
    }
  }, [
    userCurrentBusinessDetails?.isOnlineBusiness,
    userCurrentBusinessDetails?.id,
    productId,
    plateFormID,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchAttributeLevelThreshold();
    }
  }, [fetchAttributeLevelThreshold, userCurrentBusinessDetails?.id]);

  const handleStarChange = (event) => {
    const newStarValue = event.target.value;
    setStar(newStarValue);
    updateAttributeLevelThreshold(newStarValue);
  };

  const handleFaceChange = (event) => {
    const newFaceValue = event.target.value;
    setFace(newFaceValue);
    updateAttributeLevelThreshold(newFaceValue);
  };

  const getFaceIcon = (value) => {
    switch (value) {
      case 'Sad':
        return <SentimentDissatisfiedOutlinedIcon className="text-16 align-middle" />;
      case 'Neutral':
        return <SentimentNeutralOutlinedIcon className="text-16 align-middle" />;
      case 'Happy':
        return <SentimentSatisfiedAltOutlinedIcon className="text-16 align-middle" />;
      default:
        return null;
    }
  };

  const updateAttributeLevelThreshold = async (value) => {
    try {
      setRefreshIcon(true);
      const variables = {};
      if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
        if (plateFormID === 5) {
          variables.amazonProductId = productId;
        } else if (plateFormID === 6) {
          variables.flipkartProductId = productId;
        }
      } else {
        variables.userBusinessId = parseInt(userCurrentBusinessDetails?.id, 10);
      }
      if (typeof value === 'number') {
        variables.star = value;
      }
      if (typeof value === 'string') {
        variables.face = value;
      }

      const payload = {
        query: manageBusinessData.updateAttributeLevelThreshold,
        variables,
      };

      const result = await handleApiRequest(payload);
      setRefreshIcon(false);

      if (result?.updateAttributeLevelThreshold?.status === 200) {
        enqueueSnackbar(result?.updateAttributeLevelThreshold?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        fetchAttributeLevelThreshold();
      } else {
        enqueueSnackbar(result?.updateAttributeLevelThreshold?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('An error occurred while updating attribute level threshold', error);
    }
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <div>
      {params?.productId && (
        <div className="pl-20">
          <Toolbar className="pt-32 font-semibold min-h-fit">
            <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
              west
            </Icon>
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 px-12 font-bold text-18"
            >
              {t('products.productsettings')}
            </Typography>
          </Toolbar>
        </div>
      )}
      <div className={`${!productId ? 'p-20 h-max lg:p-40' : 'h-max p-40'}`}>
        <div className="flex items-center">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('serviceRecovery.title')}
          </Typography>
        </div>
        <div className="bg-white p-28 rounded-md mt-20">
          <div className="flex items-center justify-between">
            <div className="w-5/6">
              <Typography className="font-bold text-20">
                {t('serviceRecovery.enableServiceRecovery')}
              </Typography>
              <Typography className="font-semibold text-14 leading-8 mt-16">
                {t('serviceRecovery.enableServiceRecoveryMsg')}
              </Typography>
            </div>
            <Switch checked={enableDisable.recoveryStatus} onChange={enableDisableService} />
          </div>
        </div>
        {enableDisable.recoveryStatus && (
          <div className="bg-white p-28 rounded-md mt-28">
            <div className="d-flex flex-col">
              <div className="w-5/6">
                <Typography className="font-bold text-20">
                  {t('serviceRecovery.setAttributeThresholdLevel')}
                </Typography>
              </div>
              <hr className="my-16 w-full border-dashed border border-gray-400" />
              {star && face && (
                <>
                  <div>
                    <Typography className="font-normal text-15 pb-5">
                      {t('serviceRecovery.setStarRating')}
                    </Typography>
                    <FormControl fullWidth>
                      <Select value={star} onChange={handleStarChange} displayEmpty>
                        {Array.from({ length: 5 }, (_, index) => index + 1).map((value) => (
                          <MenuItem key={value} value={value} className="hover:bg-darkgreen-A300">
                            <StarBorderOutlinedIcon className="font-semibold text-15 align-middle" />
                            <span className="pl-5 text-15 align-middle font-semibold">{`${value} Star`}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <Typography className="font-normal text-15 pb-5 mt-16">
                      {t('serviceRecovery.setFaceRating')}
                    </Typography>

                    <FormControl fullWidth>
                      <Select value={face} onChange={handleFaceChange} displayEmpty>
                        {['Sad', 'Neutral', 'Happy'].map((value) => (
                          <MenuItem key={value} value={value} className="hover:bg-darkgreen-A300">
                            {getFaceIcon(value)}
                            <span className="pl-5 font-semibold text-15 align-middle">{`${value}`}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {!userCurrentBusinessDetails?.isOnlineBusiness && enableDisable.recoveryStatus && (
          <div className="bg-white p-28 rounded-md mt-28">
            <div className="flex items-center justify-between">
              <div className="w-5/6">
                <Typography className="font-bold text-20">
                  {t('serviceRecovery.preventReviewUploads')}
                </Typography>
                <Typography className="font-semibold text-14 leading-8 mt-16">
                  {t('serviceRecovery.preventReviewUploadsMsg')}
                </Typography>
              </div>
              <Switch checked={enableDisable.preventionStatus} onChange={enableDisablePrevention} />
            </div>
          </div>
        )}
        <div className="bg-white sm:px-20 px-10 py-16 rounded-md mt-24 min-h-384">
          <div className="border-b-2 pb-12 border-solid border-grey mb-28 sm:flex justify-between items-center">
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab
                className="sm:text-18 font-semibold p-0 sm:mr-28 mr-14"
                value={0}
                label={t('serviceRecovery.serviceRecoveryInstance')}
              />
              <Tab
                className="sm:text-18 font-semibold p-0"
                value={1}
                label={t('serviceRecovery.preventedReviews')}
              />
            </Tabs>
            {tabValue === 0 && (
              <Select
                className="w-160 mt-8 sm:0 bg-darkgreen-100"
                value={changeStatus || 'all'}
                size="small"
                onChange={handleChangeStatus}
              >
                <MenuItem value="all">All</MenuItem>
                {options.map((item, i) => {
                  return (
                    <MenuItem value={item.id} key={i}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </div>
          {parseInt(tabValue, 10) === 0 && (
            <>
              {negativeReviewData?.length > 0 ? (
                <>
                  {negativeReviewData?.map((item, i) => {
                    const buttonName = options?.find((button) => button.label === item.status);
                    return (
                      <div className="sm:flex justify-between bg-gray-100 p-16 mb-16" key={i}>
                        <div className="flex items-center gap-16">
                          <div>
                            <UserProfile name={item?.name} />
                          </div>
                          <div>
                            <Typography className="font-semibold text-16">
                              {capitalizeFirstLetter(item.name)}
                            </Typography>
                            <Typography>
                              <AccessTimeIcon className="text-gray w-16" />
                              <span className="pl-8">
                                {moment(item.cts).format('MMM DD, YYYY')}
                              </span>
                              <span className="pl-8">{moment(item.cts).format('hh:mm A')}</span>
                            </Typography>
                          </div>
                        </div>
                        <div className="mt-12 sm:mt-0">
                          <Button
                            className={`${
                              buttonName?.buttonName === 'Request'
                                ? 'bg-yellow-A300 !text-yellow-A500'
                                : buttonName?.buttonName === 'Called'
                                ? '!bg-blue !text-white'
                                : '!bg-green !text-white'
                            } rounded px-36`}
                            onClick={() => viewCustomerData(item.id)}
                          >
                            {buttonName?.buttonName}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="flex items-center justify-center">
                  <div>
                    <img className="w-96 m-auto" src="/assets/images/icon/Group38341.svg" alt="" />
                    <Typography className="text-center text-20 pt-12 font-semibold">
                      {t('serviceRecovery.noServiceRecovery')}
                    </Typography>
                    <Typography className="text-center pt-10 sm:max-w-288 m-auto">
                      {t('serviceRecovery.noServiceMessage')}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
          {parseInt(tabValue, 10) === 1 && (
            <>
              {preventionData?.length > 0 ? (
                <>
                  {preventionData?.map((prevent, i) => {
                    return (
                      <div className="flex w-full justify-between bg-gray-100 p-16 mb-16" key={i}>
                        <div className="w-full">
                          {[...Array(5)].map((_, index) => (
                            <Icon
                              className={`text-16 align-middle mr-2 -mt-2 ${
                                index < prevent.rating ? 'text-yellow-800' : 'text-gray-400'
                              }`}
                              key={index}
                            >
                              star
                            </Icon>
                          ))}
                          <div className="flex item-center justify-between">
                            <Typography className="font-semibold">
                              {moment(prevent?.cts).format('MMM DD, YYYY')}
                            </Typography>
                            {prevent.phoneNumber?.phoneNumber !== 0 && (
                              <Typography>
                                {prevent.phoneNumber?.countryCode}{' '}
                                {prevent.phoneNumber?.phoneNumber}
                              </Typography>
                            )}
                          </div>
                          <Typography className="pt-6 text-14">{prevent.review}</Typography>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="flex items-center justify-center">
                  <div>
                    <img className="w-96 m-auto" src="/assets/images/icon/downthumb.png" alt="" />
                    <Typography className="text-center text-20 pt-12 font-semibold">
                      {t('serviceRecovery.noPreventedYet')}
                    </Typography>
                    <Typography className="text-center pt-10 sm:max-w-288 m-auto">
                      {t('serviceRecovery.noPreventedMsg')}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <Dialog
          classes={{
            paper:
              'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-darkgreen border-red-A300 rounded-md',
          }}
          open={openDialogue}
          fullWidth
          maxWidth="xs"
        >
          <Icon
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            onClick={() => handleCancel()}
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black w-full text-left">
            {t('serviceRecovery.serviceRecoveryDetails')}
          </Typography>
          <hr className="border border-dashed border-solid my-8 border-gray-100" />
          <div>
            <Typography className="mt-12">{t('serviceRecovery.customerName')}</Typography>
            <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
              {customerReviewData?.name || '-'}
            </Typography>
          </div>
          <div>
            <Typography className="mt-12">{t('serviceRecovery.phoneNo')}</Typography>
            <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
              {`${customerReviewData?.countryCode} ${customerReviewData?.phoneNumber}` || '-'}
            </Typography>
          </div>
          <div>
            <Typography className="mt-12">{t('serviceRecovery.autoGeneratedText')}</Typography>
            <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
              {customerReviewData?.autoGeneratedReview || '-'}
            </Typography>
          </div>
          <div>
            <Typography className="mt-12">{t('serviceRecovery.dateOfCallRequest')}</Typography>
            <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
              {moment(customerReviewData?.cts).format('DD-MM-YYYY')}
            </Typography>
          </div>
          <div>
            <Typography className="mt-12">{t('serviceRecovery.note')}</Typography>
            <Typography className="bg-gray-100 p-10 mt-8 font-semibold">
              {customerReviewData?.reason || '-'}
            </Typography>
          </div>
          <div>
            <Typography className="mt-12">{t('serviceRecovery.status')}</Typography>
            <Select
              className="w-full"
              size="small"
              value={parseInt(selectedStatus, 10)}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              {options.map((item, i) => {
                return (
                  <MenuItem value={item.id} key={i}>
                    {item.value}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="text-center">
            <Button
              className="bg-darkgreen rounded mt-20 disabled:bg-gray disabled:text-black w-224 md:px-60 text-white hover:bg-darkgreen mx-10 md:mx-0"
              disabled={refreshIcon}
              onClick={() => updateNegativeReview()}
            >
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
              {t('reviewScreenMode.button.save')}
            </Button>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ServiceRecovery;
