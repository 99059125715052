import { useEffect, useState, useCallback, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Typography, Icon } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { FixedSizeList as List } from 'react-window';
import * as yup from 'yup';
import _ from '@lodash';
import ReactSelect from 'react-select';
import Loading from '../Loading';
import '../MangeBusiness.css';
import manageBusinessData from '../../../query/manageBusiness';
import queryData from '../../../query/common';
import { getParamsId, handleApiRequest, sortOptions } from '../../../common/common';

const defaultValues = {
  flipCartUrl: '',
};

function FlipkartProduct(prop) {
  const { businessId, setIsBusinessSuccessFullyAdded } = prop;

  const { t } = useTranslation();

  const [flipkartProduct, setFlipkartProduct] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [productDetails, setProductDetails] = useState();
  const [productType, setProductType] = useState([]);
  const [selectProductType, setSelectProductType] = useState();
  const [productUrl, setProductUrl] = useState();
  const [inputValue, setInputValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight } = props;
    if (options.length > 0) {
      return (
        <div>
          {children?.length > 0 ? (
            <List height={maxHeight} itemCount={children.length} itemSize={itemHeight}>
              {({ index, style }) => (
                <div style={style} className="bg-white relative">
                  {children[index]}
                </div>
              )}
            </List>
          ) : (
            <div className="text-center py-10 my-1">
              {t('manageBusiness.addBusinessSteps.addBusiness.form.businessType.businessNotFound')}
            </div>
          )}
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const handleSelectProductType = (selectedOption) => {
    setSelectProductType(selectedOption ? selectedOption.value : null);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = useMemo(() => {
    return sortOptions(inputValue, productType);
  }, [inputValue, productType]);

  const schema = yup.object().shape({
    flipCartUrl: yup
      .string()
      .trim()
      .required(
        t('manageBusiness.addBusinessSteps.flipkartProduct.dialog.productName.validationMessage')
      ),
  });

  const { control, watch, reset, handleSubmit, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    const fetchBusinessTye = async () => {
      const payload = {
        query: queryData.getOnlineBusinessProductType,
      };
      const result = await handleApiRequest(payload);
      if (result?.getOnlineBusinessProductType?.status === 200) {
        setProductType(
          result?.getOnlineBusinessProductType?.data.map((idx) => ({
            value: idx.id,
            label: idx.bType,
          }))
        );
      }
    };
    fetchBusinessTye();
  }, []);

  const fetchFlipCartData = useCallback(async () => {
    if (businessId) {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getFlipkartProduct,
        variables: {
          userBusinessId: parseInt(businessId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getFlipkartProduct?.status === 200) {
        setFlipkartProduct(result?.getFlipkartProduct?.data);
      } else {
        enqueueSnackbar(result?.getFlipkartProduct?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  }, [businessId, enqueueSnackbar]);

  useEffect(() => {
    fetchFlipCartData();
  }, [fetchFlipCartData]);

  if (pageLoading) {
    return <Loading />;
  }

  const handleRemove = () => {
    setOpenDialogue(false);
    reset(defaultValues);
    setProductDetails();
    setSelectProductType();
  };

  const verifyLink = async () => {
    setRefreshIcon(true);
    const payload = {
      query: manageBusinessData.fetchFlipkartProduct,
      variables: {
        url: getValues('flipCartUrl'),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.fetchFlipkartProduct?.status === 200) {
      setProductUrl(getValues('flipCartUrl'));
      setProductDetails(result?.fetchFlipkartProduct?.data);
    } else {
      enqueueSnackbar(result?.fetchFlipkartProduct?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const onSubmit = async (submitData) => {
    setRefreshIcon(true);
    const payload = {
      query: manageBusinessData.addFlipkartProductV1,
      variables: {
        productTypeId: parseInt(selectProductType?.value, 10),
        url: submitData?.flipCartUrl,
        userBusinessId: parseInt(businessId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.addFlipkartProductV1?.status === 200) {
      fetchFlipCartData();
      handleRemove();
      setOpenDialogue(false);
    } else {
      enqueueSnackbar(result?.addFlipkartProductV1?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const deleteProduct = async (id) => {
    const payload = {
      query: manageBusinessData.deleteFlipkartProduct,
      variables: {
        flipkartProductId: parseInt(id, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.deleteFlipkartProduct?.status === 200) {
      fetchFlipCartData();
    } else {
      enqueueSnackbar(result?.deleteFlipkartProduct?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const saveAndNext = () => {
    setIsBusinessSuccessFullyAdded(true);
  };

  return (
    <div className="text-left">
      <h3 className="text-24 font-bold mb-12">
        {t('manageBusiness.addBusinessSteps.flipkartProduct.pageTitle')}
      </h3>
      <Typography className="block text-16 font-semibold max-w-640 leading-relaxed">
        {t('manageBusiness.addBusinessSteps.flipkartProduct.pageDesc')}
      </Typography>
      <div className="bg-grey-50 sm:px-20 px-10 py-16 rounded-md mt-24 min-h-384">
        <div className="flex justify-between border-b-1 border-solid border-grey pb-14 mb-20">
          <h4 className="text-20 font-semibold ">
            {t('manageBusiness.addBusinessSteps.flipkartProduct.title')}
          </h4>
          {flipkartProduct?.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              className="md:text-14 font-medium disabled:text-black rounded-full px-28 min-h-28 py-0"
              aria-label="add"
              size="small"
              onClick={() => setOpenDialogue(true)}
              type="button"
            >
              {t('manageBusiness.addBusinessSteps.productServices.businessProduct.LAdd')}
            </Button>
          )}
        </div>
        <div>
          {flipkartProduct?.length > 0 ? (
            <>
              {flipkartProduct?.map((item, index) => {
                return (
                  <div
                    className="bg-white rounded-md p-12 mb-12 w-full flex items-center"
                    key={index}
                  >
                    <img
                      src={item?.profileUrl}
                      className="img-fluid max-w-80 rounded-md shadow mr-20"
                      alt="..."
                    />
                    <div>
                      <span className="block  w-full md:text-16 text-14 font-semibold capitalize items-center overflow-hidden whitespace-nowrap text-ellipsis	mb-14 max-w-640">
                        {item?.title}
                      </span>
                      <div className="inline-block bg-grey-50 rounded-md px-10 py-6 mr-10">
                        <span className="inline-block font-medium">
                          <span>
                            <Icon className="text-16 text-yellow-800 align-middle mr-7 -mt-2">
                              star
                            </Icon>
                            {item?.rating}/5
                          </span>
                          <span className="inline-block ml-32">{item?.totalReviews} Reviews</span>
                        </span>
                      </div>
                      <Button
                        variant="contained"
                        color="inherit"
                        className="inline-block md:text-14 font-medium text-grey-700 disabled:text-black rounded-md p-0 bg-grey-50 min-h-32 w-32 min-w-32 w-32 h-32"
                        aria-label="delete"
                        onClick={() => deleteProduct(item?.id)}
                        type="button"
                      >
                        <Icon className="text-20 text-red align-middle">delete</Icon>
                      </Button>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="text-center md:pt-32 pt-32">
              <h5 className="text-20 font-semibold mb-20">
                {t('manageBusiness.addBusinessSteps.flipkartProduct.pageTitle')}
              </h5>
              <Typography className="text-14 font-medium mb-20 max-w-384 mx-auto">
                {t('manageBusiness.addBusinessSteps.flipkartProduct.noProductDesc')}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                className="md:text-16 font-medium disabled:text-black rounded-full px-28"
                aria-label="add"
                onClick={() => setOpenDialogue(true)}
                type="button"
              >
                <Icon>add</Icon>
                {t('manageBusiness.addBusinessSteps.flipkartProduct.pageTitle')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="text-right mt-48 md:mb-96 mb-36">
        <Button
          variant="outlined"
          color="secondary"
          className="md:text-16 font-medium rounded-md mr-24 sm:min-w-160"
          aria-label="skip"
          onClick={() => saveAndNext()}
          type="button"
        >
          {t('manageBusiness.button.skip')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className="md:text-16 font-medium disabled:bg-darkgreen-A300 disabled:text-black rounded-md sm:min-w-160"
          aria-label="confirm"
          disabled={flipkartProduct.length === 0}
          onClick={() => saveAndNext()}
          type="button"
        >
          {t('manageBusiness.button.saveNext')}
        </Button>
      </div>
      <Dialog
        classes={{
          paper:
            '-mt-12 p-28 border-t-8 border-solid business-type-dropdown border-t-darkgreen border-darkgreen rounded-md',
        }}
        open={openDialogue}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-28">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
            {t('manageBusiness.addBusinessSteps.flipkartProduct.pageTitle')}
          </Typography>
        </DialogTitle>

        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col md:overflow-hidden"
        >
          <DialogContent className="p-0 business-type-dropdown-content">
            <div className="block mb-14">
              <InputLabel
                htmlFor="flipCartUrl"
                className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
              >
                {t('manageBusiness.addBusinessSteps.flipkartProduct.dialog.productName.name')}
              </InputLabel>
              <Controller
                control={control}
                name="flipCartUrl"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t(
                      'manageBusiness.addBusinessSteps.flipkartProduct.dialog.productName.placeHolder'
                    )}
                    size="small"
                    id="flipCartUrl"
                    error={!!errors.flipCartUrl}
                    helperText={errors?.flipCartUrl?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            {(!productDetails || productUrl !== getValues('flipCartUrl')) && (
              <Button
                className="md:text-16 font-medium disabled:text-black rounded-md min-w-128"
                variant="contained"
                color="secondary"
                size="small"
                type="button"
                onClick={() => verifyLink()}
                disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
              >
                {t('manageBusiness.addBusinessSteps.flipkartProduct.dialog.button')}

                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            )}
            {productDetails && productUrl === getValues('flipCartUrl') ? (
              <>
                <h4 className="text-14 md:text-16 font-medium mb-12 mt-48">
                  {' '}
                  {t('manageBusiness.addBusinessSteps.flipkartProduct.dialog.productDetail')}
                </h4>
                <div className="bg-grey-100 rounded-md p-10 w-full flex items-center">
                  <img
                    src={productDetails?.product_image}
                    className="img-fluid max-w-80 rounded-md shadow mr-10"
                    alt="..."
                  />
                  <div>
                    <span className="block w-full md:text-12 text-12 font-medium capitalize items-center">
                      {productDetails?.title}
                    </span>
                  </div>
                </div>
                <div className="mt-12">
                  <Typography className="text-14 md:text-16 font-medium mb-4">
                    {t('manageBusiness.addBusinessSteps.amazonProduct.dialog.productType')}
                  </Typography>
                  <ReactSelect
                    labelId="businessType"
                    placeholder={t('manageBusiness.addBusinessSteps.selectProductType')}
                    id="businessType"
                    name="businessType"
                    onChange={handleSelectProductType}
                    onInputChange={handleInputChange}
                    value={productType?.find((op) => {
                      return parseInt(op.value, 10) === parseInt(selectProductType, 10);
                    })}
                    options={filteredOptions}
                    components={{
                      MenuList: CustomMenuList,
                    }}
                  />
                </div>
                <div className="text-center">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="md:text-16 font-medium disabled:text-black rounded-md px-28 py-0 mt-48 min-w-256"
                    aria-label="add"
                    type="submit"
                    disabled={refreshIcon}
                  >
                    {t('manageBusiness.addBusinessSteps.flipkartProduct.dialog.addProduct')}
                    {refreshIcon && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <h4 className="text-center text-14 md:text-16 font-medium mb-28 mt-32">
                  {t('manageBusiness.addBusinessSteps.flipkartProduct.dialog.guideTitle')}
                </h4>
                <img
                  src="/assets/images/business/flipkartProductGuide.png"
                  className="img-fluid mx-auto max-w-320 w-full"
                  alt="..."
                />
              </>
            )}
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}

export default FlipkartProduct;
