import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Icon,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';

const WebsiteAppointment = (props) => {
  const {
    fetchBusinessDetails,
    userCurrentBusinessDetails,
    googleBusinessData,
    openDialog,
    setOpenDialog,
  } = props;
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [link, setLink] = useState();
  const [linkType, setLinkType] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload =
        linkType === 'websiteUri'
          ? {
              query: manageBusinessData.updateGoogleBusinessDetails,
              variables: {
                businessId: parseInt(userCurrentBusinessDetails?.id, 10),
                websiteUri: link,
                mask: linkType,
              },
            }
          : {
              query: manageBusinessData.updateOrDeleteGoogleAttributes,
              variables: {
                businessId: parseInt(userCurrentBusinessDetails?.id, 10),
                data: [
                  {
                    name: 'attributes/url_appointment',
                    valueType: 'URL',
                    uriValues: [{ uri: link }],
                  },
                ],
              },
            };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (
        linkType === 'websiteUri'
          ? result?.updateGoogleBusinessDetails?.status
          : result?.updateOrDeleteGoogleAttributes?.status === 200
      ) {
        fetchBusinessDetails();
        setLink();
        setOpenDialog({ ...openDialog, websiteAndAppointmentDialog: false });
      } else {
        enqueueSnackbar(
          linkType === 'websiteUri'
            ? result?.updateGoogleBusinessDetails?.message
            : result?.updateOrDeleteGoogleAttributes?.message,
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error adding photos/videos:', error);
    }
  };

  return (
    <div>
      <div className="bg-white rounded-md">
        <Typography className="font-bold text-24 mt-16">
          <InsertLinkIcon className="text-32 mr-8" />
          {t('listManagement.website')} & {t('listManagement.appointment')}{' '}
          {t('listManagement.link')}
        </Typography>
        <div className="bg-darkgreen-100 lg:grid grid-cols-2 col-span-1 gap-16 p-28 rounded-md mt-16">
          <div
            className="bg-white p-24 rounded-md"
            onClick={() => {
              setOpenDialog({ ...openDialog, websiteAndAppointmentDialog: true });
              setLinkType('websiteUri');
              setLink(googleBusinessData?.websiteUri);
            }}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setOpenDialog({ ...openDialog, websiteAndAppointmentDialog: true });
              }
            }}
          >
            <Typography className="font-bold text-16">
              {t('listManagement.website')} {t('listManagement.link')}
            </Typography>
            <Typography className="font-semibold text-14">
              {googleBusinessData?.websiteUri
                ? googleBusinessData?.websiteUri
                : t('listManagement.addYourWebsite')}
            </Typography>
          </div>
          <div
            className="bg-white p-24 rounded-md"
            onClick={() => {
              setOpenDialog({ ...openDialog, websiteAndAppointmentDialog: true });
              setLinkType('URL');
              setLink(googleBusinessData?.appointmentUrl);
            }}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setOpenDialog({ ...openDialog, websiteAndAppointmentDialog: true });
              }
            }}
          >
            <Typography className="font-bold text-16">
              {t('listManagement.appointment')} {t('listManagement.link')}
            </Typography>
            <Typography className="font-semibold text-14">
              {googleBusinessData?.appointmentUrl
                ? googleBusinessData?.appointmentUrl
                : t('listManagement.addYourAppointment')}
            </Typography>
          </div>
        </div>
      </div>
      <Dialog
        classes={{
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        open={openDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Icon
              onClick={() => setOpenDialog({ ...openDialog, websiteAndAppointmentDialog: false })}
              className="text-24 absolute right-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-darkgreen-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('listManagement.address')} :{' '}
              {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold text-16 pt-20">
              {linkType === 'websiteUri'
                ? `${t('listManagement.addYourWebsite')}`
                : `${t('listManagement.addYourAppointment')}`}
            </Typography>
            <div>
              <Typography className="font-bold text-16 py-8">
                {linkType === 'websiteUri'
                  ? `${t('listManagement.website')}`
                  : `${t('listManagement.appointment')}`}{' '}
                {t('listManagement.link')}
              </Typography>
              <TextField
                className="w-full"
                name="phoneNumber2"
                value={link}
                placeholder={
                  linkType === 'websiteUri'
                    ? t('listManagement.placeHolder.websiteUrl')
                    : t('listManagement.placeHolder.appointmentUrl')
                }
                onChange={(event) => setLink(event.target.value)}
                size="small"
              />
            </div>
          </div>
          <div className="text-center">
            <Button
              className="bg-darkgreen w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-darkgreen"
              disabled={refreshIcon || !link}
              onClick={() => onSubmit()}
            >
              {refreshIcon && (
                <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
              )}
              Save
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default WebsiteAppointment;
