import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import history from '@history';
import './MangeBusiness.css';
import { Button, Typography } from '@mui/material';
import BusinessLocation from './addPhyscialBusiness/BusinessLocation';
import reducer from './store';
import { getReviewPlaterFormData, getUserPackageData } from '../../../store/userSlice';
import AddOnlineBusiness from './addOnlineBusiness/AddOnlineBusiness';

function ManageBusiness() {
  const { t } = useTranslation();

  const [physicalPlatFrom, setPhysicalPlatForm] = useState([]);
  const [onlinePlatFrom, setOnlinePlatForm] = useState([]);
  const getUserSubscriptionPackageDetails = useSelector(getUserPackageData);
  const reviewPlateFormsData = useSelector(getReviewPlaterFormData);
  const [businessAddInProgress, setBusinessAddInProgress] = useState({
    isPhysical: false,
    isOnline: false,
  });
  const [isBusinessSuccessFullyAdded, setIsBusinessSuccessFullyAdded] = useState(false);

  useEffect(() => {
    const excludedPlatforms = [
      'amazon',
      'flipkart',
      'linkedin',
      'twitter',
      'instagram',
      'facebook',
      'quickreviews',
    ];
    const filteredData = reviewPlateFormsData?.filter(
      (platform) => !excludedPlatforms?.includes(platform?.name)
    );
    const updatedData = filteredData?.map((platform) => ({
      ...platform,
      isBusinessDetailsAdded: false,
    }));
    const googlePlatform = updatedData?.find((platform) => platform.name === 'google');
    const otherPlatforms = updatedData?.filter((platform) => platform.name !== 'google');
    const finalData = googlePlatform ? [googlePlatform, ...otherPlatforms] : otherPlatforms;
    setPhysicalPlatForm(finalData);
  }, [reviewPlateFormsData]);

  useEffect(() => {
    const onlineBusiness = ['amazon', 'flipkart', 'quickreviews'];
    const onlineData = reviewPlateFormsData?.filter((platform) =>
      onlineBusiness?.includes(platform?.name)
    );
    const sortOnlineData = onlineData?.sort((a, b) => a.id - b.id);
    setOnlinePlatForm(sortOnlineData);
  }, [reviewPlateFormsData]);

  const clickOnExploreFeature = () => {
    setIsBusinessSuccessFullyAdded(false);
    setBusinessAddInProgress({
      isPhysical: false,
      isOnline: false,
    });
    history.push('business/dashboard');
  };

  return (
    <div className="flex items-center justify-center bg-white h-full w-full sm:px-0 px-16 sm:py-0 py-40">
      <img
        src="assets/images/background/top-round.png"
        className="absolute top-0 right-0 hidden lg:block"
        alt="..."
      />
      <img
        src="assets/images/background/bottom-round.png"
        className="absolute bottom-0 left-0 hidden lg:block"
        alt="..."
      />
      {!isBusinessSuccessFullyAdded ? (
        <div className="container px-16">
          <img className="m-auto w-84 h-84" src="assets/images/business/quickhub.svg" alt="..." />
          <Typography className="text-center font-bold text-24 my-16">
            {t('manageBusiness.addBusinessSteps.businessLocation.pageTitle')}
          </Typography>
          <div className="bg-gray-50 p-4 sm:p-28 md:p-40 sm:flex w-full gap-24 md:gap-112 rounded-xl">
            {!businessAddInProgress.isOnline && !isBusinessSuccessFullyAdded && (
              <div className="w-full">
                {!businessAddInProgress.isOnline && !businessAddInProgress.isPhysical && (
                  <Typography className="font-semibold mb-12">
                    {t('manageBusiness.physicalBusiness')}
                  </Typography>
                )}
                <BusinessLocation
                  physicalPlatFrom={physicalPlatFrom}
                  businessAddInProgress={businessAddInProgress}
                  setPhysicalPlatForm={setPhysicalPlatForm}
                  packageTypeId={getUserSubscriptionPackageDetails?.packageTypeId}
                  setBusinessAddInProgress={setBusinessAddInProgress}
                  setIsBusinessSuccessFullyAdded={setIsBusinessSuccessFullyAdded}
                />
              </div>
            )}
            {!businessAddInProgress.isOnline &&
              !businessAddInProgress.isPhysical &&
              !isBusinessSuccessFullyAdded && <div className="border-r-2 border-gray" />}
            {!businessAddInProgress.isPhysical &&
              !isBusinessSuccessFullyAdded &&
              !physicalPlatFrom?.find((item) => item.businessName)?.businessName && (
                <div className="w-full">
                  {!businessAddInProgress.isOnline && !businessAddInProgress.isPhysical && (
                    <Typography className="font-semibold mb-12">
                      {t('manageBusiness.onlineBusiness')}
                    </Typography>
                  )}
                  <AddOnlineBusiness
                    onlinePlatFrom={onlinePlatFrom}
                    businessAddInProgress={businessAddInProgress}
                    setBusinessAddInProgress={setBusinessAddInProgress}
                    setIsBusinessSuccessFullyAdded={setIsBusinessSuccessFullyAdded}
                  />
                </div>
              )}
          </div>
        </div>
      ) : (
        <div className="container !mx-16 md:!w-1/2 bg-gray-100 py-148 flex justify-center rounded-lg">
          <div className="flex items-center text-center justify-center px-16 py-96">
            <div>
              <img className="m-auto w-160 sm:w-192" src="assets/images/icon/success.png" alt="" />
              <Typography className="font-bold text-24 py-20">
                {t('manageBusiness.businessAddedSuccessFully')}
              </Typography>
              <Typography className="md:max-w-480 font-semibold">
                {t('manageBusiness.businessAddedMessage')}
              </Typography>
              <Button
                className="bg-darkgreen text-white w-256 mt-16 hover:bg-darkgreen font-medium rounded"
                onClick={() => clickOnExploreFeature()}
              >
                {t('manageBusiness.button.exploreFeature')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(ManageBusiness);
