const getAllBusinessType = `
	query ($isActive: Boolean, $pageNumber: Int, $pageSize: Int, $searchText: String, $sortBy: String, $sortOrder: OrderDirection) {
		getAllBusinessType(isActive: $isActive, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText, sortBy: $sortBy, sortOrder: $sortOrder) {
               totalCount
      data {
          bType
          cts
          id
          isActive
      } 
               message
               status 
		}
	}
`;

const addBusinessType = `
     mutation ($businessType: String!) {
		addBusinessType(businessType: $businessType) {
               message
               status
		}
	}
`;

const updateBusinessType = `
    mutation ($businessType: String!, $businessTypeId: Int!) {
		updateBusinessType(businessType: $businessType, businessTypeId: $businessTypeId) {
      message
      status
		}
	}
`;

const getAllBusinessAttributes = `
	query ($isActive: Boolean, $pageNumber: Int, $pageSize: Int, $searchText: String, $sortBy: String, $sortOrder: OrderDirection) {
		getAllBusinessAttributes(isActive: $isActive, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText, sortBy: $sortBy, sortOrder: $sortOrder) {
      totalCount
      data {
        bAttribute
        businessAttributeValueId
        type
        icon
        cts
        id
        isActive
      } 
      message
      status 
		}
	}
`;

const addBusinessAttributes = `
mutation ($bAttributes: String!, $icon: String, $businessAttributeValueId: Int!) {
		addBusinessAttributes(bAttributes: $bAttributes, icon: $icon, businessAttributeValueId: $businessAttributeValueId) {
      message
      status 
		}
	}
`;

const updateBusinessAttribute = `
mutation ($bAttributes: String!, $icon: String, $bAttributeId: Int!, $businessAttributeValueId: Int) {
  updateBusinessAttribute(bAttributes: $bAttributes, icon: $icon, bAttributeId: $bAttributeId, businessAttributeValueId: $businessAttributeValueId) {
      message
      status 
		}
	}
`;

const getAllBusinessTypeAttributes = `
query(
   $pageNumber: Int
   $pageSize: Int
   $sortBy: String
   $sortOrder: OrderDirection
   $searchText: String
   $isActiveFilter: Boolean
 ) {
   getAllBusinessTypeAttributes(
     pageNumber: $pageNumber
     pageSize: $pageSize
     sortBy: $sortBy
     sortOrder: $sortOrder
     searchText: $searchText
     isActiveFilter: $isActiveFilter
   ) {
     totalCount
     data {
       id
       businessTypeId
       business_attributes {
         bAttribute
         id
       }
       business_types {
         isActive
         bType
         id
       }
       cts
     }
     totalCount
     message
     status
   }
 }
 
`;

const addBusinessTypeAttribute = `
mutation ($bAttributeId: [Int!]!, $bTypeId: Int!) {
   addBusinessTypeAttribute(bAttributeId: $bAttributeId, bTypeId: $bTypeId) {
      message
      status 
		}
	}
`;

const updateBusinessAttributeType = `
mutation ($bAttributeId: [Int!]!, $bAttributeTypeId: Int!, $bTypeId: Int) {
   updateBusinessAttributeType(bAttributeId: $bAttributeId, bAttributeTypeId: $bAttributeTypeId, bTypeId: $bTypeId) {
      message
      status 
		}
	}
`;

const enableDisableBusinessType = `
mutation ($businessId: Int!, $isEnable: Boolean!) {
   enableDisableBusinessType(businessId: $businessId, isEnable: $isEnable) {
      message
      status 
		}
	}
`;

const getBusinessList = `
query ($pageNumber: Int, $pageSize: Int, $countryId: Int,$sortBy: String, $sortOrder: OrderDirection, $businessName: String,$businessType:String,$category:String, $status: Boolean) {
  getBusinessList(pageNumber: $pageNumber, countryId: $countryId, pageSize: $pageSize,  sortBy: $sortBy, sortOrder: $sortOrder,businessName: $businessName,businessType:$businessType,category:$category, status: $status) {
      message
      status 
      totalCount
      id
      data{
        business_types{
          bType
          id
          isActive
        }
        countries{
          id
          name
        }
        cts
        email
        id
        name
        userId
      }
		}
	}
`;

const deleteBusiness = `
mutation ($businessId: Int!) {
  deleteBusiness(businessId: $businessId) {
      message
      status 
		}
	}
`;

const getSendCampaignMessages = `
query ($pageNumber: Int, $pageSize: Int, $servicesType: String, $messageType: String,$userBusinessId: Int){
  getSendCampaignMessages (pageNumber: $pageNumber, pageSize: $pageSize, servicesType: $servicesType, messageType: $messageType, userBusinessId: $userBusinessId){
    data {
      user_businesses{
        id
        name
      }
      campaign_platform{
        id
        name
      }
      campaign_services_queue{
        messages
        id
        status
        campaignPlatformId   
        scheduleDate
        phoneNumber
        name
        email
        countryCode
      }
      campaign_services_standard_messages{
        id
        title
        messages
        htmlTemplate
        htmlSubject
      }
      status
      cts
      id
      totalCustomers
      userId
    }
    totalCount
    message
    status
  }
}
`;

const sendCampaignMessages = `
mutation ($businessId: Int!, $campaignPlatformId: Int!, $scheduleDate: DateTime, $isSchedule: Boolean, $campaignServicesStandardMessageId: Int!, $queue: [QueueList!]!) {
  sendCampaignMessages(
    businessId: $businessId
    campaignPlatformId: $campaignPlatformId
    scheduleDate:$scheduleDate,
    campaignServicesStandardMessageId: $campaignServicesStandardMessageId
    queue: $queue,
    isSchedule:$isSchedule
  ) {
    message
    status
  }
}
`;

const getBusinessBasedOnUserId = `
query{
  getBusinessBasedOnUserId{
    message
    status
    data{
      id
      name
      businssQrId
      address
      address2
    }
  }
}
`;

const campaignServiceValidation = `
query($campaignPlatformId: Int!, $userBusinessId: Int!){
  campaignServiceValidation(campaignPlatformId: $campaignPlatformId, userBusinessId:$userBusinessId){
    remainingCount
    totalAccessCount
    sendCount
    available
    message
    status
  }
}
`;

const getCampaignStandardMessages = `
query ($userBusinessId: Int, $campaignPlatformId:Int, $pageNumber: Int, $pageSize: Int,$messagesBusinessTypeId: Int){
  getCampaignStandardMessages(userBusinessId: $userBusinessId, campaignPlatformId: $campaignPlatformId, pageNumber: $pageNumber, pageSize: $pageSize, messagesBusinessTypeId: $messagesBusinessTypeId){
    data{
      id
      cts
      title
      messages
      htmlTemplate
      isActive
      campaignPlatformId
      htmlSubject
    }
    status
    message
  }
}
`;

const getAllQueueMessages = `
query ($campaignServicesId: Int!, $pageNumber:Int, $pageSize: Int){
  getAllQueueMessages(campaignServicesId:$campaignServicesId, pageNumber:$pageNumber, pageSize:$pageSize){
    data {
      campaignPlatformId
      servicesType
      messagesType
      scheduleDate
      campaign_services_standard_messages {
        htmlSubject
        htmlTemplate
        messages
      }
      campaign_services_queue {
        id
        email
        name
        countryCode
        phoneNumber
        status
        messages
      }
    }
    totalCount
    message
    status
  }
}
`;

const saveUserCSV = `
mutation saveUserCSV($file: Upload, $callFrom: String, $userBusinessId: Int) {
  saveUserCSV(file: $file, callFrom: $callFrom, userBusinessId: $userBusinessId) {
    message
    status
  }
}
`;

const getUserCSV = `
query($callFrom: String, $userBusinessId: Int){
  getUserCSV(callFrom: $callFrom, userBusinessId:$userBusinessId){
    data{
      allContact {
        firstName
        lastName
        countryCode
        cts
        dataFrom
        email
        id
        name
        phoneNumber
      }
      userCSVData{
        fileName
        id
      }
    }
    message
    status
  }
}
`;

const getUserCSVOnCsvId = `
query($csvId: Int!){
  getUserCSVOnCsvId(csvId: $csvId){
    data{
      countryCode
      email
      name
      phoneNumber
      dataFrom
      id
    }
    message
    status
  }
}
`;

const deleteUserCsv = `
mutation ($csvId: Int!) {
  deleteUserCsv(csvId: $csvId){
    message
    status
  }
}
`;

const getCampaignPlatformDemoCsv = `
{
  getCampaignPlatformDemoCsv {
    status
    message
    data {
      demoCsv
    }
  }
}
`;

const getBusinessAttributeValue = `
query {
      getBusinessAttributeValue{
        message
        status
        data{
          id
          type
          reaction  
        }
    
		}
	}
`;

const createCampaignServicesStandardMessages = `
mutation($userBusinessId: Int!, $htmlTemplate: String, $title: String, $serviceType: String!, $messages: String, $htmlSubject: String, $campaignPlatformId: Int!) {
  createCampaignServicesStandardMessages(userBusinessId:$userBusinessId, serviceType: $serviceType, htmlTemplate:$htmlTemplate,
    title: $title, messages:$messages, htmlSubject: $htmlSubject,
    campaignPlatformId:$campaignPlatformId){
    status
    message
    data{
      id
    }
  }
}
`;

const getCampaignServicesMessagesBusinessType = `
{
  getCampaignServicesMessagesBusinessType {
    status
    message
    data {
       id
      type
    }
  }
}
`;

const getAppSetupCard = `
query ($businessId: Int!, $type: String!) {
  getAppSetupCard(businessId : $businessId, type: $type){
    cts
    data {
      backgroundColor
      button
      buttonAction
      buttonColor
      cardName
      cardType
      cts
      description
      id
      image
      isActive
      isAdded
      isOfflineSupport
      isOnlineSupport
      offlineIndex
      onlineIndex
      title
      uts
      videoUrl
    }
    id
    message
    status
  }
}
`;

const getBusinessWhatsNewCard = `
query ($businessId: Int, $domain: String, $pageNumber: Int, $pageSize: Int) {
  getBusinessWhatsNewCard(businessId : $businessId, domain : $domain, pageNumber : $pageNumber, pageSize: $pageSize){
    cts
    data {
      cardDetails
      cts
      whatsNewCardId
      id
    }
    id
    message
    status
  }
}
`;

const searchCampaignContact = `query ($searchText: String!, $callFrom: String) {
  searchCampaignContact(
    searchText: $searchText
    callFrom: $callFrom
  ) {
    status
    message
    data {
      name
      email
      phoneNumber
      countryCode
    }
  }
}`;

const sendCampaignWhatsAppMessages = `
mutation ($businessId: Int!, $campaignPlatformId: Int!, $phoneNumber: PhoneNumberObjectV1!  $firstName: String!, $lastName: String! $servicesType: String!, $whatsAppTemplateId: Int!) {
  sendCampaignWhatsAppMessages(
    businessId: $businessId
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
    campaignPlatformId: $campaignPlatformId
    whatsAppTemplateId: $whatsAppTemplateId
    servicesType: $servicesType
  ) {
    message
    status
  }
}
`;

const getOnlineProductDefaultTemplate = `
query getOnlineProductDefaultTemplate($genericName: String!, $productUrl: String!, $profileUrl: String, $type: String!, $reviewPlatformsId: Int) {
  getOnlineProductDefaultTemplate(genericName: $genericName, productUrl: $productUrl, profileUrl: $profileUrl, type: $type, reviewPlatformsId: $reviewPlatformsId){
    data {
    htmlTemplate
    id
    isActive
    text
    type
    }
    id
    message
    status
  }
}
`;

const sendOnlineMessageAndEmail = `mutation sendOnlineMessageAndEmail($amazonProductId: Int, $campaignPlatformId: Int!, $countryCode: String, $email: String, $emailTemplate: String, $flipkartProductId: Int, $phoneNumber: Float, $smsTemplate: String, $userBusinessId: Int) {
sendOnlineMessageAndEmail(amazonProductId: $amazonProductId, campaignPlatformId: $campaignPlatformId, countryCode: $countryCode, email: $email, emailTemplate: $emailTemplate, flipkartProductId: $flipkartProductId, phoneNumber: $phoneNumber, smsTemplate: $smsTemplate, userBusinessId: $userBusinessId) {
message
status
}}`;

const businessData = {
  getAllBusinessType,
  addBusinessType,
  updateBusinessType,
  getAllBusinessAttributes,
  addBusinessAttributes,
  updateBusinessAttribute,
  getAllBusinessTypeAttributes,
  addBusinessTypeAttribute,
  updateBusinessAttributeType,
  enableDisableBusinessType,
  getBusinessList,
  deleteBusiness,
  getSendCampaignMessages,
  sendCampaignMessages,
  getBusinessBasedOnUserId,
  campaignServiceValidation,
  getCampaignStandardMessages,
  getAllQueueMessages,
  saveUserCSV,
  getUserCSV,
  getUserCSVOnCsvId,
  deleteUserCsv,
  getCampaignPlatformDemoCsv,
  getBusinessAttributeValue,
  createCampaignServicesStandardMessages,
  getCampaignServicesMessagesBusinessType,
  getAppSetupCard,
  getBusinessWhatsNewCard,
  searchCampaignContact,
  sendCampaignWhatsAppMessages,
  getOnlineProductDefaultTemplate,
  sendOnlineMessageAndEmail,
};

export default businessData;
