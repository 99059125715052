import { useState } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Icon,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';

const BasicBusinessDetails = (props) => {
  const {
    fetchBusinessDetails,
    userCurrentBusinessDetails,
    googleBusinessData,
    openDialog,
    setOpenDialog,
  } = props;

  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState(
    googleBusinessData?.phoneNumbers?.primaryPhone?.slice(1)?.replace(/\s+/g, '')
  );
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState([
    {
      phoneNumber:
        googleBusinessData?.phoneNumbers?.additionalPhones[0]?.slice(1)?.replace(/\s+/g, '') || '',
    },
    {
      phoneNumber:
        googleBusinessData?.phoneNumbers?.additionalPhones[1]?.slice(1)?.replace(/\s+/g, '') || '',
    },
  ]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setAdditionalPhoneNumber([
      {
        phoneNumber:
          googleBusinessData?.phoneNumbers?.additionalPhones[0]?.slice(1)?.replace(/\s+/g, '') ||
          '',
      },
      {
        phoneNumber:
          googleBusinessData?.phoneNumbers?.additionalPhones[1]?.slice(1)?.replace(/\s+/g, '') ||
          '',
      },
    ]);
    setPrimaryPhoneNumber(
      googleBusinessData?.phoneNumbers?.primaryPhone?.slice(1)?.replace(/\s+/g, '')
    );
    setOpenDialog({ ...openDialog, phoneNumberDialog: false });
  };

  const handlePrimaryNumber = (event) => {
    setPrimaryPhoneNumber(event.target.value);
  };

  const handleAdditionalPhoneNumber = (event) => {
    setAdditionalPhoneNumber((prevNumbers) => {
      const updatedNumbers = [...prevNumbers];
      if (event.target.name === 'phoneNumber1') {
        updatedNumbers[0] = { phoneNumber: event.target.value };
      } else if (event.target.name === 'phoneNumber2') {
        updatedNumbers[1] = { phoneNumber: event.target.value };
      }
      return updatedNumbers;
    });
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    const validAdditionalPhones = additionalPhoneNumber
      .filter((phone) => !Number.isNaN(parseInt(phone.phoneNumber, 10)))
      .map((phone) => ({
        phoneNumber: parseInt(phone?.phoneNumber?.replace(/\D+/g, ''), 10),
      }));
    try {
      const payload = {
        query: manageBusinessData.updateGoogleBusinessDetails,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          mask: 'phoneNumbers',
          phoneNumbers: {
            primaryPhone: {
              phoneNumber: parseInt(primaryPhoneNumber, 10),
            },
            additionalPhones: validAdditionalPhones.length > 0 ? validAdditionalPhones : undefined,
          },
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateGoogleBusinessDetails?.status === 200) {
        fetchBusinessDetails();
        handleClose();
      } else {
        enqueueSnackbar(result?.updateGoogleBusinessDetails?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating phone numbers:', error);
    }
  };

  return (
    <div>
      <Typography className="font-bold text-24 mt-16">
        <BusinessIcon className="text-32" /> {t('listManagement.basicBusinessDetails')}
      </Typography>
      <div className="bg-darkgreen-100 lg:grid grid-cols-2 col-span-1 gap-16 p-28 rounded-md mt-16">
        <div
          className="bg-white p-24 rounded-md"
          onClick={() => setOpenDialog({ ...openDialog, phoneNumberDialog: true })}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setOpenDialog({ ...openDialog, phoneNumberDialog: true });
            }
          }}
        >
          <Typography className="font-bold text-16">{t('listManagement.phoneNumber')}</Typography>
          <Typography className="font-semibold text-14">
            {t('listManagement.phoneNumber')} : {googleBusinessData?.phoneNumbers?.primaryPhone}
          </Typography>
        </div>
        <div className="bg-white p-24 rounded-md my-16 lg:my-0">
          <Typography className="font-bold text-16">
            {t('listManagement.businessStatus')}
          </Typography>
          <Typography className="font-semibold text-14">
            {t('listManagement.businessStatus')} : {googleBusinessData?.openInfo?.status}
          </Typography>
        </div>
        <div className="bg-white p-24 rounded-md">
          <Typography className="font-bold text-16">
            {t('listManagement.locationUpdate')}
          </Typography>
          <Typography className="font-semibold text-14">
            {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
            {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
            {googleBusinessData?.storefrontAddress?.locality}{' '}
            {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
            {googleBusinessData?.storefrontAddress?.postalCode}
          </Typography>
        </div>
      </div>
      <Dialog
        classes={{
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        open={openDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Typography className="text-20 font-bold text-black w-full">
              {t('listManagement.updatePhoneNumber')}
            </Typography>
            <Icon
              onClick={() => handleClose()}
              className="text-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-darkgreen-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('listManagement.address')} :{' '}
              {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold text-16 pt-20">
              {t('listManagement.addPrimaryAndAdditionalPhoneNumber')}
            </Typography>
            <div className="py-8">
              <Typography className="font-bold text-16 pb-8">
                {t('listManagement.primaryPhoneNumber')}
              </Typography>
              <TextField
                className="w-full"
                type="number"
                placeholder={t('listManagement.placeHolder.phoneNumber')}
                value={primaryPhoneNumber}
                onChange={handlePrimaryNumber}
                size="small"
              />
            </div>
            <div>
              <Typography className="font-bold text-16 py-8">
                {t('listManagement.additionalPhoneNumber1')} ({t('listManagement.optional')})
              </Typography>
              <TextField
                className="w-full"
                name="phoneNumber1"
                type="number"
                placeholder={t('listManagement.placeHolder.additionalPhoneNumber1')}
                value={additionalPhoneNumber[0]?.phoneNumber?.toString()}
                onChange={(event) => handleAdditionalPhoneNumber(event)}
                size="small"
              />
            </div>
            <div>
              <Typography className="font-bold text-16 py-8">
                {t('listManagement.additionalPhoneNumber2')} ({t('listManagement.optional')})
              </Typography>
              <TextField
                className="w-full"
                name="phoneNumber2"
                type="number"
                placeholder={t('listManagement.placeHolder.additionalPhoneNumber1')}
                value={additionalPhoneNumber[1]?.phoneNumber?.toString()}
                onChange={(event) => handleAdditionalPhoneNumber(event)}
                size="small"
              />
            </div>
          </div>
          <div className="text-center">
            <Button
              className="bg-darkgreen w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-darkgreen"
              disabled={refreshIcon || !primaryPhoneNumber}
              onClick={() => onSubmit()}
            >
              {refreshIcon && (
                <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
              )}
              Save
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default BasicBusinessDetails;
