const getQuickChatDefaultAvatar = ` 
query{
	getQuickChatDefaultAvatar {
          message
          status
          data{
            id
            cts
            avatar
            isActive
          }      
	}
  }  
`;

const checkQuickChatDomain = ` 
query($domain: String!){
	checkQuickChatDomain(domain: $domain) {
          message
          status
          data{
            domainAvailable
          }      
	}
  }  
`;

const createQuickChatAndUpdate = ` 
mutation($avatar: String, $avatarImageFile: Upload, $domain: String!, $headerColor: String!, $name: String!, $userBusinessId: Int!){
	createQuickChatAndUpdate(avatar: $avatar, avatarImageFile: $avatarImageFile, domain: $domain, headerColor: $headerColor, name: $name, userBusinessId: $userBusinessId) {
          message
          status  
	}
  }  
`;

const getQuickChat = ` 
query($businessId: Int, $domain: String){
	getQuickChat(businessId: $businessId, domain: $domain) {
          message
          status 
          data{
            avatar
            domain
            headerColor
            id
            isActive
            name
            userBusinessesId
            quickChatSuggestion
          }
	}
  }  
`;

const getAllQuickChatWithLastMessage = ` 
query($pageNumber: Int, $pageSize: Int, $quickChatId: Int!, $status: String) {
  getAllQuickChatWithLastMessage(
    quickChatId: $quickChatId
    pageSize:$pageSize
    pageNumber:$pageNumber
    status:$status
  ) {
    status
    message
    totalCount
    data {
      id
      ipAddress
      quickChatId
      favourite
      status
      quickChatRoomMessages {
        quickChatRoomId
        message
        messageType
        status
        uts
      }
    }
  }
} 
`;

const getAllQuickChatRoomMessage = ` 
query($ipAddress: String!, $quickChatId: Int!) {
  getAllQuickChatRoomMessage(
    ipAddress:$ipAddress
    quickChatId: $quickChatId
  ) {
    status
    message
    status
    uts
    data{
      cts
      message
      messageType
      quickChatRoomId
      status
      uts
    }
  }
} 
`;

const getAllQuickChatRoomMessageUsingQuickChatId = ` 
query($pageNumber: Int, $pageSize: Int, $quickChatRoomId: Int!, $sortBy: String, $sortOrder: OrderDirection) {
  getAllQuickChatRoomMessageUsingQuickChatId(
    pageNumber:$pageNumber
    pageSize: $pageSize
    quickChatRoomId: $quickChatRoomId
    sortBy: $sortBy
    sortOrder: $sortOrder
  ) {
    status
    message
    data {
      message
      messageType
      status
      cts
      uts
    }
  }
} 
`;

const sendQuickChatMessageByOwner = ` 
mutation($message: String!, $quickChatRoomId: Int!) {
  sendQuickChatMessageByOwner(
    message:$message
    quickChatRoomId: $quickChatRoomId
  ) {
    status
    message
  }
} 
`;

const saveQuickChatClientRating = `mutation ($quickChatId: Int!, $ipAddress: String!, $rating: Int!) {
  saveQuickChatClientRating(
    quickChatId: $quickChatId
    ipAddress: $ipAddress
    rating: $rating
  ) {
    status
    message
  }
}
`;

const addQuickChatFavorite = `mutation ($isFavorite: Boolean!, $quickChatRoomId: Int!) {
  addQuickChatFavorite(
    isFavorite: $isFavorite
    quickChatRoomId: $quickChatRoomId
  ) {
    status
    message
  }
}

`;

const quickChatQuery = {
  getQuickChatDefaultAvatar,
  checkQuickChatDomain,
  createQuickChatAndUpdate,
  getQuickChat,
  getAllQuickChatWithLastMessage,
  getAllQuickChatRoomMessage,
  getAllQuickChatRoomMessageUsingQuickChatId,
  sendQuickChatMessageByOwner,
  saveQuickChatClientRating,
  addQuickChatFavorite,
};

export default quickChatQuery;
