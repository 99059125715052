import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import withReducer from 'app/store/withReducer';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import JSEncrypt from 'jsencrypt';
import history from '@history';
import { useTranslation } from 'react-i18next';
import reducer from './store';
import Query from '../query/auth';
import { forgotPasswordAction, sendForgotPasswordLink } from './store/forgotPasswordSlice';
import { getEncryptedData } from '../common/common';

const defaultValues = {
  email: '',
};

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('signInPage.formField.email.validationMessages.validEmail'))
      .required(t('signInPage.formField.email.validationMessages.required')),
  });

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const sendForgotPasswordLinkStatus = useSelector(
    ({ forgotPasswordReducer }) =>
      forgotPasswordReducer?.forgotPassword?.sendForgotPasswordLinkData?.data
        ?.sendForgotPasswordLink
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { isValid, dirtyFields, errors } = formState;
  const [confirmationEmail, setConfirmationEmail] = React.useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(forgotPasswordAction());
    };
  }, [dispatch]);

  useEffect(() => {
    setLoading(false);
    if (sendForgotPasswordLinkStatus?.status === 400) {
      enqueueSnackbar(sendForgotPasswordLinkStatus?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else if (sendForgotPasswordLinkStatus?.status === 200) {
      enqueueSnackbar(sendForgotPasswordLinkStatus?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      setShowConfirmationDialog(true);
    }
  }, [enqueueSnackbar, sendForgotPasswordLinkStatus]);

  function onSubmitForgotPassword({ email }) {
    setLoading(true);
    const data = getEncryptedData(`{"e": "${email}"}`);
    const payload = {
      query: Query.sendForgotPasswordLink,
      variables: { data },
    };
    setConfirmationEmail(email);
    dispatch(sendForgotPasswordLink(payload));
  }

  return (
    <div className="flex items-center justify-center bg-white h-full w-full sm:px-0 px-16 sm:py-0 py-40">
      <img
        src="assets/images/background/top-round.png"
        className="absolute top-0 right-0 hidden lg:block"
        alt="..."
      />
      <div>
        <span className="absolute bg-darkgreen rounded-full text-white w-32 h-32 text-center pt-2 top-72 left-72">
          <Icon className="text-28 cursor-pointer" onClick={() => history.go(-1)}>
            chevron_left
          </Icon>
        </span>

        <div>
          <img
            src="assets/images/logo/quick_hub.png"
            className="mx-auto md:max-w-max max-w-80"
            alt="logo"
          />

          <Typography className="mt-10 mb-32 text-24 font-bold text-center text-black">
            {t('forgotPassword.title')}
          </Typography>
          <Paper className="shadow-none">
            <div className="bg-grey-50 sm:max-w-[816px] md:min-w-[816px] w-full mx-auto rounded-2xl items-center justify-center sm:shadow md:shadow-none py-20 px-20 md:py-40 md:px-80">
              <Typography className="text-20 font-bold tracking-tight leading-tight">
                {t('forgotPassword.title')}
              </Typography>

              <div className="flex items-baseline mt-28 font-medium">
                <Typography className="text-15 font-medium mt-15">
                  {t('forgotPassword.labels.description')}
                </Typography>
              </div>
              <form
                name="forgotPasswordForm"
                noValidate
                className="flex flex-col justify-center w-full mt-32"
                onSubmit={handleSubmit(onSubmitForgotPassword)}
              >
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24 bg-white w-full"
                      size="medium"
                      label="Email"
                      autoFocus
                      type="email"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      variant="outlined"
                      required
                      fullWidth
                      InputLabelProps={{
                        classes: {
                          asterisk: 'text-red-500',
                        },
                      }}
                    />
                  )}
                />
                <div className="relative">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    className="w-full mt-16 md:text-18 font-semibold disabled:text-black rounded-md"
                    aria-label="Submit"
                    disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                    type="submit"
                  >
                    {t('forgotPassword.button.submit')}{' '}
                    {loading && <CircularProgress size={24} className="absolute color-darkgreen" />}
                  </Button>
                </div>
              </form>
            </div>
          </Paper>
          <Dialog
            open={showConfirmationDialog}
            onClose={() => setShowConfirmationDialog(false)}
            maxWidth="xs"
            fullWidth
            className="flex items-center justify-center"
          >
            <DialogTitle>
              <div className="flex items-center justify-center">
                <Icon fontSize="large" style={{ color: 'darkgreen' }}>
                  check_circle
                </Icon>
              </div>
            </DialogTitle>
            <DialogContent className="text-center py-10">
              <DialogContentText>
                {t('forgotPassword.labels.emailSentMessage')}
                <br />
                <strong>{confirmationEmail}</strong>
                <br />
                {t('forgotPassword.labels.resetPasswordLinkMessage')} <br />{' '}
                {t('forgotPassword.labels.resetPasswordMessage')}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  setShowConfirmationDialog(false);
                  history.push('/sign-in');
                }}
                variant="contained"
                color="secondary"
                className="mb-32 w-160"
              >
                {t('forgotPassword.button.okGotIt')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <img
        src="assets/images/background/bottom-round.png"
        className="absolute bottom-0 left-0 hidden lg:block"
        alt="..."
      />
    </div>
  );
};
export default withReducer('forgotPasswordReducer', reducer)(ForgotPasswordPage);
