const getReviewPlatforms = `
 query{
  getReviewPlatforms{
    id 
    data{
      id 
      logo
      name
    }
    status
    message
    cts
    uts
  }
 }
`;

const getBusinessAttributesOnId = `
 query($businessId: Int!, $businessTypeId: Int!){
     getBusinessAttributesOnId(businessId: $businessId, businessTypeId: $businessTypeId){
    data{
      id 
      bAttribute
      isActive
    }
    status
    message
  }
 }
`;

const getBusinessOwnerCustomAttributes = `
 query($businessId: Int!){
  getBusinessOwnerCustomAttributes(businessId: $businessId){
    message
    status
    data{
      id
      cts
      isActive
      bAttribute
      businessAttributeValueId
    }
    googleAttributesData{
      id
      cts
      isActive
      bAttribute
      businessAttributeValueId
      isGoogle
    }
  }
 }
`;

const getUserBusinessDetail = `
 query($businessId: Int){
     getUserBusinessDetail(businessId: $businessId){
     status
     message
     data {
      cts
       isOnlineBusiness
       id
       isNegativeReviewRedirect
       isNegativeReviewsPrevention
       isPackageBasedDefaultBusiness
       isGoogleVerified
       businessRemoveStatus {
        isDelete
        deactivatedCts
       }
       name
       city
       address
       address2
       states{
         id
         name
       }
       countries{
         id
         name
       }
       zipCode
       profileURL
       phoneNumber {
         countryCode
         phoneNumber
         isoCode
       }
       email
       website
       reviewPlatformId
       reviewModeTypeId
       businessTypeId
       isAutoThankYouReply
       isQRCodeOrdered
       business_types {
         id
         bType
         isActive
       }
       businessQRId
       userId
       user_business_review_platforms {
         id
         url
         placeId
         reviewPlatformId
       }
     } 
  }
 }
`;

const getBusinessAttributeValue = `
query {
      getBusinessAttributeValue{
        message
        status
        data{
          id
          type
          reaction  
        }
    
		}
	}
`;

const getBusinessServicesAndProductData = `
 query($businessId: Int!, $type: String, $isActive: Boolean){
  getBusinessServicesAndProductData(businessId: $businessId, type: $type, isActive: $isActive){
    data{
      id 
      businessId
      name
      isActive
      specialty
      type
    }
    status
    message
  }
 }
`;

const getReviewPlatfromModeType = `
 query($reviewPlatformId: Int, $isActive: Boolean, $sortOrder: OrderDirection, $sortBy: String){
  getReviewPlatfromModeType(reviewPlatformId: $reviewPlatformId, isActive: $isActive, sortOrder: $sortOrder, sortBy: $sortBy){
    data{
      id
      image
      name
    }
    status
    message
  }
 }
`;

const getBusinessAgentList = `
 query($businessId: Int!){
  getBusinessAgentList (businessId: $businessId){
    data{
      id
      role
      name
      isBusinessQr
      businessQRId
    }
    status
    message
  }
 }
`;

const getAgentSupportStatus = `
 query($businessId: Int!){
  getAgentSupportStatus (businessId: $businessId){
    data{
      agentSupport
    }
    status
    message
  }
 }
`;

const getAmazonProduct = `
 query($userBusinessId: Int!, $amazonProductId: Int, $pageNumber: Int, $pageSize: Int){
  getAmazonProduct (userBusinessId: $userBusinessId, amazonProductId: $amazonProductId, pageNumber: $pageNumber, pageSize: $pageSize){
    data{
      id
      userId
      userBusinessId
      profileUrl
      title
      productUrl
      totalReviews
      rating
      isActive
      reviewPlatformId
      placeId
      businessQRRedirectUrl
      genericName
      scrapingStatus
      reviewModeTypeId
      businessTypeId
      isAutoTextGenerate
      isNegativeReviewRedirect
    }
      totalCount
    status
    message
  }
 }
`;

const getFlipkartProduct = `
 query($userBusinessId: Int!, $flipkartProductId: Int, $pageNumber: Int, $pageSize: Int){
  getFlipkartProduct (userBusinessId: $userBusinessId, flipkartProductId: $flipkartProductId, pageNumber: $pageNumber, pageSize: $pageSize){
    data{
      businessQRRedirectUrl
      businessTypeId
      genericName
      id
      isActive
      isAutoTextGenerate
      isNegativeReviewRedirect
      placeId
      productQrId
      productUrl
      profileUrl
      rating
      reviewModeTypeId
      reviewPlatformId
      scrapingStatus
      title
      totalReviews
      userBusinessId
      userId
    }
    totalCount
    status
    message
  }
 }
`;

const searchYelpBusiness = `
query ($location: String!, $search: String, $pageNumber: Int, $pageSize: Int) {
  searchYelpBusiness(
    location: $location
    search: $search
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    data {
      alias
      categories {
        alias
        title
      }
      coordinates {
        latitude
        longitude
      }
      location {
        address1
        address2
        address3
        city
        country
        display_address
        state
        zip_code
      }
      display_phone
      distance
      id
      image_url
      is_closed
      name
      phone
      price
      rating
      review_count
      transactions
      url
    }
    message
    status
  }
}
`;

const validateZomatoUrl = `
mutation ($url: String!){
  validateZomatoUrl(url: $url){
    status
    message
    data {
      res_id
      name
      address
      city_name
      country_name
      business_image_url
      business_url
      overall_ratings {
        DINING {
          reviewCount
          rating
          rating_type
        }
        DELIVERY {
           reviewCount
          rating
          rating_type
        }
      }
      aggregate_rating {
        aggregate_rating
      }
    }
  }
}
`;

const addBusinessDetailV2 = `mutation AddBusinessDetailV2($businessDetail: BusinessDetailV2!, $isQuickHubProduct:Boolean, $isAddedByGoogleConnect: Boolean, $isClient: Boolean, $userId: Int, $googleAccount: String, $googleToken: String, $userPackageSubscriptionId: Int) {
  addBusinessDetailV2(businessDetail: $businessDetail, isClient: $isClient, isQuickHubProduct: $isQuickHubProduct, isAddedByGoogleConnect: $isAddedByGoogleConnect, userId: $userId, googleAccount: $googleAccount, googleToken: $googleToken, userPackageSubscriptionId:$userPackageSubscriptionId) {
    status
    message
  }
}
`;

const updateBusinessDetail = `mutation updateBusinessDetail($businessDetail: BusinessDetail!) {
  updateBusinessDetail(businessDetail: $businessDetail) {
    status
    message
  }
}
`;

const addCustomBusinessAttribute = `
mutation addCustomBusinessAttribute($bAttribute: String! , $businessId:Int! , $businessAttributeValueId:Int! , $isActive:Boolean!){
  addCustomBusinessAttribute(bAttribute: $bAttribute, businessId: $businessId, businessAttributeValueId: $businessAttributeValueId, isActive: $isActive){
    status
    message
  }
}
`;

const editCustomBusinessAttribute = `
mutation editCustomBusinessAttribute($businessAttributeValueId: Int!, $businessId:Int!, $userCustomBusinessAttributeId:Int!, $bAttribute: String!){
  editCustomBusinessAttribute(businessAttributeValueId: $businessAttributeValueId, businessId: $businessId, userCustomBusinessAttributeId: $userCustomBusinessAttributeId, bAttribute: $bAttribute){
    status
    message
  }
}
`;

const updateCustomBusinessAttributeWithDefaultBusinessAttribute = `mutation updateCustomBusinessAttributeWithDefaultBusinessAttribute($defaultAttribute: [DefaultAttributeArgData!]!, $customBusinessAttribute: [CustomBusinessAttributeInput!]!, $businessId : Int!){
  updateCustomBusinessAttributeWithDefaultBusinessAttribute(defaultAttribute: $defaultAttribute, customBusinessAttribute: $customBusinessAttribute, businessId: $businessId){
    status
    message
  }
}`;

const addBusinessServicesAndProductDataV1 = `mutation addBusinessServicesAndProductDataV1($businessId:Int!,$type:String!
  $name:String!,$specialty:Boolean!
) {
addBusinessServicesAndProductDataV1(businessId: $businessId, type: $type, name: $name, specialty: $specialty){
  message
  status
  }
}`;

const editBusinessServicesAndProductData = `mutation editBusinessServicesAndProductData($userBusinessServiceAndProductId:Int!,
  $name:String,$businessId:Int,$specialty:Boolean
) {
editBusinessServicesAndProductData(userBusinessServiceAndProductId: $userBusinessServiceAndProductId,
name: $name,businessId: $businessId,specialty: $specialty){
  message
  status
  }
}`;

const removeBusinessProductsServices = `mutation removeBusinessServicesAndProductData($userBusinessServiceAndProductId: [Int!]!){
  removeBusinessServicesAndProductData(userBusinessServiceAndProductId: $userBusinessServiceAndProductId){
    message
    status
  }
}
`;

const updateUserBusinessesPlatformModeTypeV2 = `mutation  updateUserBusinessesPlatfromModeType($userBusinessId: Int!, $reviewModeTypeId: Int!){
  updateUserBusinessesPlatfromModeType(userBusinessId: $userBusinessId, reviewModeTypeId: $reviewModeTypeId) {
    status
    message
  }
}
`;

const addBusinessAgent = `mutation addBusinessAgent($businessId: Int!,$name: String!,$role: String) {
  addBusinessAgent(businessId:$businessId,name:$name,role:$role){
    message
    status
  }
}
`;

const updateBusinessAgent = `mutation updateBusinessAgent($businessAgentId: Int!,$name: String,$role: String) {
  updateBusinessAgent(businessAgentId:$businessAgentId,name:$name,role:$role){
    message
    status
  }
}
`;

const deleteBusinessAgent = `mutation deleteBusinessAgent($businessAgentId: Int!) {
  deleteBusinessAgent(businessAgentId:$businessAgentId){
    message
    status
  }
}
`;

const setAgentSupportStatus = `mutation setAgentSupportStatus($businessId: Int!,$isActive: Boolean!) {
  setAgentSupportStatus(businessId:$businessId,isActive:$isActive){
    message
    status
  }
}
`;

const addAmazonProductV1 = `mutation addAmazonProductV1($productTypeId: Int, $url: String!, $userBusinessId: Int!){
  addAmazonProductV1(productTypeId: $productTypeId, url: $url, userBusinessId: $userBusinessId){
    message
    status
  }
}
`;

const deleteAmazonProduct = `mutation deleteAmazonProduct($amazonProductId: Int!){
  deleteAmazonProduct(amazonProductId: $amazonProductId){
    message
    status
  }
}`;

const deleteFlipkartProduct = `mutation deleteFlipkartProduct($flipkartProductId: Int!, $userBusinessId: Int!){
  deleteFlipkartProduct(flipkartProductId: $flipkartProductId, userBusinessId: $userBusinessId){
    message
    status
  }
}`;

const addFlipkartProductV1 = `mutation addFlipkartProductV1($productTypeId: Int, $url: String!, $userBusinessId: Int!){
  addFlipkartProductV1(productTypeId: $productTypeId, url: $url, userBusinessId: $userBusinessId){
    message
    status
  }
}
`;

const fetchAmazonApiData = `query fetchAmazonApiData($url: String!) {
  fetchAmazonApiData(url: $url){
    message
    status
    data{
      product_url
      rating
      product_asin
      total_reviews
      title
      profile_url
    }
  }
}`;

const fetchFlipkartProduct = `query fetchFlipkartProduct($url: String!) {
  fetchFlipkartProduct(url: $url){
    message
    status
    data{
      product_url
      review_url
      generic_name
      product_id
      rating
      title
      total_reviews
      product_image
    }
  }
}`;

const removeBusinessPlatform = `
mutation removeBusinessPlatform($useBusinessId: Int!, $userBusinessPlatformId: Int!){
  removeBusinessPlatform(useBusinessId: $useBusinessId, userBusinessPlatformId: $userBusinessPlatformId){
    message
    status
  }
}
`;

const getNegativeReview = `query ($userBusinessId: Int, $negativeReviewId: Int, $status: String, $amazonProductId: Int, $flipkartProductId: Int){
  getNegativeReview(userBusinessId: $userBusinessId,negativeReviewId: $negativeReviewId,status: $status, amazonProductId: $amazonProductId, flipkartProductId: $flipkartProductId){
    message
    status
    data{
      name
      countryCode
      phoneNumber
      reason
      cts
      status
      businessUserNote
      autoGeneratedReview
    }
  }
}
`;

const getNegativeReviewsBaseOnPrevention = `
 query getNegativeReviewsBaseOnPrevention($businessId: Int!){
  getNegativeReviewsBaseOnPrevention(businessId: $businessId){
    data {
      rating
      review
      cts
      phoneNumber{
        countryCode
        isoCode,
        phoneNumber
      }
    }
    message
    status
  }
 }
`;

const getNegativeReviewRedirectStatus = `query getNegativeReviewRedirectStatus($userBusinessId: Int, $flipkartProductId: Int, $amazonProductId: Int) {
  getNegativeReviewRedirectStatus(userBusinessId: $userBusinessId, flipkartProductId: $flipkartProductId, amazonProductId: $amazonProductId) {
    message
    status
    data{
      id
      cts
      uts
      businessId
      name
      phoneNumber
      countryCode
      reason
      status
      businessUserNote
      autoGeneratedReview
    }
  }
}
`;

const negativeReviewUpdate = `mutation negativeReviewUpdate($negativeReviewId: Int!, $status: String!, $businessUserNote: String) {
  negativeReviewUpdate(negativeReviewId: $negativeReviewId,status: $status,businessUserNote: $businessUserNote){
    message
    status
  }
}
`;
const setNegativeReviewRedirect = `
mutation setNegativeReviewRedirect($userBusinessId: Int!, $isActive: Boolean! $negativeReviewsPrevention: Boolean) {
  setNegativeReviewRedirect(userBusinessId: $userBusinessId, isActive: $isActive, negativeReviewsPrevention: $negativeReviewsPrevention) {
    status
    message
  }
}
`;

const getBusinessQRIdBaseOnBusinessId = `query getBusinessQRIdBaseOnBusinessId($businessId: Int!) {
  getBusinessQRIdBaseOnBusinessId(
  businessId: $businessId) {
  message
  status
  data{
  id
  businessQRId
  }
  }
  }`;

const addBusinessQRIdToBusinessAgent = `mutation addBusinessQRIdToBusinessAgent($agentId:Int!,$businessQRId: Int!){
    addBusinessQRIdToBusinessAgent(agentId: $agentId,businessQRId: $businessQRId ){
    status
    message
  }
}
`;

const deleteQRCode = `mutation deleteQRCode($agentId: Int!){
  deleteQRCode(agentId: $agentId){
  status
  message
  }
}`;

const businessPlatformPlanValidation = `query businessPlatformPlanValidation($businessId: Int!){
  businessPlatformPlanValidation(businessId: $businessId) {
    data {
      availableCount
      usedCount
    }
    message
    status
  }
}
`;

const getUserBusinessesPackageUsage = `query($businessId: Int!) {
  getUserBusinessesPackageUsage(businessId:$businessId){
    status
    message
    data {
      userId
      dataUsage 
    }
  }
}
`;

const getBusinessAutoTextGenerateStatus = `query getBusinessAutoTextGenerateStatus($businessId: Int!){
  getBusinessAutoTextGenerateStatus(businessId: $businessId){
    status
    message
    data{
      isAutoTextGenerate
    }
  }
}`;

const setBusinessAutoTextGenerate = `mutation setBusinessAutoTextGenerate($businessId: Int! $isActive: Boolean) {
  setBusinessAutoTextGenerate(businessId: $businessId, isActive:$isActive){
     message
     status
  }
}`;

const getAutoReviewComplexityType = `query{
  getAutoReviewComplexityType(isActive:true){
    message
    status
    data{
      id
      cts
      isActive
      typeName
    }
  }
}
`;

const setReviewComplexityType = `mutation setReviewComplexityType($businessId:Int!,$typeId:Int!){
  setReviewComplexityType(
    businessId:$businessId
    typeId:$typeId
  ){
    status
    message
  }
}
`;

const setAmazonAndFlipkartReviewComplexityType = `mutation setAmazonAndFlipkartReviewComplexityType($productId: Int!,$type: String!, $typeId: Int) {
  setAmazonAndFlipkartReviewComplexityType(productId:$productId,type:$type, typeId:$typeId){
    message
    status
  }
}`;

const getSelectedReviewComplexityType = `query getSelectedReviewComplexityType($businessId: Int!){
  getSelectedReviewComplexityType(businessId: $businessId){
    status
    message
    data{
      id
      autoGenerateReviewComplexityTypeId
    }
  }
}`;

const getAmazonAndFlipkartSelectedReviewComplexityType = `query getAmazonAndFlipkartSelectedReviewComplexityType($productId: Int!,$type: String!, $typeId: Int){
  getAmazonAndFlipkartSelectedReviewComplexityType(productId:$productId,type:$type, typeId:$typeId){
    data {
      id
      autoGenerateReviewComplexityTypeId
    }
    message
    status
  }
}`;

const getContactFromBusinessReview = `query getContactFromBusinessReview($userBusinessId: Int!, $pageNumber: Int, $pageSize: Int, $isNewest: Boolean){
  getContactFromBusinessReview(userBusinessId: $userBusinessId, pageNumber: $pageNumber, pageSize: $pageSize, isNewest: $isNewest){
    status
    message
    totalCount
    data {
      id
      countryCode
      cts
      phoneNumber
    }
  }
}`;

const getBusinessAutoThanksHistory = `query getBusinessAutoThanksHistory($businessId: Int!,$pageNumber: Int, $pageSize: Int) {
  getBusinessAutoThanksHistory(businessId: $businessId,pageNumber: $pageNumber, pageSize: $pageSize,) {
    data {
      phoneNumber {
        phoneNumber
        countryCode
      }
      messagesStatus
      cts
    }
      totalCount
    message
    status
  }
}
`;

const setBusinessAutoThankSupport = `mutation setBusinessAutoThankSupport($businessId: Int!, $isAutoThankYouReply: Boolean!, $defaultAutoReplyStandardMessageId: Int) {
  setBusinessAutoThankSupport(businessId: $businessId, isAutoThankYouReply: $isAutoThankYouReply, defaultAutoReplyStandardMessageId: $defaultAutoReplyStandardMessageId) {
    message
    status
  }
}
`;

const getAutoThankYouStandardMessages = `query getAutoThankYouStandardMessages($pageNumber: Int, $pageSize: Int) {
  getAutoThankYouStandardMessages(pageNumber: $pageNumber, pageSize: $pageSize) {
    status
    message
    data {
      id
      messages
    }
  }
}
`;

const getAutoThankYouDefaultMessages = `query getAutoThankYouDefaultMessages($businessId: Int!) {
  getAutoThankYouDefaultMessages(businessId: $businessId) {
    status
    message
    data {
      messages
    }
  }
}
`;

const getInvitationRequestedAndAcceptedRequestUserData = `query getInvitationRequestedAndAcceptedRequestUserData($status: BusinessUserRequestEnum!, $userBusinessId: Int!, $pageSize: Int, $pageNumber: Int ){
  getInvitationRequestedAndAcceptedRequestUserData(status: $status, userBusinessId: $userBusinessId, pageSize: $pageSize, pageNumber: $pageNumber){
  totalCount   
  message
      status
      data{
        id
        userId
        firstName
        lastName
        profile
        phoneNumber
        email
      }
    }
   }`;

const searchUserOnEmailAndPhoneNumber = `query searchUserOnEmailAndPhoneNumber($email: String, $businessId: Int!, $phoneNumberDetails: SearchPhoneNumber){
  searchUserOnEmailAndPhoneNumber(email: $email, businessId: $businessId, phoneNumberDetails: $phoneNumberDetails) {
    message
    status
    data {
      id
      firstName
      lastName
      profileUrl
      email
      phoneNumber{
        countryCode
        isoCode
        phoneNumber
      }
    }
  }
}`;

const getAttributeLevelThreshold = `query getAttributeLevelThreshold($amazonProductId: Int, $face: String, $flipkartProductId: Int, $star: Int, $thumbs: String, $userBusinessId: Int){
  getAttributeLevelThreshold(amazonProductId: $amazonProductId, face: $face, flipkartProductId: $flipkartProductId, star: $star, thumbs: $thumbs, userBusinessId: $userBusinessId) {
    message
    status
    cts
    data {
      amazonProductId
      face
      flipkartProductId
      star
      thumbs
      userBusinessId
    }
    id
    message
    status
    uts
  }
}`;

const updateAttributeLevelThreshold = `mutation updateAttributeLevelThreshold($amazonProductId: Int, $face: String, $flipkartProductId: Int, $star: Int, $thumbs: String, $userBusinessId: Int) {
  updateAttributeLevelThreshold(amazonProductId: $amazonProductId,face: $face,flipkartProductId: $flipkartProductId, star: $star, thumbs: $thumbs,userBusinessId: $userBusinessId){
    message
    status
  }
}
`;

const invitedUserToJoinBusinessServices = `mutation invitedUserToJoinBusinessServices($invitationData: [invitationData!]){
  invitedUserToJoinBusinessServices(invitationData: $invitationData) {
    status
    message
  }
}`;

const cancelRequestedInvitationAndAcceptedInvitation = `mutation cancelRequestedInvitationAndAcceptedInvitation($status: BusinessUserRequestEnum!, $userBusinessRoleMappingId: Int!){
  cancelRequestedInvitationAndAcceptedInvitation(status: $status, userBusinessRoleMappingId: $userBusinessRoleMappingId){
    status
    message
    
  }
}
`;

const removeUserBusinessDetail = `mutation removeUserBusinessDetail($businessId: Int!){
  removeUserBusinessDetail(businessId: $businessId){
    message
    status
  }
}`;

const getUserRegisteredGoogleBusiness = `query($authorizationCode: String!, $businessId: Int, $isAddedByGoogleConnect: Boolean){
  getUserRegisteredGoogleBusiness(authorizationCode: $authorizationCode, businessId:$businessId, isAddedByGoogleConnect: $isAddedByGoogleConnect)
  { 
    data{
        businessAddress
        businessName
        businessPlaceId
        locationId
        accountId
        isAvailable
        googleAccount
        googleToken
        isAvailable
    }
    message
    status
  }
}
`;

const getGridPoints = `query ($latitude: Float!, $longitude: Float!, $radius: Int, $gridSize: Int){
  getGridPoints(latitude: $latitude, longitude: $longitude, radius: $radius, gridSize: $gridSize) {
    status
    message
    data
  }
}
`;

const getRankApi = `query ($latitude: Float!, $longitude: Float!, $userBusinessId: Int!, $placeId: String!, $reviewPlatformId: Int!, $keyword: String!, $radius: Int, $gridSize: Int)  {
  getRankApi(
    latitude: $latitude,
    longitude:  $longitude,
    userBusinessId: $userBusinessId,
    placeId: $placeId,
    reviewPlatformId: $reviewPlatformId,
    keyword: $keyword,
    radius: $radius,
    gridSize: $gridSize
  ) {
    status
    message
    data {
      self_rank
      result {
        address
        name
        place_id
        rating
        total_reviews
        map_url
        is_self_listing
        hit_url
        primary_category
        lat
        long
        z
        distance_of_point_from_center
        rank
        avg_rank
      }
    }
  }
}
`;

const getRankApiKeyword = `query ($businessId: Int!){
  getRankApiKeyword(businessId: $businessId) {
    status
    message
    data
  }
}`;

const getUserBusinessRanker = `
query ($userBusinessId: Int!, $reviewPlatformId: Int!, $queryParam: Boolean){
  getUserBusinessRanker(userBusinessId: $userBusinessId,reviewPlatformId: $reviewPlatformId,queryParam: $queryParam){
    message
    status
    data{
      id
      cts
      userBusinessId
      reviewPlatformId
      queryParams{
        radius
        grid_size
        keyword
        latitude
        longitude
      }
      rankerJson{
        z
        lat
        long
        name
        rank
        rating
        address
        hit_url
        map_url
        avg_rank
        place_id
        total_reviews
        is_self_listing
        primary_category
        distance_of_point_from_center
      }
    }
  }
}`;

const getGoogleAuthUrl = `query{
  getGoogleAuthUrl{
    data{
      redirectUrl
    }
    message
    status
  }
}`;

const updateAmazonProductScreenMode = `mutation updateAmazonProductScreenMode($userBusinessId: Int!, $amazonProductId: Int!, $reviewModeTypeId: Int!){
updateAmazonProductScreenMode(userBusinessId: $userBusinessId, amazonProductId: $amazonProductId, reviewModeTypeId: $reviewModeTypeId){
 message
    status
}
}`;

const updateFlipkartProductScreenMode = `mutation updateFlipkartProductScreenMode($userBusinessId: Int!, $flipkartProductId: Int!, $reviewModeTypeId: Int!){
  updateFlipkartProductScreenMode(userBusinessId: $userBusinessId, flipkartProductId: $flipkartProductId, reviewModeTypeId: $reviewModeTypeId){
   message
      status
  }
  }`;

const setAmazonAndFlipkartAutoTextGenerate = `mutation setAmazonAndFlipkartAutoTextGenerate($isActive: Boolean!, $flipkartProductId: Int, $amazonProductId: Int){
  setAmazonAndFlipkartAutoTextGenerate(isActive: $isActive, flipkartProductId: $flipkartProductId, amazonProductId: $amazonProductId){
   message
      status
  }
  }`;

const getAmazonCustomAttributes = `query getAmazonCustomAttributes($amazonProductId: Int!){
  getAmazonCustomAttributes(amazonProductId: $amazonProductId){
  data {
  id
  bAttribute
  businessAttributeValueId
  isActive
  }
   message
    status
  }
  }`;

const getFlipkartCustomAttributes = `query getFlipkartCustomAttributes($flipkartProductId: Int!, $userBusinessId: Int!){
  getFlipkartCustomAttributes(flipkartProductId: $flipkartProductId, userBusinessId: $userBusinessId){
  data {
  id
  bAttribute
  businessAttributeValueId
  isActive
  }
   message
    status
  }
  }`;

const getAmazonProductAttributesOnId = `query getAmazonProductAttributesOnId($amazonProductId: Int, $businessTypeId: Int!){
  getAmazonProductAttributesOnId(amazonProductId: $amazonProductId, businessTypeId: $businessTypeId){
  data {
  bAttribute
  isActive
  id
  }
   message
    status
  }
  }`;

const getFlipkartProductAttributesOnId = `query getFlipkartProductAttributesOnId($flipkartProductId: Int, $businessTypeId: Int!){
    getFlipkartProductAttributesOnId(flipkartProductId: $flipkartProductId, businessTypeId: $businessTypeId){
    data {
    bAttribute
    isActive
    id
    }
     message
      status
    }
    }`;

const updateAmazonAttributeWithDefaultBusinessAttribute = `mutation updateAmazonAttributeWithDefaultBusinessAttribute($amazonProductId: Int, $customBusinessAttribute: [CustomBusinessAttributeInput!]!, $defaultAttribute: [DefaultAttributeData!]!){
  updateAmazonAttributeWithDefaultBusinessAttribute(amazonProductId: $amazonProductId, customBusinessAttribute: $customBusinessAttribute, defaultAttribute: $defaultAttribute){
   message
      status
  }
  }`;

const updateFlipkartAttributeWithDefaultBusinessAttribute = `mutation updateFlipkartAttributeWithDefaultBusinessAttribute($flipkartProductId: Int, $customBusinessAttribute: [CustomBusinessAttributeInput!]!, $defaultAttribute: [DefaultAttributeData!]!){
    updateFlipkartAttributeWithDefaultBusinessAttribute(flipkartProductId: $flipkartProductId, customBusinessAttribute: $customBusinessAttribute, defaultAttribute: $defaultAttribute){
     message
        status
    }
    }`;

const editCustomAmazonAttribute = ` mutation editCustomAmazonAttribute($amazonProductId: Int!, $bAttribute: String!, $businessAttributeValueId: Int!, $amazonCustomBusinessAttributeId: Int!){
      editCustomAmazonAttribute(amazonProductId: $amazonProductId, bAttribute: $bAttribute, businessAttributeValueId: $businessAttributeValueId, amazonCustomBusinessAttributeId: $amazonCustomBusinessAttributeId){
        message
        status
      }
    }`;

const editCustomFlipKartAttribute = ` mutation editCustomFlipKartAttribute($flipKartProductId: Int!, $bAttribute: String!, $businessAttributeValueId: Int!, $flipKartCustomBusinessAttributeId: Int!){
      editCustomFlipKartAttribute(flipKartProductId: $flipKartProductId, bAttribute: $bAttribute, businessAttributeValueId: $businessAttributeValueId, flipKartCustomBusinessAttributeId: $flipKartCustomBusinessAttributeId){
        message
        status
      }
    }`;

const addCustomAmazonAttribute = ` mutation addCustomAmazonAttribute($amazonProductId: Int!, $bAttribute: String!, $businessAttributeValueId: Int!, , $isActive: Boolean!){
      addCustomAmazonAttribute(amazonProductId: $amazonProductId, bAttribute: $bAttribute, businessAttributeValueId: $businessAttributeValueId, , isActive: $isActive){
        message
        status
      }
    }`;

const addCustomFlipKartAttribute = ` mutation addCustomFlipKartAttribute($flipKartProductId: Int!, $bAttribute: String!, $businessAttributeValueId: Int!, $isActive: Boolean!){
      addCustomFlipKartAttribute(flipKartProductId: $flipKartProductId, bAttribute: $bAttribute, businessAttributeValueId: $businessAttributeValueId, isActive: $isActive){
        message
        status
      }
    }`;

const deleteAmazonCustomAttribute = ` mutation deleteAmazonCustomAttribute($amazonCustomBusinessAttributeId: Int!){
      deleteAmazonCustomAttribute(amazonCustomBusinessAttributeId: $amazonCustomBusinessAttributeId){
        message
        status
      }
    }`;
const deleteFlipkartCustomAttribute = ` mutation deleteFlipkartCustomAttribute($flipKartCustomBusinessAttributeId: Int!){
      deleteFlipkartCustomAttribute(flipKartCustomBusinessAttributeId: $flipKartCustomBusinessAttributeId){
        message
        status
      }
    }`;
const setAmazonAndFlipkartNegativeReviewRedirect = ` mutation setAmazonAndFlipkartNegativeReviewRedirect($amazonProductId: Int, $flipkartProductId: Int, $isActive: Boolean!){
      setAmazonAndFlipkartNegativeReviewRedirect(amazonProductId: $amazonProductId, flipkartProductId: $flipkartProductId, isActive: $isActive){
        message
        status
      }
    }`;

const connectMyBusiness = `mutation connectMyBusiness(
  $placeId: String!, 
  $userBusinessId: Int,
  $locationId: String!,
  $accountId: String!){
  connectMyBusiness(
  placeId: $placeId, 
  userBusinessId: $userBusinessId,
  locationId: $locationId,
  accountId: $accountId) {
    message
    status
  }
}`;

const getUserBusinessRankerHistory = `query ($pageNumber: Int, $pageSize: Int, $userBusinessId: Int!){
  getUserBusinessRankerHistory(pageNumber: $pageNumber, pageSize: $pageSize, userBusinessId: $userBusinessId) {
    message
    status
    totalCount
    data {
      id
      cts
      userBusinessId
      reviewPlatformId
      queryParams{
      grid_size
      keyword
      latitude
      longitude
      radius
      }
      rankerJson{
        address
        avg_rank
        z
        lat
        long
        name
        rank
        rating
        address
        hit_url
        map_url
        avg_rank
        place_id
        total_reviews
        is_self_listing
        primary_category
        distance_of_point_from_center
      }
      rankHistory {
        points {
          result {
            z
            lat
            long
            name
            rank
            rating
            address
            hit_url
            map_url
            avg_rank
            place_id
            total_reviews
            is_self_listing
            primary_category
            distance_of_point_from_center
          }
          self_rank
        }
      }
    }
  }
}`;

const createFAQQuestions = `mutation ($businessId: Int!, $questionAnsData: GoogleQuestionAnswersData!) {
  createFAQQuestions(
    businessId: $businessId
    questionAnsData: $questionAnsData
  ) {
    status
    message
  }
}
`;

const getFAQQuestions = `query ($locationId : String!){
  getFAQQuestions(locationId: $locationId) {
    status
    message
    data {
      id
      cts
      questionId
      questionText
      authorName
      profilePhotoUrl
      google_faq_answer {
        id
        googleFaqQuestionsId
        uts
        answerId
        answerText
        authorName
        profilePhotoUrl
      }
    }
  }
}`;

const getUserBusinessesGoogleDetails = `query ($businessId: Int){
  getUserBusinessesGoogleDetails(businessId: $businessId){
    message
    status
    data
  }
}`;

const ansFAQQuestions = `mutation ($businessId: Int!, $answer: String!, $questionId: Int!){
  ansFAQQuestions(
    businessId: $businessId
    answer: $answer
    questionId: $questionId
  ) {
    status
    message
  }
}`;

const searchTrustPilot = `query ($countryCode: String!, $pageNumber: Int, $pageSize: Int, $searchText: String){
  searchTrustPilot(countryCode: $countryCode, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText){
    data{
      placeId
      displayName
      identifyingName
      categories{
        id
        name
        primary
      }
      address{
        street
        city
        postcode
        country
        countryCode
      }
      countryCode
      rating
      reviewCount
      websiteUrl
      logoUrl
      phoneNumber
      email
    }
    message
    status
  }
}
`;

const removeOrCancelUserBusinessRemoval = `mutation ($businessId: Int!, $cancelBusinessRemoval: Boolean) {
  removeOrCancelUserBusinessRemoval(businessId: $businessId, cancelBusinessRemoval:$cancelBusinessRemoval){
    status
    message
  }
}
`;

const getPaymentHistory = `query ($businessId:Int,$orderBy:String,$pageNumber: Int,$pageSize: Int){
  getPaymentHistory(businessId:$businessId, orderBy:$orderBy,pageNumber:$pageNumber,pageSize:$pageSize){
    message
    status
    data {
      autopay
      cts
      packages {
        id
        name
        packageInterval
      }
      paymentIntentsDescTwo {
        amount
        currency
        payment_method_types
        status
      }
      transactionId
      userId
      user_package_subscription {
        expireDate
        paymentDetailsId
        startDate
      }
    }
  }
}
`;

const getInvoiceHistoryForRazorpay = `query($businessId: Int,
$orderBy: String,
$pageNumber: Int,
$pageSize: Int){
  getInvoiceHistoryForRazorpay(orderBy:$orderBy,pageSize:$pageSize, businessId:$businessId,pageNumber:$pageNumber){
    message
    status
    data{
      status
      startDate
      expireDate
      invoiceRedirectLink
      status
      isActive
      planName
      invoiceAmount
      paymentMethod
      transactionId
      packageInterval
      comboPlanName
    }
  }
}`;

const getPlatformDetails = `query(
  $businessId: Int!,
  $platformId: Int!
  ) {
  getPlatformDetails(businessId:$businessId , platformId:$platformId){
    status
    message
    data{
      totalReview
      totalReviewRatings
      platformDetails {
        logo
        name
      }
    }
  }
}`;

const getAppSetupCard = `query($businessId: Int!,$type: String!) {
  getAppSetupCard(businessId:$businessId,type:$type) {
  status
  message
  pendingCount
  totalCount
    data{
    id
  isAdded
  cardName
  videoUrl
  buttonAction
  buttonColor
  backgroundColor
  isAdded
  title
    }
  }
}`;

const updateGoogleBusinessDetails = `mutation ($businessId: Int!, $domain: String, $mask: String!,
$openingDate: GoogleDateArgs, $pageNumber: Int, $pageSize: Int, $periods: [GoogleOpeningHoursPeriodArgs!], $phoneNumbers: GooglePhoneNumberArgs, $websiteUri: String){
updateGoogleBusinessDetails (
  businessId: $businessId,
  domain: $domain,
  mask: $mask,
  openingDate: $openingDate,
  pageNumber: $pageNumber,
  pageSize: $pageSize,
  periods: $periods,
  phoneNumbers: $phoneNumbers,
  websiteUri: $websiteUri
) {
  status
  message
}
}`;

const addGoogleBusinessPhotosAndVideos = `mutation addGoogleBusinessPhotosAndVideos($userBusinessId: Int!, $file: Upload, $mediaFormat: String!,$category:String, $mediaId: String) {
  addGoogleBusinessPhotosAndVideos(
    userBusinessId: $userBusinessId
    file: $file
    mediaFormat: $mediaFormat
    category: $category
    mediaId: $mediaId
  ) {
    message
    status
  }
}`;

const deleteGoogleBusinessPhotosAndVideos = `mutation ($mediaId: String, $userBusinessId: Int, $mediaFormat: String) {
  deleteGoogleBusinessPhotosAndVideos(
    mediaId: $mediaId,
    userBusinessId: $userBusinessId,
    mediaFormat: $mediaFormat
  ) {
    message
    status
  }
}`;

const createGooglePostByType = `mutation createGooglePostByType($postData: CreateOrUpdateGooglePostByTypeDto!, $image: Upload) {
  createGooglePostByType(postData: $postData, image: $image) {
    status
    message
  }
}`;

const deleteGooglePostByType = `mutation ($postType: String, $postName: String){
  deleteGooglePostByType(postType: $postType, postName:$postName
  userBusinessesGoogleDetailId:1){
    message
    status
    
  }
}`;

const updateGooglePostByType = `mutation updateGooglePostByType($postData: CreateOrUpdateGooglePostByTypeDto!, $postName: String!) {
  updateGooglePostByType(postData: $postData, postName: $postName) {
    status
    message
  }
}`;

const getGoogleBusinessPosts = `query ($userBusinessesGoogleDetailId: Int!) {
  getGoogleBusinessPosts(userBusinessesGoogleDetailId: $userBusinessesGoogleDetailId) {
    data {
      id
      callToActionPost
      eventPost
      offerPost
    }
    message
    status
  }
}`;

const getGoogleBusinessPhotosVideos = `query ($businessId : Int!) {
  getGoogleBusinessPhotosVideos(businessId: $businessId){
    message
    status
    data{
      googlePhotos
      googleVideo
    }
  }
}`;

const updateOrDeleteGoogleAttributes = `mutation ($businessId: Int, $data: [UpdateGoogleAttributesArgsData!], $domain: String, $removeAttribute: [String!]) {
  updateOrDeleteGoogleAttributes(
    businessId: $businessId,
    data: $data,
    domain: $domain,
    removeAttribute: $removeAttribute
  ) {
    message
    status
  }
}`;

const getServicesSuggestionsForGoogle = `query ($businessId: Int, $pageNumber: Int, $pageSize: Int){
  getServicesSuggestionsForGoogle(businessId: $businessId, pageNumber: $pageNumber, pageSize:$pageSize) {
  status
  message
  data{
   serviceTypeId
   displayName
   price {
   currencyCode
    units
  }
   description
 }
}
}`;

const getActiveGoogleServices = `query ($businessId:Int, $pageNumber: Int, $pageSize: Int){
  getActiveGoogleServices(businessId: $businessId, pageNumber: $pageNumber, pageSize:$pageSize) {
    status
    message
    data {
      defaultServices {
        serviceTypeId
        displayName
        description
        price {
          currencyCode
          units
        }
      }
      customServices {
        serviceTypeId
        displayName
        description
        price {
          currencyCode
          units
        }
      }
    }
  }
}`;

const addAndDeleteServicesInGoogle = `mutation ($businessId: Int, $data: GoogleServiceUpdatePayload!, $locationId: String!){
    addAndDeleteServicesInGoogle(
    businessId: $businessId,
    data: $data,
    locationId: $locationId,
    ){
    status
    message
    }
  }`;

const manageBusinessData = {
  getReviewPlatforms,
  getBusinessAttributesOnId,
  getBusinessOwnerCustomAttributes,
  getUserBusinessDetail,
  getBusinessAttributeValue,
  getBusinessServicesAndProductData,
  getReviewPlatfromModeType,
  getBusinessAgentList,
  getAgentSupportStatus,
  getAmazonProduct,
  getFlipkartProduct,
  searchYelpBusiness,
  validateZomatoUrl,
  addBusinessDetailV2,
  updateBusinessDetail,
  addCustomBusinessAttribute,
  editCustomBusinessAttribute,
  updateCustomBusinessAttributeWithDefaultBusinessAttribute,
  addBusinessServicesAndProductDataV1,
  editBusinessServicesAndProductData,
  removeBusinessProductsServices,
  updateUserBusinessesPlatformModeTypeV2,
  addBusinessAgent,
  setAgentSupportStatus,
  updateBusinessAgent,
  deleteBusinessAgent,
  addAmazonProductV1,
  deleteAmazonProduct,
  deleteFlipkartProduct,
  addFlipkartProductV1,
  fetchAmazonApiData,
  fetchFlipkartProduct,
  removeBusinessPlatform,
  getNegativeReview,
  getNegativeReviewsBaseOnPrevention,
  getNegativeReviewRedirectStatus,
  negativeReviewUpdate,
  setNegativeReviewRedirect,
  getBusinessQRIdBaseOnBusinessId,
  addBusinessQRIdToBusinessAgent,
  deleteQRCode,
  businessPlatformPlanValidation,
  getUserBusinessesPackageUsage,
  getBusinessAutoTextGenerateStatus,
  setBusinessAutoTextGenerate,
  getAutoReviewComplexityType,
  setReviewComplexityType,
  setAmazonAndFlipkartReviewComplexityType,
  getSelectedReviewComplexityType,
  getAmazonAndFlipkartSelectedReviewComplexityType,
  getContactFromBusinessReview,
  getBusinessAutoThanksHistory,
  setBusinessAutoThankSupport,
  getAutoThankYouStandardMessages,
  getAutoThankYouDefaultMessages,
  getInvitationRequestedAndAcceptedRequestUserData,
  searchUserOnEmailAndPhoneNumber,
  getAttributeLevelThreshold,
  updateAttributeLevelThreshold,
  invitedUserToJoinBusinessServices,
  cancelRequestedInvitationAndAcceptedInvitation,
  removeUserBusinessDetail,
  getUserRegisteredGoogleBusiness,
  getGridPoints,
  getRankApi,
  getUserBusinessRanker,
  getRankApiKeyword,
  getGoogleAuthUrl,
  updateAmazonProductScreenMode,
  updateFlipkartProductScreenMode,
  setAmazonAndFlipkartAutoTextGenerate,
  getAmazonCustomAttributes,
  getFlipkartCustomAttributes,
  getAmazonProductAttributesOnId,
  getFlipkartProductAttributesOnId,
  updateAmazonAttributeWithDefaultBusinessAttribute,
  updateFlipkartAttributeWithDefaultBusinessAttribute,
  editCustomAmazonAttribute,
  editCustomFlipKartAttribute,
  addCustomAmazonAttribute,
  addCustomFlipKartAttribute,
  deleteAmazonCustomAttribute,
  deleteFlipkartCustomAttribute,
  setAmazonAndFlipkartNegativeReviewRedirect,
  connectMyBusiness,
  getUserBusinessRankerHistory,
  createFAQQuestions,
  getFAQQuestions,
  getUserBusinessesGoogleDetails,
  ansFAQQuestions,
  searchTrustPilot,
  getPaymentHistory,
  getInvoiceHistoryForRazorpay,
  removeOrCancelUserBusinessRemoval,
  getPlatformDetails,
  getAppSetupCard,
  updateGoogleBusinessDetails,
  addGoogleBusinessPhotosAndVideos,
  deleteGoogleBusinessPhotosAndVideos,
  createGooglePostByType,
  deleteGooglePostByType,
  updateGooglePostByType,
  getGoogleBusinessPosts,
  getGoogleBusinessPhotosVideos,
  updateOrDeleteGoogleAttributes,
  getServicesSuggestionsForGoogle,
  getActiveGoogleServices,
  addAndDeleteServicesInGoogle,
};

export default manageBusinessData;
