import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Menu, MenuItem, Tabs, Tab, Button, TablePagination } from '@mui/material';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UserService from 'src/app/services';
import moment from 'moment';
import FuseLoading from '@fuse/core/FuseLoading';
import { t, use } from 'i18next';
import Pagination from 'src/app/component/table/Pagination';
import { handleApiRequest } from '../../../common/common';

const loginUserData = UserService.getUserData();

const MySubscription = () => {
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [getPaymentHistory, setGetPaymentHistory] = useState('');
  const [total, setTotal] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [orderBy] = useState('asc');
  const [pageSize] = useState(20);
  const [pageNumber] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [countryId] = useState(loginUserData.countryId);
  const isOpen = Boolean(anchorEl);
  const [isOpenBundle, setIsOpenBundle] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setIsLoading(true);

        if (countryId === 2) {
          const invoicePayload = {
            query: manageBusinessData.getInvoiceHistoryForRazorpay,
            variables: {
              orderBy,
              pageSize,
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              pageNumber,
            },
          };
          const invoiceResult = await handleApiRequest(invoicePayload);
          if (invoiceResult?.getInvoiceHistoryForRazorpay?.status === 200) {
            setGetPaymentHistory(invoiceResult?.getInvoiceHistoryForRazorpay?.data);
            setTotal(invoiceResult?.getInvoiceHistoryForRazorpay?.data.length);
          }
        } else {
          const paymentHistoryPayload = {
            query: manageBusinessData.getPaymentHistory,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              orderBy,
              pageNumber,
              pageSize,
            },
          };
          const paymentHistoryResult = await handleApiRequest(paymentHistoryPayload);
          if (paymentHistoryResult?.getPaymentHistory?.status === 200) {
            setGetPaymentHistory(paymentHistoryResult?.getPaymentHistory?.data);
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllData();
  }, [countryId, orderBy, pageNumber, pageSize, userCurrentBusinessDetails?.id]);

  const getDaysRemaining = (expireDate) => {
    const now = new Date();
    const expiryDate = new Date(expireDate);
    const timeDiff = expiryDate - now;
    const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return Math.max(0, daysRemaining);
  };

  const handleClickBundle = () => {
    setIsOpenBundle((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      <div className="mb-16">
        <h1 className=" text-30 font-bold">{t('plan.mySubscriptionData.mySubscription')}</h1>
      </div>
      <div className="  border-solid border-grey  flex flex-col justify-between  ">
        <div className=" border-solid border-grey  sm:flex justify-between items-center  border-b-2 ">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              className="sm:text-18 font-bold mr-20 text-black p-0"
              value={0}
              label={t('plan.mySubscriptionData.activePlan')}
            />
            <Tab
              label={t('plan.mySubscriptionData.paymentHistory')}
              className="sm:text-18 font-bold p-0 text-black"
              value={1}
            />
          </Tabs>
        </div>
        <div className="py-20">
          {selectedTab === 0 && (
            <div className="w-full">
              {userSubscriptionData?.map((subscription, index) => (
                <div
                  className={` my-16 justify-between rounded-md bg-white max-w-screen-md flex-col sm:flex-row border-grey-300 border-1 ${
                    isOpenBundle ? 'h-auto' : ''
                  }`}
                  key={index}
                >
                  {subscription?.isFreeTrial && (
                    <p className="w-full h-15 bg-amber-50 text-14 text-grey-700 font-medium pl-5 p-4">
                      {t('plan.mySubscriptionData.freeTrailWillExpireIn')}
                      {getDaysRemaining(subscription.expireDate)}
                      {t('plan.mySubscriptionData.days')}
                    </p>
                  )}
                  <div className="flex justify-between p-14 relative">
                    <div className="gap-10 flex items-center bg-white rounded-md ">
                      <div>
                        {subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                          'Quick Review' && (
                          <img
                            src="/assets/images/quick-hub/quick-review.jpg"
                            alt="Quick Review"
                            className="bg-white"
                          />
                        )}
                        {subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                          'Quick Social' && (
                          <img
                            src="/assets/images/quick-hub/quick-social.jpg"
                            alt="Quick Social"
                            className="bg-white"
                          />
                        )}
                        {subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                          'Quick Chat' && (
                          <img
                            src="/assets/images/quick-hub/quick-chat.jpg"
                            alt="Quick Chat"
                            className="bg-white "
                          />
                        )}
                        {subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                          'Quick Bundle' && (
                          <img
                            src="/assets/images/quick-hub/quick-bundle.png"
                            alt="Quick Bundle"
                            className="bg-white  "
                          />
                        )}
                        {subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                          'Quick Combo' && (
                          <img
                            src="/assets/images/quick-hub/quick-chat.jpg"
                            alt="Quick Combo"
                            className="bg-white"
                          />
                        )}
                        {subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                          'Quick Campaign' && (
                          <img
                            src="/assets/images/quick-hub/quick-campaign.jpg"
                            alt="Quick Combo"
                            className="bg-white"
                          />
                        )}
                      </div>
                      <div>
                        <div className="flex text-left items-center ">
                          <span className="text-left block mb-6 font-semibold text-20 tracking-normal text-black">
                            {subscription.name.replace(/\b\w/g, (char) => char.toUpperCase())}
                          </span>

                          {(subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                            'Quick Bundle' ||
                            subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                              'Quick Combo') && (
                            <div className="ml-2 font-Quick">
                              {(subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                                'Quick Bundle' ||
                                subscription.name.replace(/\b\w/g, (char) => char.toUpperCase()) ===
                                  'Quick Combo') && (
                                <div className=" w-full ">
                                  <IconButton className="p-0" onClick={handleClickBundle}>
                                    {isOpenBundle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                  </IconButton>
                                  {isOpenBundle && (
                                    <div className=" ml-64 left-0 right-14 bg-gray-200  w-100  h-auto z-20 rounded-8 absolute p-5">
                                      {subscription?.comboDetails?.length > 0 ? (
                                        <div>
                                          {subscription.comboDetails.map((item) => (
                                            <div
                                              key={index}
                                              className="p-4 flex font-medium justify-between border-b-1 border-b-grey-300 last:border-b-0 "
                                            >
                                              <p>{item.name}</p>
                                              <p>
                                                {loginUserData?.countryId === 2 ? '₹' : '$'}
                                                {item.offerAmount ? item.offerAmount : item.amount}
                                              </p>
                                            </div>
                                          ))}
                                        </div>
                                      ) : (
                                        <div className=" text-center">
                                          {t('plan.mySubscriptionData.noDataAvailable')}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <p
                          className={`text-left font-medium  ${
                            subscription.isSubscription === false &&
                            subscription.isFreeTrial === 'false'
                              ? 'text-red-500'
                              : 'text-black'
                          }`}
                        >
                          {(!isOpen || subscription.name !== 'Quick Bundle') && (
                            <>
                              {subscription.isFreeTrial === true
                                ? 'Expires on : '
                                : subscription.isSubscription === false
                                ? 'Expired on : '
                                : subscription.isSubscription === true
                                ? 'Next renew on : '
                                : null}
                              {subscription?.expireDate ? (
                                <span>
                                  {moment(subscription.expireDate).format('MMM DD, YYYY')}
                                </span>
                              ) : (
                                'N/A'
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="item-center pt-10">
                      <p className="text-20 justify-around text-right font-medium text-black">
                        {subscription.currencySymbol}
                        {subscription.offerAmount ? subscription.offerAmount : subscription.amount}
                        {t('plan.mySubscriptionData.month')}
                      </p>
                      <div className="px-2 py-8">
                        {!subscription.isSubscription || subscription.isFreeTrial ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            className="text-14 font-medium disabled:text-black rounded-md px-20"
                            aria-label="skip"
                            type="button"
                          >
                            {t('plan.mySubscriptionData.subscribeNow')}
                          </Button>
                        ) : subscription.isSubscription === true ? (
                          <a
                            href="*"
                            className="text-black font-medium text-14 rounded-md px-20"
                            style={{ color: 'black' }}
                          >
                            {t('plan.mySubscriptionData.cancelPlan')}
                          </a>
                        ) : subscription.isOneTimePayment === true &&
                          !subscription.isOneTimePayment ? (
                          <a href="*" className="font-medium text-14 rounded-md px-20">
                            {t('plan.mySubscriptionData.extendPlan')}
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {selectedTab === 1 && (
            <div className="w-full">
              {getPaymentHistory?.length > 0 ? (
                <div className="overflow-y-auto p-5 ">
                  <div>
                    <table className="min-w-full table-auto bg-white border-2 border-black-200 rounded-md ">
                      <thead className=" py-5 px-5 border-2  border-black-200 w-full">
                        <tr className=" p-10 bg-white-100  font-bold text-md  font-quicksand">
                          <th className=" p-12 text-left text-black font-bold text-12 ">
                            {t('plan.mySubscriptionData.transactionId')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-12 ">
                            {t('plan.mySubscriptionData.product')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-12 ">
                            {t('plan.mySubscriptionData.planStartOn')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-12 ">
                            {t('plan.mySubscriptionData.nextRecurringDate')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-12 ">
                            {t('plan.mySubscriptionData.amount')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-12 ">
                            {t('plan.mySubscriptionData.billingCycle')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-12 ">
                            {t('plan.mySubscriptionData.method')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-12">
                            {t('plan.mySubscriptionData.status')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-12 ">
                            {t('plan.mySubscriptionData.autoPay')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-12">
                            {t('plan.mySubscriptionData.invoice')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getPaymentHistory?.map((payment, index) => (
                          <tr key={index} className="border-t text-2">
                            <td className="p-12 text-sm font-medium font-quicksand">
                              {payment.transactionId}
                            </td>
                            <td className=" p-12 text-sm font-medium font-quicksand">
                              {payment.planName}
                              {loginUserData?.countryId === 2
                                ? (payment.planName === 'Quick Bundle' ||
                                    payment.planName === 'Quick Combo') && (
                                    <div className="bg-grey-400">
                                      <IconButton onClick={handleClick}>
                                        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                      </IconButton>
                                      <Menu
                                        anchorEl={anchorEl}
                                        open={isOpen}
                                        onClose={handleClose}
                                        MenuListProps={{
                                          'aria-labelledby': 'basic-button',
                                        }}
                                      >
                                        {payment?.planName.replace(/\b\w/g, (char) =>
                                          char.toUpperCase()
                                        ) === 'Quick Combo' &&
                                          payment?.comboDetails?.map((detail) => (
                                            <MenuItem key={index} onClick={handleClose}>
                                              <div className="justify-around font-medium font-quicksand">
                                                <h4>{detail.name}</h4>
                                              </div>
                                            </MenuItem>
                                          ))}
                                        {payment?.planName.replace(/\b\w/g, (char) =>
                                          char.toUpperCase()
                                        ) === 'Quick Bundle' &&
                                          payment?.comboDetails?.map((detail) => (
                                            <MenuItem key={index} onClick={handleClose}>
                                              <div className="justify-around font-medium font-quicksand">
                                                <h4>{detail.name}</h4>
                                              </div>
                                            </MenuItem>
                                          ))}
                                      </Menu>
                                    </div>
                                  )
                                : (payment.packages?.name === 'Quick Bundle' ||
                                    payment.packages?.name === 'Quick Combo') && (
                                    <>
                                      <IconButton onClick={handleClick}>
                                        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                      </IconButton>
                                      <Menu
                                        anchorEl={anchorEl}
                                        open={isOpen}
                                        onClose={handleClose}
                                        MenuListProps={{
                                          'aria-labelledby': 'basic-button',
                                        }}
                                      >
                                        <MenuItem onClick={handleClose}>
                                          {t('plan.mySubscriptionData.noData')}
                                        </MenuItem>
                                      </Menu>
                                    </>
                                  )}
                            </td>
                            <td className="  p-12 text-sm font-medium font-quicksand">
                              {loginUserData?.countryId === 2
                                ? payment?.startDate
                                  ? moment(payment?.startDate).format('MMM DD, YYYY')
                                  : ''
                                : payment?.startDate
                                ? moment(payment?.startDate).format('MMM DD, YYYY')
                                : ''}
                            </td>
                            <td className="  p-12 text-sm font-medium font-quicksand">
                              {loginUserData?.countryId === 2
                                ? payment?.expireDate
                                  ? moment(payment?.expireDate).format('MMM DD, YYYY')
                                  : ''
                                : payment?.user_package_subscription.expireDate
                                ? moment(payment?.user_package_subscription.expireDates).format(
                                    'MMM DD, YYYY'
                                  )
                                : ''}
                            </td>
                            <td className=" p-12 text-sm font-medium font-quicksand">
                              {loginUserData?.countryId === 2
                                ? `${!payment?.currency ? '₹' : '$'} ${payment?.invoiceAmount}`
                                : `${payment?.paymentIntentsDescTwo?.currency ? '$' : ''} ${
                                    payment?.paymentIntentsDescTwo?.amount || ''
                                  }`}
                            </td>
                            <td className="font-medium text-sm font-quicksand p-12">
                              {loginUserData?.countryId === 2
                                ? payment?.packageInterval
                                  ? payment.packageInterval.replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )
                                  : ''
                                : payment?.packages?.packageInterval
                                ? payment.packages.packageInterval.replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )
                                : ''}
                            </td>
                            <td className=" text-sm font-medium font-quicksand p-12">
                              {loginUserData?.countryId === 2
                                ? payment?.paymentMethod
                                  ? payment.paymentMethod.replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )
                                  : ''
                                : payment?.packages?.packageInterval
                                ? payment.packages.packageInterval.replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )
                                : ''}
                            </td>
                            <td
                              className={` p-12 text-sm font-medium font-quicksand ${
                                payment.status === 'Failed' ? 'text-red-500' : 'text-darkgreen'
                              }`}
                            >
                              {loginUserData?.countryId === 2
                                ? payment?.status === 'Activated'
                                  ? t('plan.mySubscriptionData.failed')
                                  : t('plan.mySubscriptionData.successful')
                                : payment?.paymentIntentsDescTwo?.status === 'Activated'
                                ? t('plan.mySubscriptionData.failed')
                                : t('plan.mySubscriptionData.successful')}
                            </td>
                            <td className=" p-12 text-sm font-medium font-quicksand">
                              {payment.autopay ? 'Yes' : 'No'}
                            </td>
                            <td className="p-12 text-sm font-medium font-quicksand">
                              <a
                                href={payment.invoiceRedirectLink}
                                color="inherit"
                                className=" font-quicksand font-medium"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p className="bg-darkgreen text-white px-10 py-2 rounded-sm inline-block text-center">
                                  {t('plan.mySubscriptionData.downloadReceipt')}
                                </p>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      totalCount={total}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              ) : (
                <h1 className="text-center text-black">
                  {t('plan.mySubscriptionData.noDataAvailable')}
                </h1>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MySubscription;
