import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import history from '@history';
import './MangeBusiness.css';
import reducer from './store';
import { getParamsId } from '../../common/common';

function SelectBusiness() {
  const { t } = useTranslation();

  const redirectBusiness = () => {
    const id = getParamsId();
    if (id) {
      history.push(`/manage-business/${id}`);
    } else {
      history.push('/manage-business');
    }
  };

  return (
    <div className="flex items-center justify-center bg-white h-full w-full sm:px-0 px-16 sm:py-0 py-40">
      <img
        src="assets/images/background/top-round.png"
        className="absolute top-0 right-0 hidden lg:block"
        alt="..."
      />
      <div className="bg-grey-50 sm:max-w-[1000px] w-full mx-auto rounded-2xl">
        <div className="text-center mangeBusinessPage-height flex justify-center items-center overflow-hidden">
          <div>
            <img src="assets/images/icon/success.png" className="mx-auto" alt="..." />
            <h2 className="sm:text-28 text-20 font-bold mt-24 md:mt-48">
              {t('manageBusiness.selectCategory.congratulations')}
            </h2>
            <Typography className="sm:text-18 font-medium mt-16 mb-24 sm:mb-48 max-w-640 mx-auto text-black">
              {t('manageBusiness.selectCategory.con_desc')}
            </Typography>
            <div className="text-center sm:mb-0 mb-28">
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-288"
                aria-label="skip"
                onClick={() => {
                  const id = getParamsId();
                  if (id) {
                    history.push(`/business/dashboard/${id}`);
                  } else {
                    history.push('/business/dashboard');
                  }
                }}
                type="button"
              >
                {t('manageBusiness.button.skip')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className="md:text-16 font-medium disabled:bg-gray disabled:text-black rounded-md sm:min-w-288"
                aria-label="confirm"
                onClick={() => redirectBusiness()}
                type="button"
              >
                {t('manageBusiness.selectCategory.addBusiness')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/images/background/bottom-round.png"
        className="absolute bottom-0 left-0 hidden lg:block"
        alt="..."
      />
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(SelectBusiness);
