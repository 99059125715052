import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { json, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@mui/material/InputBase';
import Avatar from '@mui/material/Avatar';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import axios from 'axios';
import './QuickChatEditor.css';
import { publicIpv4 } from 'public-ip';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from '@mui/material';
import { chatSocket } from '../../../socket';
import quickChatQuery from '../query/quickChat';
import { handleApiRequest } from '../common/common';

function QuickChat(props) {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const chatScroll = useRef(null);
  const [messageText, setMessageText] = useState('');
  const [chatInfo, setChatInfo] = useState({});
  const [messages, setMessages] = useState([]);
  const [getAllMessages, setGetAllMessages] = useState([]);
  const [isBotTyping, setIsBotTyping] = useState(true);
  const [tags, setTags] = useState([]);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [isConnected, setIsConnected] = useState(chatSocket.connected);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const currentTime = moment().format('h:mm A');
  const ipAddress = publicIpv4();
  const [rating, setRating] = useState();
  const [cachedIp, setCachedIp] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [quickChatMessageStatus, setQuickChatMessageStatus] = useState(true);

  console.log('messages', messages);

  useEffect(() => {
    chatScroll.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    try {
      const fetchAgentSupportStatus = async () => {
        const payloadData = {
          query: quickChatQuery.getQuickChat,
          variables: {
            domain: `${routeParams?.location}/${routeParams?.business}`,
          },
        };
        const resultData = await handleApiRequest(payloadData);

        if (resultData?.getQuickChat?.status === 200) {
          setChatInfo(resultData?.getQuickChat?.data);
          if (resultData?.getQuickChat?.data) {
            setTags(JSON?.parse(resultData?.getQuickChat?.data?.quickChatSuggestion));
          }
        }
      };
      fetchAgentSupportStatus();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [routeParams]);

  const initialSetData = useCallback((data) => {
    if (data?.length === 0) {
      setTimeout(() => {
        setMessages([
          {
            message: `${t('quickChat.createBot.defaultMessage')}`,
            messageType: 'sender',
            time: moment().format('h:mm A'),
          },
          {
            message: `${t('quickChat.createBot.defaultMessage2')}`,
            messageType: 'sender',
            time: moment().format('h:mm A'),
          },
        ]);
        setIsBotTyping(false);
        setDisplayMenu(true);
      }, 2000);
    } else {
      setMessages(data);
      setIsBotTyping(false);
      setDisplayMenu(true);
    }
  }, []);

  useEffect(() => {
    const fetchIp = async () => {
      const ip = await ipAddress;
      setCachedIp(ip);
    };

    if (!cachedIp) {
      fetchIp();
    }
  }, [cachedIp, ipAddress]);

  useEffect(() => {
    const fetchAgentSupportStatus = async () => {
      if (chatInfo?.id) {
        const payloadData = {
          query: quickChatQuery.getAllQuickChatRoomMessage,
          variables: {
            ipAddress: cachedIp,
            quickChatId: chatInfo?.id,
          },
        };
        const resultData = await handleApiRequest(payloadData);

        if (resultData?.getAllQuickChatRoomMessage?.status === 200) {
          setGetAllMessages(resultData?.getAllQuickChatRoomMessage?.data);
          initialSetData(resultData?.getAllQuickChatRoomMessage?.data);
        }
      }
    };
    fetchAgentSupportStatus();
  }, [chatInfo?.id, initialSetData, cachedIp]);

  // useEffect(() => {
  //   const socketEvent = async () => {
  //     try {
  //       if (quickChatMessageStatus && isConnected && props?.quickChatId) {
  //         setQuickChatMessageStatus(false);
  //         const messageData = JSON.stringify({ ipAddress: await ipAddress, quickChatId: 339 });
  //         chatSocket.emit('subscribeChannelByClientUser', messageData);
  //       }
  //     } catch (error) {
  //       console.error('An error occurred:', error);
  //     }
  //   };
  //   socketEvent();
  // }, [ipAddress, isConnected, props?.quickChatId, quickChatMessageStatus]);
  useEffect(() => {
    const handleMessage = (message) => {
      console.log('messageinnnnnnn', message);
      if (message?.data?.answer || message?.data?.message) {
        const newMessage = {
          message: message?.data?.answer || message?.data?.message,
          messageType: 'sender',
          time: currentTime,
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        const payload = JSON.stringify({
          quickChatRoomMessagesId: message?.data?.quickChatRoomMessagesId,
          status: 'delivered',
        });
        chatSocket.emit('messageStatusFromClient', payload); // for read and deliver
        setIsBotTyping(false);
      }
    };

    const handleConnect = async () => {
      try {
        const ipAddressValue = '125.369.255';
        const subscribeChatData = JSON.stringify({
          ipAddress: ipAddressValue,
          quickChatId: chatInfo?.id,
        });

        chatSocket.emit('subscribeChannelByClientUser', subscribeChatData);
        setIsConnected(true);
      } catch (error) {
        console.error('Error connecting to chat:', error);
        setIsConnected(false);
      }
    };

    const handleDisconnect = () => {
      setIsConnected(false);
    };

    // Attach event listeners
    chatSocket.on('connect', handleConnect);
    chatSocket.on('messageToClient', handleMessage);
    chatSocket.on('disconnect', handleDisconnect);

    // Cleanup function to remove the event listeners when component unmounts or re-renders
    return () => {
      chatSocket.off('connect', handleConnect);
      chatSocket.off('messageToClient', handleMessage);
      chatSocket.off('disconnect', handleDisconnect);
    };
  }, [chatInfo?.id, currentTime]);

  useEffect(() => {
    const clearLocalStorageOnRefresh = () => {
      localStorage.removeItem('botReplayStatusValue');
    };

    window.addEventListener('beforeunload', clearLocalStorageOnRefresh);

    return () => {
      window.removeEventListener('beforeunload', clearLocalStorageOnRefresh);
    };
  }, []);

  const sendMessage = async (e) => {
    const message = messageText || e;
    const botReplayStatus = message !== 'I would like to talk to someone';

    if (!localStorage.getItem('botReplayStatusValue')) {
      localStorage.setItem('botReplayStatusValue', botReplayStatus);
    }

    if (message?.trim()) {
      const messageData = JSON.stringify({
        message,
        ipAddress: await ipAddress,
        quickChatRoomId: getAllMessages[0]?.quickChatRoomId,
        quickChatId: chatInfo?.id,
        businessId: chatInfo?.userBusinessesId,
        isReplyByBot: localStorage.getItem('botReplayStatusValue'),
      });
      chatSocket.emit('messageFromClient', messageData); // for send message
      const newMessages = [...messages, { message, messageType: 'delivered', time: currentTime }];
      setMessages(newMessages);
      if (botReplayStatus) {
        setIsBotTyping(true);
      }

      setMessageText('');
    }
  };
  const onMessageSubmit = async (e) => {
    e.preventDefault();
    sendMessage();
    setDisplayMenu(false);
  };

  const handleTagClick = async (tag) => {
    setMessageText(tag);
    sendMessage(tag);
    setDisplayMenu(false);
  };

  const closeWindow = () => {
    setOpenDialog(true);
  };

  const groupMessagesByDate = (messagesData) => {
    return messagesData.reduce((groups, message) => {
      const date = moment(message.cts).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const submitRating = async () => {
    try {
      const payload = {
        query: quickChatQuery.saveQuickChatClientRating,
        variables: {
          quickChatId: parseInt(chatInfo?.id, 10),
          ipAddress: cachedIp,
          rating: parseInt(rating, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.saveQuickChatClientRating?.status === 200) {
        setOpenDialog(false);
        setRating();
        window.open('about:blank', '_self');
        window.close();
      }
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleClick = (value) => {
    setRating(value);
  };

  return (
    <>
      {chatInfo && chatInfo !== null ? (
        <>
          {chatInfo?.headerColor && (
            <AppBar
              className={`shadow-md sticky mb-48 bg-[${chatInfo?.headerColor}]`}
              style={{ backgroundColor: chatInfo?.headerColor }}
            >
              <Toolbar className="px-4">
                <div className="flex flex-1 items-center px-12">
                  <Avatar
                    src={chatInfo?.avatar}
                    className="bg-white md:p-10 p-5 md:w-48 md:h-48 w-28 h-28"
                  />
                  <span className="w-10 h-10 bg-green inline-block rounded-full -ml-8 z-10 mt-20" />
                  <Typography className="mx-16 text-16" color="inherit">
                    {chatInfo?.name}
                  </Typography>
                </div>

                <div className="flex px-4">
                  <IconButton onClick={() => closeWindow()} color="inherit" size="large">
                    <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
          )}

          <div className="mb-76 px-12">
            {Object.entries(groupMessagesByDate(messages)).map(([date, messagesValue], index) => {
              return (
                <div key={index}>
                  <div className="text-center">
                    <div className="text-center text-grey-600 text-12 font-medium mb-12 bg-white inline-block mx-auto rounded-md shadow px-10 py-4 font-medium">
                      {moment(date).calendar(null, {
                        sameDay: '[Today]',
                        lastDay: '[Yesterday]',
                        lastWeek: 'dddd',
                        sameElse: 'DD/MM/YYYY',
                      })}
                    </div>
                  </div>
                  {messagesValue?.map((msg, i) => (
                    <div
                      key={i}
                      className={`block max-w-[1050px] relative mx-auto ${
                        msg?.messageType === 'sender' ? 'text-left' : `text-right`
                      }`}
                    >
                      {msg.messageType === 'sender' && (
                        <Avatar
                          src={chatInfo?.avatar}
                          className="float-left md:mr-28 mr-12 md:w-60 md:h-60 w-28 h-28 bg-darkgreen-100 md:p-10 p-5"
                        />
                      )}
                      <div className="sm:max-w-480 max-w-256 inline-block">
                        <div
                          key={index}
                          className={`transition ease-in-out delay-150 md:p-16 p-10 my-5 mx-auto rounded-2xl message md:text-16 font-semibold ${
                            msg?.messageType === 'sender'
                              ? 'bg-darkgreen-100 text-left rounded-tl-none'
                              : `bg-[${chatInfo?.headerColor}] text-right text-white rounded-tr-none`
                          } `}
                          style={{
                            backgroundColor:
                              msg?.messageType === 'sender'
                                ? 'darkgreen-100'
                                : chatInfo?.headerColor,
                          }}
                          dangerouslySetInnerHTML={{ __html: msg?.message }}
                        />
                        <Typography
                          className="time w-full text-12 font-medium text-grey-700 mt-8 whitespace-nowrap"
                          color="text.secondary"
                        >
                          {moment(msg?.cts).format('h:MM A')}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
            {displayMenu && (
              <div className="tags block max-w-[1050px] relative mx-auto text-left mt-8 max-w-[1050px] md:pl-80 pl-36">
                {tags?.map((tag, i) => (
                  <div
                    key={i}
                    className="tag bg-white font-medium md:text-14 text-12 text-darkgreen border-1 border-solid border-darkgreen rounded-full md:px-16 md:py-8 px-12 py-2 m-2 cursor-pointer inline-block"
                    aria-hidden="true"
                    onClick={() => handleTagClick(tag)}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            )}
            {isBotTyping && (
              <div className="block max-w-[1050px] relative mx-auto text-left">
                <Avatar
                  src={chatInfo?.avatar}
                  className="float-left md:mr-28 mr-12 md:w-60 md:h-60 w-28 h-28 bg-darkgreen-100 md:p-10 p-5"
                />
                <div className="max-w-480 inline-block">
                  <div className="py-16 my-5 mx-auto rounded-2xl message md:text-16 font-semibold bg-white text-left rounded-tl-none">
                    <div className="typing-indicator">
                      <div className="dot" style={{ backgroundColor: chatInfo?.headerColor }} />
                      <div className="dot" style={{ backgroundColor: chatInfo?.headerColor }} />
                      <div className="dot" style={{ backgroundColor: chatInfo?.headerColor }} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div ref={chatScroll} className="h-20" />
          </div>

          <form
            onSubmit={onMessageSubmit}
            className="pb-16 px-8 bg-white bottom-0 fixed left-0 right-0 mx-auto max-w-[1050px] pt-24"
          >
            <Paper className="rounded-16 flex items-center relative shadow-none border-1 border-solid border-grey-500">
              <InputBase
                autoFocus={false}
                id="message-input"
                size="small"
                className="flex flex-1 grow shrink-0 px-16"
                placeholder="Ask Me anything"
                onChange={(e) => setMessageText(e.target.value)}
                value={messageText}
              />
              <IconButton className="absolute right-10 top-3" type="submit" size="small">
                <FuseSvgIcon className="rotate-90" color="action">
                  heroicons-outline:paper-airplane
                </FuseSvgIcon>
              </IconButton>
            </Paper>
          </form>
        </>
      ) : (
        <div className="h-screen flex items-center mx-10">
          <div className="bg-darkgreen-100 max-w-screen-lg	lg:py-60 py-20 lg:px-96 px-20 text-center mx-auto ">
            <div>
              <img
                className="m-auto max-w-96"
                src="/assets/images/business/invalid_chat.png"
                alt=""
              />
              <h3 className="sm:text-28 text-24 font-bold mb-16 mt-20 text-darkgreen">
                {t('quickChat.invalidLink')}
              </h3>
              <Typography className="sm:text-16 font-semibold mx-auto max-w-screen-md lg:px-96">
                {t('quickChat.invalidDesc')}
              </Typography>
            </div>
          </div>
        </div>
      )}
      <Dialog
        classes={{
          paper: 'm-24 p-28',
        }}
        open={openDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={() => {
              setOpenDialog(false);
              setRating();
              window.open('about:blank', '_self');
              window.close();
            }}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
        </DialogTitle>

        <DialogContent className="p-0 mb-24">
          <Typography className="text-20 font-medium text-center">
            {t('quickChat.shareExp')}
          </Typography>
          <div className="text-center mt-8">
            {[...Array(5)]?.map((item, i) => {
              return (
                <Icon
                  className={`text-48 cursor-pointer ${
                    i < rating ? 'text-yellow-700' : 'text-gray-400'
                  }`}
                  key={i}
                  onClick={() => handleClick(i + 1)}
                >
                  star
                </Icon>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions className="p-0 justify-center">
          <Button
            className="md:text-18 font-semibold disabled:text-black rounded-md px-48"
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => submitRating()}
            disabled={!rating}
          >
            Submit
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QuickChat;
