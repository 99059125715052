import AutoGenerateTextPage from './AutoGenerateTextPage';
import CustomAttributesPage from './CustomAttributesPage';
import InviteReviewProductPage from './InviteReviewProductPage';
import ProductDetails from './ProductDetails';
import Products from './Products';
import ReviewScreenModePage from './ReviewScreenModePage';
import ServiceRecoveryPage from './ServiceRecoveryPage';

const ProductsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'product/:id?',
      element: <Products />,
    },
    {
      path: 'productDetails/:productId/:id?',
      element: <ProductDetails />,
    },
    {
      path: 'productDetails/reviewMode/:productId/:id?',
      element: <ReviewScreenModePage />,
    },
    {
      path: 'productDetails/autoGenerateText/:productId/:id?',
      element: <AutoGenerateTextPage />,
    },
    {
      path: 'productDetails/customAttribute/:productId/:id?',
      element: <CustomAttributesPage />,
    },
    {
      path: 'productDetails/inviteReviews/:productId/:id?',
      element: <InviteReviewProductPage />,
    },
    {
      path: 'productDetails/serviceRecovery/:productId/:id?',
      element: <ServiceRecoveryPage />,
    },
  ],
};

export default ProductsConfig;
