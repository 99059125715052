const getPackages = ` 
query($countryId: Int, $packageInterval: String) {
	getPackages(countryId: $countryId, packageInterval: $packageInterval) {
	  data {
		id
		image
		name
		startDate
		expireDate
		packageIndex
		packageDetails
		offerAmount
		amount
		currency
		stripePriceId
		packageTypeId
		packageInterval
		razorpayPlanId
	  }
	  status
	  message
	}
  }  
`;

const getLocation = `
query getLocation($data: String!) {
  getLocation(data: $data) {
    message
    status
    data
    id
  }
}
`;

const getPlansAndFeaturesData = `
query {
  getPlansAndFeaturesData {
    data {
      heading {
        index
        packageTypeId
        planName
      }
      featuresData {
        index
        featureName
        description
        value {
          index
          packageTypeId
          value
        }
      }
    }
    status
    message
  }
}
`;

const getUserSubscriptionPackage = `
query getUserSubscriptionPackage {
	getUserSubscriptionPackage {
	data
	status
	message
  }
}
`;

const getPaymentHistory = `
query getPaymentHistory($pageNumber: Int, $pageSize: Int) {
	getPaymentHistory(pageNumber: $pageNumber, pageSize: $pageSize) {
	data{
		autopay
		cts
		packages{
			amount
			countries{
				currency
			}
			id
			name
			userValidation
		}
		paymentIntentsDescTwo{
			amount
			created
			currency
			payment_method_types
			status
		}
		userId
		transactionId
		user_package_subscription{
			paymentDetailsId
			expireDate
			startDate
		}
	}
	status
	message
  }
}
`;

const getInvoiceHistoryForRazorpay = `
query getInvoiceHistoryForRazorpay($pageNumber: Int, $pageSize: Int) {
	getInvoiceHistoryForRazorpay(pageNumber: $pageNumber, pageSize: $pageSize) {
	data{
		expireDate
		invoiceAmount
		invoiceRedirectLink
		isActive
		paymentMethod
		planName
		startDate
		status
		transactionId
	}
	status
	message
  }
}
`;

const getPaymentInvoice = `
query getPaymentInvoice($id: String!) {
	getPaymentInvoice(id: $id) {
	data{
		hosted_invoice_url
		id
		invoice_pdf
		object
	}
	status
	message
  }
}
`;

const saveQuickReviewContactUsData = `
mutation($name: String!, $countryCode: String!, $isoCode: String!, $phoneNumber: Float!, $email: String!, $message: String!) {
	saveQuickReviewContactUsData(
	  name: $name
	  phoneNumber: {
		countryCode: $countryCode
		isoCode: $isoCode
		phoneNumber: $phoneNumber
	  }
	  email: $email
	  message: $message
	) {
	  status
	  message
	}
  }
  `;

const redirectPortalForSubscription = `
mutation($countryId: Int!, $priceId: String!, $userBusinessId: Int) {
	redirectPortalForSubscription(
	countryId: $countryId
	  priceId: $priceId
		userBusinessId: $userBusinessId
	) {
		data{
			url
		}
	  status
	  message
	}
  }
  `;

const cancelSubscription = `
  mutation ($data: String!){
	cancelSubscription(data: $data){
	  status
	  message
	}
  }  
  `;

const cancelSubscriptionForRazorpay = `
  mutation ($data: String!){
	cancelSubscriptionForRazorpay(data: $data){
	  status
	  message
	}
  }  
  `;

const payUpdate = `mutation payUpdate($stripeSubscriptionId: String!, $type:String!, $countryId: Int!, $upgradePlanId: Int!){
	payUpdate(stripeSubscriptionId: $stripeSubscriptionId,type:$type, countryId: $countryId, upgradePlanId: $upgradePlanId ){
	  status
	  message
	  data {
	    url
	  }
	}
   }
   `;

const getPackagesV2 = `query getPackages($callFromPortal: Boolean, $countryId: Int, $packageInterval: String){
	getPackages(callFromPortal: $callFromPortal, countryId: $countryId, packageInterval: $packageInterval){
	   status
	  message
	  data {
		id
		name
		amount
		image
		startDate
		expireDate
		currency
		stripePriceId
		productId
		packageInterval
		packageTypeId
		appleProductId
		packageIndex
		packageDetails
		googleProductId
		package_amount {
		  price
		  packageId
		  countries {
			currency
		  }
		}
		offerAmount
		razorpayPlanId
		featureInsideSection
	  }
	}
   }
   `;

const getSpecialOfferForUserBusiness = `query($userBusinessId: Int) {
	getSpecialOfferForUserBusiness(userBusinessId: $userBusinessId) {
	  status
	  message
	  data {
		id
		backgroundColor
		name
		description
		icons
		iconsAndNames {
		 id
        name
        icon
		}
		button {
		  name
		  buttonColor
		}
		offerAmount
		packageInterval
		discount
		packageId
		packageBundle
		amount
		razorpayPlanId
		stripePriceId
	  }
	}
  }`;
const planQuery = {
  getPackages,
  getLocation,
  getPlansAndFeaturesData,
  getUserSubscriptionPackage,
  getInvoiceHistoryForRazorpay,
  getPaymentHistory,
  getPaymentInvoice,
  saveQuickReviewContactUsData,
  redirectPortalForSubscription,
  cancelSubscription,
  cancelSubscriptionForRazorpay,
  payUpdate,
  getPackagesV2,
  getSpecialOfferForUserBusiness,
};

export default planQuery;
