import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Icon,
  Typography,
} from '@mui/material';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { selectDashboardData } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';

const GoogleLoginButton = ({ onSuccess, onError }) => {
  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess,
    onError,
    scope: ['https://www.googleapis.com/auth/business.manage'],
  });

  return (
    <Button
      className="bg-darkgreen hover:bg-darkgreen rounded-md text-white px-28 py-16 mt-8 text-16 font-semibold"
      onClick={() => handleGoogleLogin()}
    >
      {t('businessCompetitor.connectBusiness')}
    </Button>
  );
};

const ConnectYourBusiness = (props) => {
  const { setIsBusinessConnect } = props;
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [openDialog, setOpenDialog] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [businessList, setBusinessList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const fetchUserDetails = useCallback(async () => {
    try {
      if (userCurrentBusinessDetails?.id) {
        const payload = {
          query: manageBusinessData.getUserBusinessDetail,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getUserBusinessDetail?.status === 200) {
          setIsBusinessConnect(result?.getUserBusinessDetail?.data?.[0].isGoogleVerified);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, setIsBusinessConnect]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const responseGoogle = async (response) => {
    try {
      const payload = {
        query: manageBusinessData.getUserRegisteredGoogleBusiness,
        variables: {
          authorizationCode: response?.code,
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          isAddedByGoogleConnect: false,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getUserRegisteredGoogleBusiness?.status === 200) {
        setBusinessList(result.getUserRegisteredGoogleBusiness?.data);
        setOpenDialog(true);
      } else {
        enqueueSnackbar(result?.getUserRegisteredGoogleBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching registered Google Business:', error);
    }
  };

  const handleError = (error) => {
    console.log('Login Failed:', error);
  };

  const handleConnectBusiness = async (data) => {
    setRefreshIcon(true);
    try {
      setLocationId(data?.locationId);
      const payload = {
        query: manageBusinessData.connectMyBusiness,
        variables: {
          placeId: data?.businessPlaceId,
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          locationId: data?.locationId,
          accountId: data?.accountId,
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.connectMyBusiness?.status === 200) {
        setOpenDialog(false);
        setLocationId('');
        fetchUserDetails();
      } else {
        enqueueSnackbar(result?.connectMyBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error connecting business:', error);
    }
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="text-center m-auto">
        <img className="w-192 m-auto" src="/assets/images/business/googleLogo.png" alt="" />
        <Typography className="text-20 font-semibold py-8">
          {t('businessCompetitor.businessConnectMessage')}
        </Typography>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <div className="w-full flex justify-center items-center">
            <GoogleLoginButton onSuccess={responseGoogle} onError={handleError} />
          </div>
        </GoogleOAuthProvider>
      </div>
      <Dialog
        classes={{
          paper: 'p-24',
        }}
        open={openDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDialog(false);
          }
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-16">
          <Icon
            onClick={() => {
              setOpenDialog(false);
            }}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            close
          </Icon>
        </DialogTitle>
        <DialogActions className="p-0 border p-12 w-full h-320 overflow-scroll rounded block border-darkgreen border-solid text-left">
          {businessList?.length > 0 ? (
            businessList?.map((item, index) => {
              return (
                <div className="!ml-0 mb-12 border p-8 rounded border-solid border-darkgreen">
                  <Typography className="font-semibold text-14">{item?.businessName}</Typography>
                  <Typography className="text-14 my-2">Address: {item?.businessAddress}</Typography>
                  <Button
                    className="bg-darkgreen rounded hover:bg-darkgreen text-white disabled:bg-gray px-16 font-semibold text-14"
                    disabled={locationId === item?.locationId && refreshIcon}
                    onClick={() => handleConnectBusiness(item)}
                  >
                    {t('businessCompetitor.connectBusiness')}
                    {locationId === item?.locationId && refreshIcon && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </Button>
                </div>
              );
            })
          ) : (
            <Typography>{t('businessCompetitor.noBusinessAvailable')}</Typography>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConnectYourBusiness;
