import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '@history';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Slider as MSlider, Icon } from '@mui/material';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import Slider from 'react-slick';
import moment from 'moment';
import { handleApiRequest, getParamsId, findExpireDay } from '../../common/common';
import reducer from './store';
import quickHub from '../../query/quickHub';
import UserService from '../../../services/userService';

function Dashboards() {
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const loginUserData = UserService.getUserData();
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    surpriseMeCard: {},
    quickSummaryList: [],
    overAllFullReport: [],
    specialOffer: [],
    productSnapShotData: [],
  });
  const isSingleItem = userSubscriptionData?.length === 1;

  const getImageSrc = useMemo(
    () => (type) => {
      const imageMap = {
        quick_social: 'assets/images/quick-hub/quick-social.jpg',
        quick_campaigns: 'assets/images/quick-hub/quick-campaign.jpg',
        quick_chat: 'assets/images/quick-hub/quick-chat.jpg',
        quick_reviews: 'assets/images/quick-hub/quick-review.jpg',
      };
      return imageMap[type] || '';
    },
    []
  );

  const fetchDashboardData = async (id) => {
    const fetchPayload = (query, variables) => ({
      query,
      variables: { ...variables },
    });

    const requests = [
      handleApiRequest(
        fetchPayload(quickHub.getSurpriseMeCard, { userBusinessId: id, isHomeScreen: true })
      ).catch((err) => ({ error: err })),
      handleApiRequest(fetchPayload(quickHub.getQuickHubOverAllSummary, { businessId: id })).catch(
        (err) => ({ error: err })
      ),
      handleApiRequest(fetchPayload(quickHub.quickHubOverAllFullReport, { businessId: id })).catch(
        (err) => ({ error: err })
      ),
      handleApiRequest(
        fetchPayload(quickHub.getSpecialOfferForUserBusiness, { userBusinessId: id })
      ).catch((err) => ({ error: err })),
      handleApiRequest(
        fetchPayload(quickHub.getQuickHubProductSnapShotData, { businessId: id })
      ).catch((err) => ({ error: err })),
    ];

    try {
      setLoading(true);
      const results = await Promise.all(requests);

      const [
        surpriseMeResult,
        summaryResult,
        fullReportResult,
        specialOfferResult,
        productSnapShotResult,
      ] = results.map((result) => (result.error ? null : result));

      setDashboardData({
        surpriseMeCard: surpriseMeResult?.getSurpriseMeCard?.data || {},
        quickSummaryList: summaryResult?.getQuickHubOverAllSummary?.data?.overallSummary || [],
        overAllFullReport: fullReportResult?.quickHubOverAllFullReport?.data || [],
        specialOffer: specialOfferResult?.getSpecialOfferForUserBusiness?.data || [],
        productSnapShotData: productSnapShotResult?.getQuickHubProductSnapShotData?.data || [],
      });
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchDashboardData(userCurrentBusinessDetails?.id);
    }
  }, [userCurrentBusinessDetails?.id]);

  if (loading) {
    return <FuseLoading />;
  }

  const { surpriseMeCard, quickSummaryList, productSnapShotData, specialOffer } = dashboardData;

  const settings = {
    dots: false,
    rows: 2,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const whatsSettings = {
    dots: true,
    rows: 1,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const subscriptionDataSettings = {
    dots: false,
    infinite: !isSingleItem,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  function CircularProgressBar(progressCount, totalProgressCount, type) {
    const radius = 30;
    const strokeWidth = 3;
    const circumference = 2 * Math.PI * radius;
    const progress = progressCount / totalProgressCount;
    const strokeDashoffset = circumference - progress * circumference;

    const colors = {
      quick_social: { bgColor: '#CEF1FC', progressColor: '#219EBC' },
      quick_chat: { bgColor: '#FFF1D1', progressColor: '#EBB32F' },
      quick_reviews: { bgColor: '#D4EBDE', progressColor: '#2DB67C' },
      quick_campaigns: { bgColor: '#E4DEF8', progressColor: '#8B78DC' },
    };

    const { bgColor = '', progressColor = '' } = colors[type] || {};

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <svg width={radius * 2 + strokeWidth} height={radius * 2 + strokeWidth}>
          <circle
            stroke={bgColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            r={radius}
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            style={{
              filter: 'drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px)',
            }}
          />
          <circle
            stroke={progressColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            r={radius}
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            style={{
              transition: 'stroke-dashoffset 0.35s ease',
              transform: `rotate(-90deg)`,
              transformOrigin: '50% 50%',
            }}
          />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="28"
            fontWeight="bold"
            style={{
              textShadow: '0px 3px 6px rgba(0, 0, 0, 0.3)',
            }}
            fill={
              type === 'quick_social'
                ? '#219EBC'
                : type === 'quick_chat'
                ? '#EBB32F'
                : type === 'quick_reviews'
                ? '#2DB67C'
                : type === 'quick_campaigns'
                ? '#8B78DC'
                : ''
            }
          >
            {progressCount}
          </text>
        </svg>
      </div>
    );
  }

  const SliderCard = ({ jsonCard }) => {
    let parsedCard = [];
    try {
      parsedCard = JSON.parse(jsonCard);
    } catch (error) {
      console.error('Invalid JSON format:', error);
    }
    return (
      parsedCard.length > 0 && (
        <Slider {...whatsSettings}>
          {parsedCard.map((product, i) => (
            <div className="block w-full" key={i}>
              <div className="border border-solid border-quick-review rounded-md mt-16 pr-16 py-10 pl-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src={product?.image} className="w-36 inline-block mr-4" alt="product" />
                    <span className="font-medium text-14 inline-block">{product?.description}</span>
                  </div>
                  <Button
                    className="text-12 font-medium px-14"
                    size="small"
                    color="secondary"
                    variant="contained"
                  >
                    {product?.button}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )
    );
  };

  const handleSurpriseMeCard = () => {
    const id = getParamsId();
    if (id) {
      history.push(`explore-features/${id}`);
    } else {
      history.push('explore-features/');
    }
  };

  return (
    <div className="p-20 lg:p-32 h-full l-padding">
      <div className="flex flex-col">
        {userSubscriptionData &&
          userSubscriptionData?.length > 0 &&
          userSubscriptionData.some((subscriptionData) => subscriptionData.isFreeTrial) && (
            <div className="bg-[#FFE4E1] mb-20 sm:text-18 text-14 rounded-6 font-semibold p-10">
              <Slider {...subscriptionDataSettings}>
                {userSubscriptionData
                  ?.filter((subscriptionData) => subscriptionData.isFreeTrial)
                  ?.map((subscriptionData, i) => (
                    <div key={i}>
                      {findExpireDay(subscriptionData?.expireDate) > 0 ? (
                        <div className="w-full flex justify-center relative top-3">
                          <div className="items-center flex justify-between">
                            <img
                              className="block rounded max-w-38 mr-16"
                              name="logo"
                              src="/assets/images/icon/bellIconNotification.png"
                              alt="bell notification icon"
                            />
                            <p className="mr-48">
                              {t('quickHubDashboard.your')} {subscriptionData?.name}{' '}
                              {t('quickHubDashboard.freeTrialExpire')}{' '}
                              {findExpireDay(subscriptionData?.expireDate)}{' '}
                              {t('business.searchBusiness.labels.days')}.
                            </p>
                            <Button
                              className="text-14 font-medium bg-[#D07062] text-white hover:bg-red-300 rounded-12 px-16"
                              variant="contained"
                              size="small"
                              type="submit"
                            >
                              {t('quickHubDashboard.upgradeNow')}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="w-full min-h-40 max-h-40 flex items-center justify-center">
                          <div className="w-384 sm:w-586 items-center flex justify-center">
                            <p>
                              {t('quickHubDashboard.your')} {subscriptionData?.name}{' '}
                              {t('quickHubDashboard.planExpired')}.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </Slider>
            </div>
          )}
        <div className="flex flex-row gap-32 justify-between">
          <div className="w-full">
            <div className="mb-32 bg-white shadow rounded-md py-20 px-24">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-20 font-bold">{t('quickHubDashboard.welcomeText')}</h3>
                  <Typography className="text-18 font-medium my-24 max-w-512">
                    {' '}
                    {t('quickHubDashboard.mainDesc')}
                  </Typography>
                  <div>
                    <img
                      src="assets/images/quick-hub/app-store.png"
                      className="inline-block max-w-160"
                      alt="..."
                    />
                    <img
                      src="assets/images/quick-hub/play-store.png"
                      className="inline-block max-w-160 ml-24"
                      alt="..."
                    />
                  </div>
                </div>
                <div>
                  <img src="assets/images/quick-hub/welcome.png" className="max-w-256" alt="..." />
                </div>
              </div>
            </div>
            {productSnapShotData && productSnapShotData?.length > 0 && (
              <div>
                <div className="grid grid-cols-2 gap-32">
                  {productSnapShotData?.reverse()?.map((productList, index) => (
                    <div className="bg-white shadow rounded-md p-24" key={index}>
                      <div className="mb-20">
                        <img
                          alt={productList.type}
                          src={getImageSrc(productList?.type)}
                          className="inline-block mr-12"
                        />
                        <h2 className="font-bold text-20 capitalize inline-block">
                          {productList?.type.replace(/_/g, ' ')}
                        </h2>
                      </div>
                      <div>
                        <div
                          className={` rounded p-16 mb-16 ${
                            productList?.type === 'quick_social'
                              ? 'bg-quick-social-100'
                              : productList?.type === 'quick_campaigns'
                              ? 'bg-quick-campaign-100'
                              : productList?.type === 'quick_chat'
                              ? 'bg-quick-chat-100'
                              : productList?.type === 'quick_reviews'
                              ? 'bg-quick-review-100'
                              : ''
                          } `}
                        >
                          {productList?.pendingTask?.progressCount !==
                            productList?.pendingTask?.totalProgressCount && (
                            <div className="flex items-center">
                              {CircularProgressBar(
                                productList?.pendingTask?.progressCount,
                                productList?.pendingTask?.totalProgressCount,
                                productList?.type
                              )}
                              <div className="pl-12">
                                <h3 className="text-16 font-semibold">
                                  {t('quickHubDashboard.taskPending')}
                                </h3>
                                <Typography className="text-14 font-medium max-w-256 mt-6 text-grey-700">
                                  {t('quickHubDashboard.desc')}
                                </Typography>
                              </div>
                            </div>
                          )}
                          {productList?.pendingTask?.card &&
                            productList?.pendingTask?.card?.length > 0 && (
                              <div className="bg-white rounded mt-10 px-12 py-10 font-medium text-14 flex items-center justify-between">
                                {productList?.pendingTask?.card?.[0]?.name}
                                <Button
                                  className={`text-white h-24 min-h-24 hover:bg-black ${
                                    productList?.type === 'quick_social'
                                      ? 'bg-quick-social'
                                      : productList?.type === 'quick_campaigns'
                                      ? 'bg-quick-campaign'
                                      : productList?.type === 'quick_chat'
                                      ? 'bg-quick-chat'
                                      : productList?.type === 'quick_reviews'
                                      ? 'bg-quick-review'
                                      : ''
                                  }`}
                                  variant="contained"
                                  size="small"
                                >
                                  {productList?.pendingTask?.card?.[0]?.button}
                                </Button>
                              </div>
                            )}
                        </div>
                        <div>
                          <div
                            className={`${
                              productList?.type === 'quick_social' ||
                              productList?.type === 'quick_chat'
                                ? 'grid grid-cols-2 gap-16'
                                : 'grid grid-cols-3 gap-16'
                            }`}
                          >
                            {productList?.type === 'quick_reviews' && (
                              <>
                                <div className="bg-grey-100 rounded text-center px-5 py-32">
                                  <span className="text-40 block font-semibold">
                                    {productList?.dataPoint?.todayReview}
                                  </span>
                                  <span className="text-14 font-medium">
                                    {t('quickHubDashboard.todayReview')}
                                  </span>
                                </div>
                                <div className="bg-grey-100 rounded text-center px-5 py-32">
                                  <span className="text-40 block font-semibold">
                                    {productList?.dataPoint?.totalReviewsRating?.count}
                                  </span>
                                  <span className="text-14 font-medium">
                                    {t('quickHubDashboard.overallRating')}
                                  </span>
                                </div>
                                <div className="bg-grey-100 rounded text-center px-5 py-32">
                                  <span className="text-40 block font-semibold">
                                    {productList?.dataPoint?.totalReviews?.count}
                                  </span>
                                  <span className="text-14 font-medium">
                                    {t('quickHubDashboard.totalReview')}
                                  </span>
                                </div>
                              </>
                            )}

                            {productList?.type === 'quick_chat' && (
                              <>
                                <div className="bg-grey-100 rounded text-center px-5 py-32">
                                  <span className="text-40 block font-semibold">
                                    {productList?.dataPoint?.todayConversation}
                                  </span>
                                  <span className="text-14 font-medium max-w-96 block mx-auto">
                                    {t('quickHubDashboard.todayConversations')}
                                  </span>
                                </div>
                                <div className="bg-grey-100 rounded text-center px-5 py-32">
                                  <span className="text-40 block font-semibold">
                                    {productList?.dataPoint?.totalConversation}
                                  </span>
                                  <span className="text-14 font-medium">
                                    {t('quickHubDashboard.totalConversations')}
                                  </span>
                                </div>
                              </>
                            )}

                            {productList?.type === 'quick_campaigns' && (
                              <>
                                <div className="bg-grey-100 rounded text-center px-5 py-32">
                                  <span className="text-40 block font-semibold">
                                    {productList?.dataPoint?.todayConversation}
                                  </span>
                                  <span className="text-14 font-medium max-w-96 block mx-auto">
                                    {t('quickHubDashboard.smsSend')}
                                  </span>
                                </div>
                                <div className="bg-grey-100 rounded text-center px-5 py-32">
                                  <span className="text-40 block font-semibold">
                                    {productList?.dataPoint?.todayConversation}
                                  </span>
                                  <span className="text-14 font-medium max-w-96 block mx-auto">
                                    {t('quickHubDashboard.emailSend')}
                                  </span>
                                </div>
                                <div className="bg-grey-100 rounded text-center px-5 py-32">
                                  <span className="text-40 block font-semibold">
                                    {productList?.dataPoint?.totalConversation}
                                  </span>
                                  <span className="text-14 font-medium">
                                    {t('quickHubDashboard.whatsappSend')}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                          {productList?.whatsNewCard && (
                            <SliderCard jsonCard={productList.whatsNewCard} t={t} />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="min-w-400">
            {surpriseMeCard && Object?.keys(surpriseMeCard)?.length > 0 && (
              <div className="bg-white rounded-md p-16 relative shadow">
                <div className="absolute top-32 left-32">
                  <Typography
                    className="text-18 font-semibold mb-24 max-w-160"
                    style={{ color: surpriseMeCard?.buttonColor }}
                  >
                    {surpriseMeCard?.description}
                  </Typography>
                  <Button
                    className="rounded-full px-16"
                    color="secondary"
                    variant="contained"
                    onClick={() => handleSurpriseMeCard()}
                    size="small"
                    style={{ backgroundColor: surpriseMeCard?.buttonColor }}
                  >
                    {surpriseMeCard?.button}
                  </Button>
                </div>
                <img
                  src={surpriseMeCard?.image}
                  className="inline-block w-full rounded-md max-h-192"
                  alt="..."
                />
              </div>
            )}
            {quickSummaryList && quickSummaryList?.length > 0 && (
              <div className="bg-white rounded-md p-16 relative mt-32 max-w-400 shadow">
                <h2 className="text-16 font-bold mb-16">{t('quickHubDashboard.overallSummary')}</h2>
                <Slider className="-mx-5 -my-5" {...settings}>
                  {quickSummaryList?.map((summary, index) => (
                    <div className="block w-full " key={index}>
                      <div
                        className="rounded-md p-10 mx-5 my-5"
                        style={{ backgroundColor: summary?.backgroundColor }}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-32 h-32 inline-block align-middle"
                            src={summary?.icon}
                            alt="..."
                          />
                          <span className="text-24 font-semibold inline-block ml-10 align-middle">
                            {summary?.key}
                          </span>
                        </div>
                        <span className="block text-12 font-medium text-grey-600 mt-6">
                          {summary?.value}
                        </span>
                      </div>
                    </div>
                  ))}
                </Slider>
                {/* {overAllFullReport && overAllFullReport?.length > 0 && (
            <Button
            className="rounded w-full mt-16 min-h-36 h-36 flex items-center justify-between px-16"
            variant="outlined"
            color="secondary"
            size="medium"
            type="button"
            // onClick={() => handleDownload()}
          >
            {t('quickHubDashboard.viewFullReport')} <Icon className="w-auto text-16">chevron_right</Icon>
          </Button>
          )} */}
              </div>
            )}
            {specialOffer && specialOffer?.length > 0 && (
              <div className="bg-white rounded-md p-16 relative mt-32 max-w-400 shadow">
                <h2 className="text-16 font-bold mb-16">{t('quickHubDashboard.specialOffers')}</h2>
                {specialOffer?.map((specialValue, index) => (
                  <div className="block w-full mb-16 last:mb-0" key={index}>
                    <div
                      className="rounded-md p-24 text-center"
                      style={{ backgroundColor: specialValue?.backgroundColor }}
                    >
                      <div className="flex items-center justify-center mb-14">
                        {specialValue?.icons?.map((specialIcon, i) => (
                          <img
                            className="w-56 h-56 align-middle relative first:ml-0 -ml-8 border-1 border-solid border-grey-400 rounded-full"
                            key={i}
                            src={specialIcon}
                            alt="..."
                          />
                        ))}
                      </div>
                      <span className="text-20 font-bold block mb-12 align-middle capitalize">
                        {loginUserData?.countryId === 2 ? '₹' : '$'}
                        {specialValue?.amount}/{specialValue?.packageInterval.replace(/_/g, ' ')}
                      </span>
                      <Typography className="block text-14 max-w-256 font-medium text-grey-600 mb-20 mx-auto">
                        {specialValue?.description}
                      </Typography>
                      <Button
                        className="px-20"
                        color="secondary"
                        size="small"
                        variant="contained"
                        style={{ backgroundColor: specialValue?.button?.buttonColor }}
                      >
                        {specialValue?.button?.name}
                      </Button>
                      <div className="flex w-full justify-center mt-14">
                        <span className="flex justify-center max-w-224 rounded-8 bg-white px-8 p-5">
                          <img
                            className="mr-10 max-w-16"
                            src="/assets/images/icon/discount.svg"
                            alt=""
                          />
                          <span className="text-blue-900 text-14 font-500">
                            {t('plan.save')} {loginUserData?.countryId === 2 ? '₹' : '$'}
                            {specialValue?.discount} {t('plan.onThis')} {specialValue?.name}!
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withReducer('businessDashboardsReducer', reducer)(Dashboards);
