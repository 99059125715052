import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import withReducer from 'app/store/withReducer';
import history from '@history';
import { useLocation } from 'react-router-dom';
import businessData from '../../../query/business';
import { getParamsId, handleApiRequest } from '../../../common/common';
import { storeCustomerList, storeSendSmsOrEmailDetails } from '../store/targetMessageSlice';
import reducer from '../store';

const SendSmsOrEmail = () => {
  const [selectedOption, setSelectedOption] = useState('sendNow');
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const sendSmsOrEmailDetailsData = useSelector(
    ({ state }) => state.targetMessageSlice?.sendSmsOrEmailDetailsData
  );
  const customerList = useSelector(({ state }) => state.targetMessageSlice?.customerListData);

  useEffect(() => {
    const id = getParamsId();
    if (
      (location?.pathname === '/send-reviews/' || location?.pathname === `/send-reviews/${id}`) &&
      (!customerList?.queue?.length > 0 ||
        !sendSmsOrEmailDetailsData?.businessId ||
        (sendSmsOrEmailDetailsData?.campaignPlatformId === 2 && !customerList?.message) ||
        (sendSmsOrEmailDetailsData?.campaignPlatformId === 1 && !customerList?.htmlTemplate) ||
        !customerList?.templateName)
    ) {
      if (id) {
        history.push(`invite-reviews/${id}`);
      } else {
        history.push('invite-reviews/');
      }
    }
    if (
      (location?.pathname === '/send-messages/' || location?.pathname === `/send-messages/${id}`) &&
      (!customerList?.queue?.length > 0 ||
        !sendSmsOrEmailDetailsData?.businessId ||
        (sendSmsOrEmailDetailsData?.campaignPlatformId === 2 && !customerList?.message) ||
        (sendSmsOrEmailDetailsData?.campaignPlatformId === 1 && !customerList?.htmlTemplate) ||
        !customerList?.templateName)
    ) {
      if (id) {
        history.push(`send-message/${id}`);
      } else {
        history.push('send-message/');
      }
    }
  });
  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };
  const handleSubmit = async () => {
    let campaignMessageId = '';
    setRefreshIcon(true);
    if (!customerList?.existingMessageId) {
      const payload = {
        query: businessData.createCampaignServicesStandardMessages,
        variables: {
          ...(sendSmsOrEmailDetailsData?.campaignPlatformId === 2 && {
            messages: customerList?.message,
          }),
          ...(sendSmsOrEmailDetailsData?.campaignPlatformId === 1 && {
            htmlTemplate: customerList.htmlTemplate,
            htmlSubject: customerList?.emailSubject,
          }),
          userBusinessId: parseInt(sendSmsOrEmailDetailsData?.businessId, 10),
          serviceType: sendSmsOrEmailDetailsData?.targetMessageType,
          campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10),
          title: customerList?.templateName,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.createCampaignServicesStandardMessages?.status === 200) {
        campaignMessageId = result?.createCampaignServicesStandardMessages?.data?.id;
      }
    }
    const finalCustomerData = customerList?.queue?.map((customer) => {
      return {
        ...(sendSmsOrEmailDetailsData?.campaignPlatformId === 2 && {
          countryCode: customer?.countryCode,
          phoneNumber: parseInt(customer?.phoneNumber, 10),
          title:
            sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews'
              ? customerList?.message
                  ?.replace(/\{firstname\} {lastname}/g, customer.name)
                  .replace(/\{businessname\}/g, sendSmsOrEmailDetailsData?.businessName)
                  .replace(/\[Business Name\]/g, sendSmsOrEmailDetailsData?.businessName)
                  .replace(/\[Customers Name\]/g, customer.name)
                  .concat(`${sendSmsOrEmailDetailsData?.reviewUrl}`)
              : customerList?.message
                  ?.replace(/\{firstname\} {lastname}/g, customer.name)
                  .replace(/\{businessname\}/g, sendSmsOrEmailDetailsData?.businessName)
                  .replace(/\[Business Name\]/g, sendSmsOrEmailDetailsData?.businessName)
                  .replace(/\[Customers Name\]/g, customer.name),
        }),
        name: `${customer.name}`,
        ...(sendSmsOrEmailDetailsData?.campaignPlatformId === 1 && {
          email: customer?.email,
          htmlSubject: customerList?.emailSubject
            ?.replace(/\[Business Name\]/g, sendSmsOrEmailDetailsData.businessName)
            .replace(/\[Customers Name]/g, customer.name),
          htmlTemplate: `${customerList?.htmlTemplate
            ?.replace(/\{firstname\} {lastname}/g, customer.name)
            .replace(/\[Business Name\]/g, sendSmsOrEmailDetailsData?.businessName)
            .replace(/\[Customers Name\]/g, customer.name)
            .replace(/\{businessname\}/g, sendSmsOrEmailDetailsData?.businessName)
            .replace(/\[review link\]/g, `${sendSmsOrEmailDetailsData?.reviewUrl}`)
            .replace(/\{reviewurl\}/g, `${sendSmsOrEmailDetailsData?.reviewUrl}`)}`,
        }),
      };
    });
    const dateTimeString = `${selectedDate} ${selectedTime}`;

    let scheduleSms = {};
    if (selectedDate && selectedTime) {
      scheduleSms = {
        isSchedule: true,
        scheduleDate: `${dateTimeString} +530`,
      };
    }
    let variables = {
      businessId: parseInt(sendSmsOrEmailDetailsData?.businessId, 10),
      campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10),
      campaignServicesStandardMessageId:
        parseInt(campaignMessageId, 10) || parseInt(customerList?.messageCampaignData?.id, 10),
      queue: finalCustomerData,
      servicesType: sendSmsOrEmailDetailsData?.targetMessageType,
      isSchedule: selectedOption !== 'schedule' && false,
    };

    if (selectedOption === 'schedule') {
      variables = {
        ...variables,
        ...scheduleSms,
      };
    }
    const payload = {
      query: businessData.sendCampaignMessages,
      variables,
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.sendCampaignMessages?.status === 200) {
      enqueueSnackbar(result?.sendCampaignMessages?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      dispatch(storeSendSmsOrEmailDetails());
      dispatch(storeCustomerList());
      const id = getParamsId();
      if (id) {
        history.push(`invite-reviews/${id}`);
      } else {
        history.push('invite-reviews/');
      }
    } else {
      enqueueSnackbar(result?.sendCampaignMessages?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleSelectDate = (data) => {
    const originalDate = new Date(data);
    const formattedDate = originalDate?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = formattedDate.split('/');
    const desiredFormat = `${year}-${month}-${day}`;
    setSelectedDate(desiredFormat);
  };

  const handleSelectTime = (data) => {
    setSelectedTime(data.toLocaleTimeString());
  };

  const handleCancel = () => {
    setSelectedDate('');
    setSelectedTime('');
    const id = getParamsId();
    if (id) {
      history.push(`invite-reviews/${id}`);
    } else {
      history.push('invite-reviews/');
    }
  };
  return (
    <div className="p-20 lg:p-32">
      <div>
        <Typography className="text-24 text-black font-bold">
          {sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews'
            ? t('inviteReviews.title')
            : t('sendMessage.title')}
        </Typography>
        <div className="bg-white rounded-md relative shadow p-20 mt-40 md:w-[50%]">
          <Typography className="text-14 text-black">{t('sendMessage.message')}</Typography>
          <div className="bg-[#FBFBFB] p-16 mt-4">
            {parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10) === 2 ? (
              <Typography className="text-14 text-black">
                {customerList?.message.concat(`${sendSmsOrEmailDetailsData?.reviewUrl}`)}
              </Typography>
            ) : (
              <>
                <Typography className="font-bold text-14 text-black">
                  {t('inviteReviews.subject')}: {customerList?.emailSubject}
                </Typography>
                <p
                  className="pt-12 text-14 text-black"
                  dangerouslySetInnerHTML={{
                    __html: customerList?.htmlTemplate,
                  }}
                />
              </>
            )}
          </div>
          <Typography className="text-14 text-black mt-4">
            {t('sendMessage.selectedPlatform')}
          </Typography>
          <div className="bg-[#FBFBFB] p-16 mt-4">
            <Typography className="text-14 text-black">
              {parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10) === 1 ? 'Email' : 'SMS'}
            </Typography>
          </div>
          <Typography className="text-14 text-black mt-4">
            {t('sendMessage.selectedCustomers')}
          </Typography>
          <div className="bg-[#FBFBFB] p-16 mt-4">
            <Typography className="text-14 text-black">{customerList?.queue?.length}</Typography>
          </div>
          <Typography className="text-14 text-black mt-4">
            {sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews'
              ? t('inviteReviews.sendInviteReview')
              : t('sendMessage.sendThisMessage')}
          </Typography>
          <div className="mt-4">
            <Radio
              checked={selectedOption === 'sendNow'}
              onChange={() => handleRadioChange('sendNow')}
            />
            <span>{t('sendMessage.sendNow')}</span>

            <Radio
              checked={selectedOption === 'schedule'}
              onChange={() => handleRadioChange('schedule')}
            />
            <span>{t('sendMessage.schedule')}</span>
          </div>
          {selectedOption === 'schedule' && (
            <div>
              <Typography className="text-14 text-black mt-4">
                {t('sendMessage.selectDate')}
              </Typography>
              <div className="bg-[#FBFBFB] date-color p-16 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="mt-4 w-full"
                    id="note"
                    variant="outlined"
                    required
                    fullWidth
                    disablePast
                    format="MMM dd, yyyy"
                    value={selectedTime}
                    onChange={handleSelectDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        label="Select a date"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <Typography className="text-14 text-black mt-4">
                {t('sendMessage.selectTime')}
              </Typography>
              <div className="bg-[#FBFBFB] date-color p-16 mt-4">
                <TimePicker
                  className="mt-4 w-full"
                  id="time"
                  onChange={handleSelectTime}
                  required
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </div>
            </div>
          )}
          <div className="text-center pt-16">
            <Button
              className="bg-white hover:bg-white text-darkgreen px-48 border-[1px] border-solid rounded border-darkgreen"
              onClick={() => handleCancel()}
            >
              {t('sendMessage.cancel')}
            </Button>
            <Button
              className="bg-darkgreen hover:bg-darkgreen disabled:text-black disabled:bg-gray px-48 text-white rounded ml-16 text-white"
              onClick={() => handleSubmit()}
              disabled={
                refreshIcon ||
                (selectedDate &&
                  selectedTime &&
                  !(new Date(`${selectedDate}T${selectedTime}`) > new Date()))
              }
            >
              {parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10) === 2
                ? t('sendMessage.sendSms')
                : t('sendMessage.sendEmail')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withReducer('state', reducer)(SendSmsOrEmail);
