import withReducer from 'app/store/withReducer';
import { useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Typography, Icon } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import StarIcon from '@mui/icons-material/Star';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import _ from '@lodash';
import { useSelector } from 'react-redux';
import Loading from './Loading';
import './MangeBusiness.css';
import manageBusinessData from '../../query/manageBusiness';
import reducer from './store';
import { getParamsId, handleApiRequest } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';

function ProductsServices() {
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(0);
  const [tabLoader, setTabLoader] = useState(false);
  const [businessProduct, setBusinessProduct] = useState([]);
  const [isSpecialProduct, setIsSpecialProduct] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [productName, setProductName] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [editProductAndServiceId, setEditProductAndServiceId] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [editData, setEditData] = useState();
  const [edit, setEdit] = useState(false);
  const [startSelect, setStarSelect] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const businessId = localStorage.getItem('businessId');
  const location = useLocation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const fetchData = useCallback(async () => {
    if (userCurrentBusinessDetails?.id || businessId) {
      const id = getParamsId();
      setTabLoader(true);
      const payload = {
        query: manageBusinessData.getBusinessServicesAndProductData,
        variables: {
          businessId:
            location.pathname === '/businessProductAndService' ||
            location.pathname === `/businessProductAndService/${id}`
              ? parseInt(userCurrentBusinessDetails?.id, 10)
              : parseInt(businessId, 10),
          type: tabValue === 0 ? 'product' : 'service',
        },
      };
      const result = await handleApiRequest(payload);
      setTabLoader(false);
      setPageLoading(false);
      if (result?.getBusinessServicesAndProductData?.status === 200) {
        setBusinessProduct(result?.getBusinessServicesAndProductData?.data);
      } else {
        enqueueSnackbar(result?.getBusinessServicesAndProductData?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  }, [businessId, tabValue, location.pathname, enqueueSnackbar, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addBusinessProductAndService = useCallback(async () => {
    setRefreshIcon(true);
    const id = getParamsId();
    const payload = {
      query: !edit
        ? manageBusinessData.addBusinessServicesAndProductDataV1
        : manageBusinessData.editBusinessServicesAndProductData,
      variables: {
        ...((!edit || editData?.name !== productName) && {
          businessId:
            location.pathname === '/businessProductAndService' ||
            location.pathname === `/businessProductAndService/${id}`
              ? parseInt(userCurrentBusinessDetails?.id, 10)
              : parseInt(businessId, 10),
          name: productName,
        }),
        type: tabValue === 0 ? 'product' : 'service',
        specialty: isSpecialProduct,
        ...(edit && {
          userBusinessServiceAndProductId: parseInt(editProductAndServiceId, 10),
        }),
      },
    };

    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    const res = edit
      ? result?.editBusinessServicesAndProductData
      : result?.addBusinessServicesAndProductDataV1;
    if (res?.status === 200) {
      handleRemove();
      fetchData();
    } else {
      enqueueSnackbar(res?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  }, [
    edit,
    editData?.name,
    editProductAndServiceId,
    productName,
    isSpecialProduct,
    businessId,
    location.pathname,
    userCurrentBusinessDetails?.id,
    enqueueSnackbar,
    fetchData,
    tabValue,
  ]);

  useEffect(() => {
    if (startSelect) {
      setStarSelect(false);
      addBusinessProductAndService();
    }
  }, [startSelect, addBusinessProductAndService]);

  if (
    pageLoading &&
    location.pathname !== '/businessProductAndService' &&
    location.pathname !== `/businessProductAndService/${getParamsId()}`
  ) {
    return <Loading />;
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setProductName(value);
    setIsEmpty(value.trim() === '');
  };

  const handleRemove = () => {
    setOpenDialogue(false);
    setProductName('');
    setEdit(false);
    setIsEmpty(false);
  };

  const editBusinessProductAndService = (value) => {
    setEdit(true);
    setOpenDialogue(true);
    setProductName(value?.name);
    setEditProductAndServiceId(value?.id);
    setIsSpecialProduct(value?.specialty);
    setEditData(value);
  };
  const markSpecialProductService = (value) => {
    setEdit(true);

    setIsSpecialProduct(!value.specialty);
    setProductName(value?.name);
    setEditProductAndServiceId(value?.id);
    setEditData(value);
    setStarSelect(true);
  };
  const deleteProductAndService = async (id) => {
    if (id) {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.removeBusinessProductsServices,
        variables: {
          userBusinessServiceAndProductId: parseInt(id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.removeBusinessServicesAndProductData?.status === 200) {
        fetchData();
      } else {
        enqueueSnackbar(result?.removeBusinessServicesAndProductData?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const tabContent = (tabValues) => {
    return (
      <>
        {!tabLoader ? (
          <>
            {businessProduct && businessProduct?.length > 0 ? (
              <>
                {businessProduct?.map((item, index) => {
                  return (
                    <div className="rounded-sm mx-auto flex items-center" key={index}>
                      <div
                        className={`h-auto sm:h-48 flex flex-col sm:flex-row items-center justify-evenly sm:justify-between gap-10  ${
                          location.pathname === '/businessProductAndService' ||
                          location.pathname === `/businessProductAndService/${getParamsId()}`
                            ? 'bg-gray-100 rounded-md p-28 mb-12'
                            : 'border-b-1 border-grey-200 border-solid'
                        } md:py-2 w-full`}
                      >
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item?.specialty}
                                onChange={() => markSpecialProductService(item)}
                                className="hover:bg-transparent inline-block items-center py-0 pr-0 pl-10 -mt-5"
                                icon={<StarBorderIcon className="text-darkgreen text-20" />}
                                checkedIcon={<StarIcon className="text-darkgreen text-20" />}
                              />
                            }
                          />
                          <span className="inline-block md:text-16 text-14 font-semibold capitalize items-center">
                            {item?.name}
                          </span>
                        </div>
                        {location.pathname === '/businessProductAndService' ||
                        location.pathname === `/businessProductAndService/${getParamsId()}` ? (
                          <div className="flex gap-16 flex-col sm:flex-row items-center">
                            <Button
                              variant="contained"
                              color="inherit"
                              className="md:text-14 font-medium bg-darkgreen-100 text-darkgreen hover:bg-darkgreen-100 rounded-full px-32 h-12 min-h-32"
                              aria-label="edit"
                              onClick={() => editBusinessProductAndService(item)}
                              type="button"
                            >
                              {tabValue === 0
                                ? t(
                                    'manageBusiness.addBusinessSteps.productServices.businessProduct.dialog.editTitle'
                                  )
                                : t(
                                    'manageBusiness.addBusinessSteps.productServices.businessService.dialog.editTitle'
                                  )}
                            </Button>
                            <Button
                              variant="contained"
                              color="inherit"
                              className="md:text-14 font-medium bg-red-A500 text-red hover:bg-red-A500 rounded-full px-32 h-12 min-h-32"
                              aria-label="delete"
                              onClick={() => deleteProductAndService(item.id)}
                              type="button"
                            >
                              {tabValue === 0
                                ? t(
                                    'manageBusiness.addBusinessSteps.productServices.businessProduct.dialog.deleteTitle'
                                  )
                                : t(
                                    'manageBusiness.addBusinessSteps.productServices.businessService.dialog.deleteTitle'
                                  )}
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button
                              variant="contained"
                              color="inherit"
                              className="md:text-14 font-medium text-grey-700 disabled:text-black rounded-full p-0 bg-white mr-16 min-h-32 w-32 min-w-32 w-32 h-32"
                              aria-label="edit"
                              onClick={() => editBusinessProductAndService(item)}
                              type="button"
                            >
                              <Icon className="text-20">edit</Icon>
                            </Button>
                            <Button
                              variant="contained"
                              color="inherit"
                              className="md:text-14 font-medium text-grey-700 disabled:text-black rounded-full p-0 bg-white min-h-32 w-32 min-w-32 w-32 h-32"
                              aria-label="delete"
                              onClick={() => deleteProductAndService(item.id)}
                              type="button"
                            >
                              <Icon className="text-20">delete</Icon>
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="text-center pt-68">
                  {(location.pathname === '/businessProductAndService' ||
                    location.pathname === `/businessProductAndService/${getParamsId()}`) && (
                    <img
                      className="m-auto mb-20 w-112"
                      src="/assets/images/icon/Group38343.svg"
                      alt=""
                    />
                  )}
                  <h5 className="text-20 font-semibold mb-20">
                    {tabValues === 0
                      ? location.pathname !== '/businessProductAndService' &&
                        location.pathname !== `/businessProductAndService/${getParamsId()}`
                        ? t('manageBusiness.addBusinessSteps.productServices.businessProduct.add')
                        : t('businessProductAndService.noProduct')
                      : location.pathname !== '/businessProductAndService' &&
                        location.pathname !== `/businessProductAndService/${getParamsId()}`
                      ? t('manageBusiness.addBusinessSteps.productServices.businessService.add')
                      : t('businessProductAndService.noService')}
                  </h5>
                  <Typography className="text-14 font-medium mb-20 max-w-320 mx-auto">
                    {tabValues === 0
                      ? location.pathname !== '/businessProductAndService' &&
                        location.pathname !== `/businessProductAndService/${getParamsId()}`
                        ? t('manageBusiness.addBusinessSteps.productServices.businessProduct.desc')
                        : t('businessProductAndService.productMessage')
                      : location.pathname !== '/businessProductAndService' &&
                        location.pathname !== `/businessProductAndService/${getParamsId()}`
                      ? t('manageBusiness.addBusinessSteps.productServices.businessService.desc')
                      : t('businessProductAndService.serviceMessage')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="md:text-16 font-medium disabled:text-black rounded-full px-28"
                    aria-label="add"
                    onClick={() => {
                      setIsSpecialProduct(false);
                      setOpenDialogue(true);
                    }}
                    type="button"
                  >
                    <Icon>add</Icon>

                    {tabValues === 0
                      ? `${t(
                          'manageBusiness.addBusinessSteps.productServices.businessProduct.add'
                        )}`
                      : `${t(
                          'manageBusiness.addBusinessSteps.productServices.businessService.add'
                        )}`}
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="text-center pt-68">
            <div className="loader-container bg-transparent text-center">
              <div className="loader h-48 w-48 rounded-full border-4 border-solid border-darkgreen border-t-darkgreen-100 mx-auto" />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={`${
          location.pathname !== '/businessProductAndService' &&
          location.pathname !== `/businessProductAndService/${getParamsId()}`
            ? 'bg-grey-50'
            : 'bg-white'
        } sm:px-20 px-10 py-16 rounded-md mt-24 min-h-384`}
      >
        <div className="border-b-1 border-solid border-grey mb-20 flex justify-between items-center">
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              className="sm:text-18 font-semibold p-0 sm:mr-28 mr-14"
              value={0}
              label={t('manageBusiness.addBusinessSteps.productServices.businessProduct.title')}
            />
            <Tab
              className="sm:text-18 font-semibold p-0"
              value={1}
              label={t('manageBusiness.addBusinessSteps.productServices.businessService.title')}
            />
          </Tabs>
          {businessProduct.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-full sm:px-28 px-0 min-w-0 h-28 sm:w-auto w-28 min-h-0 sm:min-h-28 py-0"
              aria-label="add"
              size="small"
              onClick={() => {
                setIsSpecialProduct(false);
                setOpenDialogue(true);
              }}
              type="button"
            >
              <Icon className="block sm:hidden text-16">add</Icon>
              <span className="hidden sm:block">
                {t('manageBusiness.addBusinessSteps.productServices.businessProduct.LAdd')}
              </span>
            </Button>
          )}
        </div>
        <div>
          {pageLoading &&
          (location.pathname === '/businessProductAndService' ||
            location.pathname === `/businessProductAndService/${getParamsId()}`) ? (
            <Loading />
          ) : (
            <>
              <div className={tabValue !== 0 ? 'hidden' : ''}>{tabContent(tabValue)}</div>
              <div className={tabValue !== 1 ? 'hidden' : ''}>{tabContent(tabValue)}</div>
            </>
          )}
        </div>
      </div>
      {location.pathname !== '/businessProductAndService' &&
        location.pathname !== `/businessProductAndService/${getParamsId()}` && (
          <div className="text-right mt-48 md:mb-96 mb-36">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              type="button"
            >
              {t('manageBusiness.button.skip')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:bg-darkgreen-A300 disabled:text-white rounded-md sm:min-w-160"
              aria-label="confirm"
              type="button"
            >
              {t('manageBusiness.addBusinessSteps.customAttribute.saveAndNext')}
            </Button>
          </div>
        )}
      <Dialog
        classes={{
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
        }}
        open={openDialogue}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-28">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
            {tabValue === 0 ? (
              <>
                {!edit
                  ? t(
                      'manageBusiness.addBusinessSteps.productServices.businessProduct.dialog.title'
                    )
                  : t(
                      'manageBusiness.addBusinessSteps.productServices.businessProduct.dialog.editTitle'
                    )}
              </>
            ) : (
              <>
                {!edit
                  ? t(
                      'manageBusiness.addBusinessSteps.productServices.businessService.dialog.title'
                    )
                  : t(
                      'manageBusiness.addBusinessSteps.productServices.businessService.dialog.editTitle'
                    )}
              </>
            )}
          </Typography>
        </DialogTitle>

        <form noValidate className="flex flex-col md:overflow-hidden">
          <DialogContent className="p-0 mb-28">
            <div className="block mb-14">
              <InputLabel
                htmlFor="productName"
                className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
              >
                {tabValue === 0 ? (
                  <>
                    {t(
                      'manageBusiness.addBusinessSteps.productServices.businessProduct.dialog.productName.name'
                    )}{' '}
                    <span className=" text-2xl text-red-500">*</span>
                  </>
                ) : (
                  <>
                    {t(
                      'manageBusiness.addBusinessSteps.productServices.businessService.dialog.serviceName.name'
                    )}{' '}
                    <span className=" text-2xl text-red-500">*</span>
                  </>
                )}
              </InputLabel>
              <TextField
                placeholder={
                  tabValue === 0
                    ? t(
                        'manageBusiness.addBusinessSteps.productServices.businessProduct.dialog.productName.placeHolder'
                      )
                    : t(
                        'manageBusiness.addBusinessSteps.productServices.businessService.dialog.serviceName.placeHolder'
                      )
                }
                size="small"
                value={productName}
                onChange={handleChange}
                id="productName"
                variant="outlined"
                required
                fullWidth
              />
              {isEmpty && (
                <Typography className="text-red pt-8 text-14">
                  {tabValue === 0
                    ? t(
                        'manageBusiness.addBusinessSteps.productServices.businessProduct.dialog.productName.validationMessage'
                      )
                    : t(
                        'manageBusiness.addBusinessSteps.productServices.businessService.dialog.serviceName.validationMessage'
                      )}
                </Typography>
              )}
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSpecialProduct}
                    onChange={() => setIsSpecialProduct(!isSpecialProduct)}
                    className="text-16 font-semibold text-black hover:bg-white"
                    icon={
                      isSpecialProduct ? (
                        <StarIcon className="text-darkgreen text-20" />
                      ) : (
                        <StarBorderIcon className="text-darkgreen text-20" />
                      )
                    }
                    checkedIcon={<StarIcon className="text-darkgreen text-20" />}
                  />
                }
                label={
                  <span className="text-16 font-semibold text-black">
                    {tabValue === 0
                      ? t(
                          'manageBusiness.addBusinessSteps.productServices.businessProduct.dialog.productName.special'
                        )
                      : t(
                          'manageBusiness.addBusinessSteps.productServices.businessService.dialog.serviceName.special'
                        )}
                  </span>
                }
              />
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md min-w-256"
              variant="contained"
              color="secondary"
              size="small"
              disabled={
                !productName ||
                refreshIcon ||
                (productName === editData?.name && isSpecialProduct === editData?.specialty)
              }
              onClick={() => addBusinessProductAndService()}
            >
              {t('manageBusiness.button.save')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default withReducer('manageBusinessReducer', reducer)(ProductsServices);
