import { t } from 'i18next';
import { Button, Icon, MenuItem, Select, Typography } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import { useEffect, useState, useRef, useCallback } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { publicIpv4 } from 'public-ip';
import { useSnackbar } from 'notistack';
import { chatSocket, socket } from '../../../../socket';
import quickChatQuery from '../../query/quickChat';
import { handleApiRequest } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';

const ChatInbox = (props) => {
  const chatScroll = useRef(null);
  const [messageInput, setMessageInput] = useState('');
  const [chatType, setChatType] = useState(0);
  const [selectedContact, setSelectedContact] = useState(null);
  const [getChatRoom, setGetChatRoom] = useState([]);
  const [selectContactMessageList, setSelectContactMessageList] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [clientStatus, setClientStatus] = useState({});
  const [isConnected, setIsConnected] = useState(chatSocket.connected);
  const ipAddress = publicIpv4();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const currentTime = moment().format('h:mm A');
  const { enqueueSnackbar } = useSnackbar();
  const [quickChatMessageStatus, setQuickChatMessageStatus] = useState(true);

  useEffect(() => {
    try {
      if (selectedContact) {
        const whatAppUserDetails = async () => {
          const payload = {
            query: quickChatQuery.getAllQuickChatRoomMessageUsingQuickChatId,
            variables: {
              quickChatRoomId: selectedContact?.quickChatRoomMessages?.quickChatRoomId,
              sortBy: 'cts',
              sortOrder: 'asc',
              pageNumber: 1,
              pageSize: 20,
            },
          };
          const res = await handleApiRequest(payload);
          if (res?.getAllQuickChatRoomMessageUsingQuickChatId?.status === 200) {
            setSelectContactMessageList(res?.getAllQuickChatRoomMessageUsingQuickChatId?.data);
            chatScroll.current?.scrollIntoView({ behavior: 'smooth' });
          }
        };

        whatAppUserDetails();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact]);

  useEffect(() => {
    try {
      if (props?.quickChatId) {
        const whatAppUserChatRoom = async () => {
          const payload = {
            query: quickChatQuery.getAllQuickChatWithLastMessage,
            variables: {
              quickChatId: props?.quickChatId,
            },
          };
          const res = await handleApiRequest(payload);
          if (res?.getAllQuickChatWithLastMessage?.status === 200) {
            setGetChatRoom(res?.getAllQuickChatWithLastMessage?.data);
          } else {
            setGetChatRoom([]);
          }
        };
        whatAppUserChatRoom();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [props?.quickChatId]);

  useEffect(() => {
    const handleConnect = async () => {
      console.log('connect===>');
      const subscribeChatData = JSON.stringify({
        userBusinessesId: parseInt(userCurrentBusinessDetails?.id, 10),
        ipAddress: await ipAddress,
        quickChatId: props?.quickChatId,
      });
      if (chatSocket.connected) {
        chatSocket.emit('subscribeChannelByBusinessUser', subscribeChatData, (ack) => {
          console.log('Event emitted, acknowledgment:', ack);
        });
      } else {
        console.error('Socket not connected, retrying...');
      }
      setIsConnected(true);
    };

    const handleMessage = (message) => {
      console.log('message', message);
      if (message?.message) {
        const newMessage = {
          message: message?.message,
          time: currentTime,
        };
        setSelectContactMessageList((prevMessages) => [...prevMessages, newMessage]);
      }
    };

    const currentClientStatus = (currentClientStatusData) => {
      setGetChatRoom((prevContacts) => {
        return prevContacts.map((contact) => {
          if (
            contact.quickChatRoomMessages.quickChatRoomId ===
            currentClientStatusData.quickChatRoomId
          ) {
            return {
              ...contact,
              quickChatRoomMessages: {
                ...contact.quickChatRoomMessages,
              },
              status: currentClientStatusData?.currentStatus,
            };
          }
          return contact;
        });
      });

      // const updatedChat = getChatRoom.map((chat) => {
      //   if (
      //     chat?.quickChatRoomMessages?.quickChatRoomId === currentClientStatusData.quickChatRoomId
      //   ) {
      //     return {
      //       ...chat,
      //       quickChatRoomMessages: {
      //         ...chat.quickChatRoomMessages,
      //         status: currentClientStatusData?.currentStatus,
      //       },
      //     };
      //   }
      //   return chat;
      // });
      // console.log('updatedProducts', updatedChat);
      // setGetChatRoom(updatedChat);
      // console.log('currentClientStatusData', currentClientStatusData);
    };

    const handleDisconnect = () => {
      setIsConnected(false);
    };
    const handleChatMessage = (event) => {
      console.log('eveent', event);
    };

    chatSocket.on('connect', handleConnect);
    chatSocket.on('sendClientMessageToBusinessUser', handleMessage);
    chatSocket.on('messageToClient', handleMessage);
    chatSocket.on('currentClientStatus', currentClientStatus);
    chatSocket.on('disconnect', handleDisconnect);
    chatSocket.on('QUICK_CHAT_MESSAGES', handleChatMessage);
    return () => {
      if (chatSocket) {
        chatSocket.off('connect', handleConnect);
        chatSocket.off('disconnect', handleDisconnect);
        chatSocket.off('messageToClient', handleMessage);
        chatSocket.off('currentClientStatus', currentClientStatus);
      }
    };
  }, [currentTime, getChatRoom, ipAddress, props?.quickChatId, userCurrentBusinessDetails?.id]);

  const handleContactClick = (contact) => {
    setSelectedContact(contact);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage('text');
    }
  };
  chatSocket.on('error', (error) => {
    console.error('Socket error:', error);
  });
  const handleSendMessage = async () => {
    if (!messageInput || messageInput.trim() === '') {
      return;
    }
    setMessageInput('');

    const newMessage = {
      message: messageInput,
      cts: moment().unix(),
      status: 'sent',
      messageType: 'sender',
    };

    setSelectContactMessageList((prevList) => [...prevList, newMessage]);
    chatScroll.current?.scrollIntoView({ behavior: 'smooth' });
    try {
      const payload = {
        query: quickChatQuery.sendQuickChatMessageByOwner,
        variables: {
          quickChatRoomId: selectedContact?.quickChatRoomMessages?.quickChatRoomId,
          message: messageInput,
        },
      };
      await handleApiRequest(payload);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const formattedDate = (timestamp) => {
    const date = moment(timestamp);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    if (date.isSame(today, 'day')) {
      return date.format('h:mm A');
    }
    if (date.isSame(yesterday, 'day')) {
      return 'Yesterday';
    }
    return date.format('DD/MM/YYYY');
  };

  const handleFavorite = async () => {
    try {
      const payload = {
        query: quickChatQuery.addQuickChatFavorite,
        variables: {
          isFavorite: !selectedContact?.favourite,
          quickChatRoomId: selectedContact?.quickChatRoomMessages?.quickChatRoomId,
        },
      };
      const res = await handleApiRequest(payload);
      if (res?.addQuickChatFavorite?.status === 200) {
        enqueueSnackbar(res?.addQuickChatFavorite?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setSelectedContact((prevData) => ({
          ...prevData,
          favourite: !prevData.favourite, // Toggle the favourite value
        }));
      } else {
        enqueueSnackbar(res?.addQuickChatFavorite?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <>
      <div className="p-20 lg:p-28">
        {getChatRoom?.length > 0 ? (
          <div className="bg-white rounded-md border-1 border-solid border-grey-300">
            <div className="flex h-screen chat-height">
              {/* Left Sidebar */}
              <div className="w-1/4 overflow-auto">
                <div className="border-b-1 border-solid border-grey-300 h-64 grid align-middle w-full px-16 relative">
                  <div className="flex items-center justify-between">
                    <Select
                      className="inline-block border-none text-black capitalize min-h-0 h-auto p-0 font-semibold text-16"
                      id="msgType"
                      value={chatType}
                      size="small"
                      displayEmpty
                      IconComponent={() => (
                        <Icon className="inline-block text-black absolute top-0 right-0 text-20">
                          keyboard_arrow_down
                        </Icon>
                      )}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiSelect-select': {
                          padding: '0px 0',
                          display: 'flex',
                          alignItems: 'center',
                          border: 'none',
                        },
                      }}
                      onChange={(e) => setChatType(e?.target?.value)}
                    >
                      <MenuItem value={0} className="capitalize">
                        {t('waba.inbox.allChat')}
                      </MenuItem>
                      <MenuItem value={1} className="capitalize">
                        {t('waba.inbox.unreadChat')}
                      </MenuItem>
                      <MenuItem value={2} className="capitalize">
                        {t('waba.inbox.broadcast')}
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="p-16">
                  {getChatRoom && getChatRoom?.length > 0 ? (
                    <>
                      {getChatRoom?.map((contact, index) => (
                        <div
                          key={index}
                          className={`mb-10 rounded cursor-pointer hover:bg-darkgreen-100 border-1 border-solid  rounded-md p-10 ${
                            selectedContact?.id === contact?.id
                              ? 'bg-darkgreen-100 border-darkgreen-100 border-l-3 border-l-solid border-l-darkgreen'
                              : 'bg-white border-grey-300 pl-12'
                          }`}
                          aria-hidden
                          onClick={() => handleContactClick(contact)}
                        >
                          <div className="flex items-center">
                            <img
                              src="/assets/images/business/profile.png"
                              className="img-fluid w-full max-w-48 mr-10"
                              alt="..."
                            />

                            <div className="overflow-hidden w-full">
                              <div className="flex items-center justify-between">
                                <h3 className="text-16 font-semibold w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                  {contact?.ipAddress}
                                </h3>
                                <span
                                  className={`text-12 font-semibold w-68 text-right ${
                                    contact?.status === 'live'
                                      ? 'text-grey-800'
                                      : contact?.status === 'close'
                                      ? 'text-grey-600'
                                      : 'text-red'
                                  }`}
                                >
                                  <span
                                    className={`inline-block w-7 h-7 rounded-full mr-3 ${
                                      contact?.status === 'live'
                                        ? 'bg-green'
                                        : contact?.status === 'close'
                                        ? 'bg-grey-600'
                                        : 'bg-red'
                                    }`}
                                  />
                                  <span>{contact?.status}</span>
                                </span>
                              </div>
                              <span className="font-medium text-14 whitespace-nowrap overflow-hidden text-ellipsis mt-4 mb-4 block text-grey-600">
                                {contact?.quickChatRoomMessages?.message}
                              </span>
                              <span className="text-12 font-semibold w-68 text-right">
                                {formattedDate(contact?.quickChatRoomMessages?.uts)}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <Typography className="text-center font-medium">
                      {t('waba.inbox.noResult')}
                    </Typography>
                  )}
                </div>
              </div>

              <div
                className={`flex-grow relative ${
                  selectedContact ? 'w-3/5' : 'w-4/5'
                } border-x-1 border-solid border-grey-300`}
              >
                {selectedContact ? (
                  <>
                    <div className="border-b-1 border-solid border-grey-300 h-64 grid align-middle w-full px-16">
                      <div className="flex items-center">
                        <img
                          src="/assets/images/business/profile.png"
                          className="img-fluid w-full max-w-40 mr-10"
                          alt="..."
                        />

                        <div className="flex items-center justify-between w-full">
                          <h3 className="text-16 font-bold">{selectedContact?.ipAddress}</h3>
                          <span
                            aria-hidden="true"
                            className="cursor-pointer"
                            onClick={() => {
                              handleFavorite();
                            }}
                          >
                            {selectedContact?.favourite ? (
                              <FavoriteIcon className="text-red" />
                            ) : (
                              <FavoriteBorderIcon />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="inbox-height flex flex-col bg-gray-A300 relative">
                      <div className="flex-grow overflow-auto p-20 pb-0">
                        {selectContactMessageList.map((message, index) => (
                          <div className="mb-12 block" key={index}>
                            <div
                              key={message?.id}
                              className={`rounded-md mb-4 inline-block px-8 py-8 text-14 font-semibold bg-white ${
                                message?.messageType === 'sender'
                                  ? 'self-end text-right float-right '
                                  : 'self-start text-left'
                              }`}
                            >
                              <div>{message?.message}</div>
                            </div>
                            <div
                              className={`text-12 text-gray-700 font-medium clear-both     ${
                                message?.messageType === 'sender' ? 'text-right' : 'text-left'
                              }`}
                            >
                              {moment(message.cts).format('h:MM A')}

                              {message?.messageType === 'sender' && message?.status === 'send' && (
                                <Icon className="text-14">done</Icon>
                              )}
                              {message?.messageType === 'sender' &&
                                message?.status === 'delivered' && (
                                  <DoneAllIcon className="text-14 ml-3" />
                                )}
                              {message?.messageType === 'sender' && message?.status === 'read' && (
                                <DoneAllIcon className="text-blue text-14 ml-3" />
                              )}
                            </div>
                          </div>
                        ))}
                        <div ref={chatScroll} className="h-32" />
                      </div>
                      <div className="mt-4 flex bg-white items-center px-20">
                        <input
                          type="text"
                          className="flex-grow p-2 border-none rounded-none h-60"
                          value={messageInput}
                          onChange={(e) => setMessageInput(e.target.value)}
                          placeholder={t('waba.inbox.typeMessage')}
                          onKeyPress={handleKeyPress}
                        />
                        <div>
                          <span
                            aria-hidden
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            className="bg-grey-100 rounded-md w-28 h-28 inline-block text-center pt-4 align-middle mx-10 cursor-pointer"
                          >
                            <Icon className="text-20">emoji_emotions</Icon>
                          </span>
                          {showEmojiPicker && (
                            <div className="absolute bottom-60 right-16">
                              <EmojiPicker
                                onEmojiClick={(emojiObject) => {
                                  setMessageInput((prevMsg) => prevMsg + emojiObject.emoji);
                                  setShowEmojiPicker(false);
                                }}
                              />
                            </div>
                          )}
                          <span
                            aria-hidden
                            title="Template"
                            className="bg-grey-100 rounded-md w-28 h-28 inline-block text-center pt-4 align-middle mx-10 cursor-pointer"
                          >
                            <Icon className="text-20">text_snippet</Icon>
                          </span>

                          <Button
                            className="rounded-md md:px-24"
                            onClick={() => handleSendMessage()}
                            size="small"
                            variant="outlined"
                            color="secondary"
                          >
                            {t('quickChat.createBot.send')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="h-full flex items-center justify-center bg-gray-A300">
                    <div className="mx-auto text-center">
                      <img
                        src="/assets/images/business/no_chat.png"
                        className="img-fluid mx-auto mb-48 max-w-192"
                        alt="..."
                      />
                      <Typography className="block text-24 font-semibold mb-12">
                        {t('quickChat.createBot.noChat')}
                      </Typography>
                      <Typography className="block font-medium text-16 text-grey-600 mb-28 max-w-256 mx-auto">
                        {t('quickChat.createBot.noChatInfo')}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="h-screen chat-height flex items-center justify-center">
            <div className="mx-auto text-center">
              <img
                src="/assets/images/business/no_chat.png"
                className="img-fluid mx-auto mb-48 max-w-192"
                alt="..."
              />
              <Typography className="block text-24 font-semibold mb-12">
                {t('quickChat.createBot.noChatStart')}
              </Typography>
              <Typography className="block font-medium text-16 text-grey-600 mb-28 max-w-480 mx-auto">
                {t('quickChat.createBot.noChatStartDesc')}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatInbox;
