import { Button, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import history from '@history';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from 'react';
import {
  getParamsId,
  capitalizeFirstLetter,
  reviewFormate,
  handleApiRequest,
} from '../../common/common';
import userData from '../../query/user';

const BusinessPlateForm = () => {
  const [platformData, setPlatformData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      setPlatformData(userCurrentBusinessDetails?.reviewplatform);
    }
  }, [userCurrentBusinessDetails?.id, userCurrentBusinessDetails?.reviewplatform]);

  const redirect = (type, platformsId) => {
    const id = getParamsId();
    const selectedData = platformData.filter((data) => data?.reviewPlatformId === platformsId);
    const ratingTypeForRedirect = selectedData[0]?.name === 'zomato' ? 'dining' : '';
    const placeIdForRedirect = selectedData[0]?.placeId;
    if (type === 'reviews') {
      if (id) {
        history.push(`/reviews/${id}`, {
          placeIdForRedirect,
          ratingTypeForRedirect,
          platformsId,
        });
      } else {
        history.push(`/reviews/`, {
          placeIdForRedirect,
          ratingTypeForRedirect,
          platformsId,
        });
      }
    } else if (type === 'inviteReviews') {
      if (id) {
        history.push(`/invite-reviews/${id}`);
      } else {
        history.push('/invite-reviews/');
      }
    } else if (type === 'addProducts') {
      history.push(`/product/${id}`, { platformsId });
    } else {
      history.push(`/product/`, { platformsId });
    }
  };

  return (
    <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
      <Typography className="font-bold text-20">
        {t('businessDashboard.businessPlateForm')}
      </Typography>
      {userCurrentBusinessDetails?.reviewplatform?.length > 0 &&
        userCurrentBusinessDetails?.reviewplatform?.map((item, index) => {
          const isOnlineBusiness = userCurrentBusinessDetails?.isOnlineBusiness;
          return (
            <div
              className={`flex items-center justify-between mt-16 p-12 rounded-lg min-h-112 ${
                (parseInt(item?.reviewPlatformId, 10) === 2 ||
                  parseInt(item?.reviewPlatformId, 10) === 5) &&
                'bg-blue-A300'
              } ${
                (parseInt(item?.reviewPlatformId, 10) === 3 ||
                  parseInt(item?.reviewPlatformId, 10) === 7) &&
                'bg-orange-A300'
              } ${
                (parseInt(item?.reviewPlatformId, 10) === 4 ||
                  parseInt(item?.reviewPlatformId, 10) === 6) &&
                'bg-purple-A300'
              }`}
              key={index}
            >
              <div className="flex gap-16 items-center">
                <div className="bg-white p-12 rounded">
                  <img className="w-32 m-auto" src={item?.logo} alt="" />
                </div>
                <div>
                  <Typography className="text-16 font-semibold">
                    {item?.name && capitalizeFirstLetter(item.name)}
                  </Typography>
                  {item?.totalReview !== 0 && (
                    <>
                      <Typography className="text-14">
                        Based on {item?.totalReview} Reviews
                      </Typography>
                      <Typography className="pr-8">
                        {reviewFormate(item?.totalRatingStars)}{' '}
                        <span className="font-semibold">{item?.totalRatingStars} / 5</span>
                      </Typography>
                    </>
                  )}
                </div>
              </div>
              <div>
                {!isOnlineBusiness || (isOnlineBusiness && item?.totalReview > 0) ? (
                  <Button
                    className="bg-white hover:bg-white rounded max-w-40 min-w-40 w-40"
                    onClick={() => redirect('reviews', item?.reviewPlatformId)}
                  >
                    <ChevronRightIcon />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="rounded-md px-20 mx-auto"
                    color="secondary"
                    size="small"
                    type="button"
                    onClick={() =>
                      redirect(
                        parseInt(item?.reviewPlatformId, 10) === 7
                          ? 'inviteReviews'
                          : 'addProducts',
                        item?.reviewPlatformId
                      )
                    }
                  >
                    {parseInt(item?.reviewPlatformId, 10) === 7
                      ? t('businessDashboard.inviteReviews')
                      : t('businessDashboard.addProducts')}
                  </Button>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default BusinessPlateForm;
