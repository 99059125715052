import { Button, Icon, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import history from '@history';
import { useSelector } from 'react-redux';
import businessData from '../../query/business';
import { getParamsId, handleApiRequest } from '../../common/common';
import Loading from '../manage-business/Loading';

const DashboardAlerts = () => {
  const [cardData, setCardData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [loading, setLoading] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCardData = async () => {
      setLoading(true);
      try {
        const payload = {
          query: businessData.getBusinessWhatsNewCard,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getBusinessWhatsNewCard?.status === 200) {
          const data = result.getBusinessWhatsNewCard?.data;
          const parseData = data?.map((card) => ({
            ...card,
            cardDetails: JSON.parse(card.cardDetails),
          }));
          setCardData(parseData);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching card data:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchCardData();
    }
  }, [userCurrentBusinessDetails?.id]);

  const handleButtons = (card) => {
    const id = getParamsId();
    if (card?.cardName === 'newReviewsAlert' || card?.cardName === 'reviewThisWeek') {
      if (id) {
        history.push(`reviews/${id}`);
      } else {
        history.push('reviews/');
      }
    } else if (card?.cardName === 'sendReviewInvitation') {
      if (id) {
        history.push(`invite-reviews/${id}`);
      } else {
        history.push('invite-reviews/');
      }
    } else if (card?.cardName === 'thankYouMessageSent') {
      if (id) {
        history.push(`autoReply/${id}`);
      } else {
        history.push('autoReply/');
      }
    } else if (card?.cardName === 'areaOfImprovements') {
      if (id) {
        history.push(`serviceRecovery/${id}`);
      } else {
        history.push('serviceRecovery/');
      }
    } else if (card?.cardName === 'competitorReviewAlert') {
      window.open(card?.cardName?.url, '_blank');
    } else if (card?.cardName === 'welcomeToQuickReview') {
      if (id) {
        history.push(`reviews/${id}`);
      } else {
        history.push('reviews/');
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
      <Slider {...settings}>
        {cardData?.length > 0 &&
          cardData
            ?.filter((item) => item?.cardDetails?.cardName !== 'defaultRatingCounter')
            ?.map((item, index) => {
              return (
                <div className="pr-0 md:pr-16" key={index}>
                  {item && (
                    <div
                      className="flex gap-16 items-center w-full min-h-200 max-h-200 p-12 py-16 rounded-md"
                      style={{ backgroundColor: item.cardDetails?.backgroundColor }}
                    >
                      <div>
                        <Typography className="font-semibold text-16">
                          {item?.cardDetails?.title}
                        </Typography>
                        <Typography className="py-8">{item?.cardDetails?.description}</Typography>
                        <Button
                          className="rounded-md text-white max-w-192 min-w-192 sm:max-w-216 sm:min-w-216 mt-8"
                          style={{
                            backgroundColor: item.cardDetails?.buttonColor,
                            '--hover-bg': item?.cardDetails?.buttonColor,
                          }}
                          onClick={() => handleButtons(item?.cardDetails)}
                        >
                          {item?.cardDetails?.button}
                        </Button>
                      </div>
                      <div>
                        <img
                          className="hidden w-192 sm:block"
                          src={item?.cardDetails?.image}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
      </Slider>
    </div>
  );
};

export default DashboardAlerts;
