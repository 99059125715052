import { Button, Tab, Tabs, Typography } from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import FuseLoading from '@fuse/core/FuseLoading';
import { selectDashboardData } from '../../../../store/userSlice';
import LogoPhotoVideo from './LogoPhotoVideo';
import BasicBusinessDetails from './BasicBusinessDetails';
import { handleApiRequest } from '../../../common/common';
import BusinessOpeningAndClosingHours from './OpeningAndClosingHours';
import ConnectYourBusiness from '../../businessCompetitor/geoGrid/ConnectYourBusiness';
import manageBusinessData from '../../../query/manageBusiness';
import PostSection from '../postUpdates/PostSection';
import WebsiteAppointment from './WebsiteLinkAppointments';
import ServiceSection from './ServicesSection';

const PrimaryDetails = () => {
  const [isBusinessConnect, setIsBusinessConnect] = useState(false);
  const [loading, setLoading] = useState(true);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [googleBusinessData, setGoogleBusinessData] = useState();
  const [openDialog, setOpenDialog] = useState({
    phoneNumberDialog: false,
    openingHoursAndHoursDialog: false,
    logoPhotoVideoDialog: false,
    websiteAndAppointmentDialog: false,
    serviceDialog: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const fetchBusinessDetails = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setLoading(true);
      try {
        const payload = {
          query: manageBusinessData.getUserBusinessesGoogleDetails,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getUserBusinessesGoogleDetails?.status === 200) {
          const data = result.getUserBusinessesGoogleDetails?.data;
          const parsedData = JSON.parse(data);
          setGoogleBusinessData(parsedData);
        } else {
          enqueueSnackbar(result?.getUserBusinessesGoogleDetails?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching business details:', error);
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    fetchBusinessDetails();
  }, [fetchBusinessDetails]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-28">
      {isBusinessConnect ? (
        <div className="bg-white rounded-md px-28 py-8">
          <div className="pt-28">
            <div className="border border-black rounded-md lg:w-1/2">
              <div className="relative">
                <img
                  className="w-96 h-96 rounded-full absolute -bottom-24 left-36"
                  src={googleBusinessData?.profileImage}
                  alt=""
                />
                <img
                  className="w-full rounded-t-md min-h-256 max-h-256"
                  src={googleBusinessData?.coverImage}
                  alt=""
                />
              </div>
              <div className="mt-32 pl-40">
                <Typography className="font-bold text-24">
                  {googleBusinessData?.title}{' '}
                  <VerifiedIcon
                    className={`ml-6 ${googleBusinessData?.isGoogleVerified ? 'text-blue' : ''}`}
                  />
                </Typography>
                <Typography className="text-gray-600 text-16 font-semibold">
                  {t('listManagement.category')} :{' '}
                  {googleBusinessData?.categories?.primaryCategory?.displayName}
                </Typography>
                <Typography className="text-gray-600 text-16 font-semibold py-4">
                  {t('listManagement.status')} : {googleBusinessData?.openInfo?.status}
                </Typography>
                <Typography className="text-gray text-16 font-semibold pb-8">
                  {t('listManagement.address')} :{' '}
                  {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
                  {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
                  {googleBusinessData?.storefrontAddress?.locality}{' '}
                  {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
                  {googleBusinessData?.storefrontAddress?.postalCode}
                </Typography>
                <div className="flex gap-8 mb-16">
                  <Typography className="border border-gray rounded-md px-24 py-8 text-gray text-16 font-semibold">
                    {t('listManagement.primaryCode')}: {googleBusinessData?.storeCode}
                  </Typography>
                  <Typography className="border border-gray rounded-md px-24 py-8 text-gray text-16 font-semibold">
                    {t('listManagement.accountName')}: -
                  </Typography>
                </div>
              </div>
            </div>
            <div className="pt-16">
              <Typography className="font-bold text-24">
                {t('listManagement.missingInformation')}
              </Typography>
              <div className="pt-16 flex flex-wrap">
                <Button
                  className="bg-darkgreen hover:bg-darkgreen disabled:text-white text-white text-16 font-bold rounded-md px-16 mb-16"
                  disabled={googleBusinessData?.phoneNumbers?.primaryPhone}
                >
                  {t('listManagement.phoneNumber')}{' '}
                  {googleBusinessData?.phoneNumbers?.primaryPhone ? (
                    <DoneIcon className="border-2 border-darkgreen-400 ml-8 p-2 w-20 h-20 bg-darkgreen-400 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-darkgreen hover:bg-darkgreen disabled:text-white text-white text-16 font-bold rounded-md px-16 ml-16 mb-16"
                  disabled={googleBusinessData?.websiteUri}
                >
                  {t('listManagement.website')} {t('listManagement.link')}{' '}
                  {googleBusinessData?.websiteUri ? (
                    <DoneIcon className="border-2 border-darkgreen-400 ml-8 p-2 w-20 h-20 bg-darkgreen-400 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-darkgreen hover:bg-darkgreen disabled:text-white text-white text-16 font-bold rounded-md px-16 ml-16 mb-16"
                  disabled={googleBusinessData?.appointmentUrl}
                >
                  {t('listManagement.appointment')} {t('listManagement.link')} Link{' '}
                  {googleBusinessData?.appointmentUrl ? (
                    <DoneIcon className="border-2 border-darkgreen-400 ml-8 p-2 w-20 h-20 bg-darkgreen-400 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-darkgreen hover:bg-darkgreen disabled:text-white text-white text-16 font-bold rounded-md px-16 ml-16 mb-16"
                  disabled={googleBusinessData?.regularHours?.periods?.length > 0}
                >
                  {t('listManagement.openingDate')}{' '}
                  {googleBusinessData?.regularHours?.periods?.length > 0 ? (
                    <DoneIcon className="border-2 border-darkgreen-400 ml-8 p-2 w-20 h-20 bg-darkgreen-400 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-darkgreen hover:bg-darkgreen disabled:text-white text-white text-16 font-bold rounded-md px-16 ml-16 mb-16"
                  disabled={googleBusinessData?.regularHours?.periods?.length > 0}
                >
                  {t('listManagement.openingHours')}
                  {googleBusinessData?.regularHours?.periods?.length > 0 ? (
                    <DoneIcon className="border-2 border-darkgreen-400 ml-8 p-2 w-20 h-20 bg-darkgreen-400 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-darkgreen hover:bg-darkgreen disabled:text-white text-white text-16 font-bold rounded-md px-16 ml-16 mb-16"
                  disabled={googleBusinessData?.coverImage}
                >
                  {t('listManagement.photo')}
                  {googleBusinessData?.coverImage ? (
                    <DoneIcon className="border-2 border-darkgreen-400 ml-8 p-2 w-20 h-20 bg-darkgreen-400 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button className="bg-darkgreen hover:bg-darkgreen text-white text-16 font-bold rounded-md px-16 ml-16 mb-16">
                  {t('listManagement.video')}
                </Button>
                <Button
                  className="bg-darkgreen hover:bg-darkgreen disabled:text-white text-white text-16 font-bold rounded-md px-16 ml-16 mb-16"
                  disabled={googleBusinessData?.profileImage}
                >
                  {t('listManagement.businessLogo')}
                  {googleBusinessData?.profileImage ? (
                    <DoneIcon className="border-2 border-darkgreen-400 ml-8 p-2 w-20 h-20 bg-darkgreen-400 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-darkgreen hover:bg-darkgreen disabled:text-white text-white text-16 font-bold rounded-md px-16 ml-16 mb-16"
                  disabled={googleBusinessData?.regularHours?.periods?.length > 0}
                >
                  {t('listManagement.openingHours')}
                  {googleBusinessData?.regularHours?.periods?.length > 0 ? (
                    <DoneIcon className="border-2 border-darkgreen-400 ml-8 p-2 w-20 h-20 bg-darkgreen-400 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button className="bg-darkgreen hover:bg-darkgreen text-white text-16 font-bold rounded-md px-16 ml-16 mb-16">
                  {t('listManagement.menuPhoto')}
                </Button>
              </div>
              <BasicBusinessDetails
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.phoneNumberDialog}
                fetchBusinessDetails={fetchBusinessDetails}
              />
              <BusinessOpeningAndClosingHours
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.openingHoursAndHoursDialog}
                fetchBusinessDetails={fetchBusinessDetails}
              />
              <LogoPhotoVideo
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.logoPhotoVideoDialog}
                fetchBusinessDetails={fetchBusinessDetails}
              />
              <WebsiteAppointment
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.websiteAndAppointmentDialog}
                fetchBusinessDetails={fetchBusinessDetails}
              />
              <ServiceSection
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.serviceDialog}
                fetchBusinessDetails={fetchBusinessDetails}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white py-256">
          <ConnectYourBusiness setIsBusinessConnect={setIsBusinessConnect} />
        </div>
      )}
    </div>
  );
};

export default PrimaryDetails;
